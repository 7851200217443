import { Route } from 'react-router-dom';
import { RoutePaths } from '@safc/ui-utils/routes';
import { AuthLayout } from 'containers/AuthLayout';
import { asLazy } from 'utils/lazy.utils';
import { JoinFormType } from '../../pages/RegisterForm/RegisterForm.effects';
import { Suspense } from 'components/common/Suspense';

const GettingStarted = asLazy(
  import('../../pages/RegisterCR/GettingStarted/GettingStarted'),
  'GettingStarted',
);
const RegisterCRForm = asLazy(
  import('../../pages/RegisterCR/RegisterCRForm/RegisterCRForm'),
  'RegisterCRForm',
);
const RegisterForm = asLazy(
  import('../../pages/RegisterForm/RegisterForm'),
  'RegisterForm',
);

export const RegisterRouter = (
  <Route path={RoutePaths.REGISTER} element={<AuthLayout />}>
    <Route
      path={RoutePaths.REGISTER_AR()}
      element={
        <Suspense>
          <RegisterForm joinFormType={JoinFormType.JoinAR} />
        </Suspense>
      }
    />
    <Route
      path={RoutePaths.REGISTER_RA()}
      element={
        <Suspense>
          <RegisterForm joinFormType={JoinFormType.JoinRA} />
        </Suspense>
      }
    />
    <Route
      path={RoutePaths.REGISTER_CR}
      element={
        <Suspense>
          <GettingStarted />
        </Suspense>
      }
    />
    <Route
      path={RoutePaths.REGISTER_CR_CREATE_COMPANY()}
      element={
        <Suspense>
          <RegisterCRForm />
        </Suspense>
      }
    />
    <Route
      path={RoutePaths.REGISTER_CR_JOIN_COMPANY()}
      element={
        <Suspense>
          <RegisterForm joinFormType={JoinFormType.JoinCR} />
        </Suspense>
      }
    />
  </Route>
);
