import { type FC, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { DEFAULT_LOGGED_PAGE, RoutePaths } from '@safc/ui-utils/routes';
import { useAppAuth } from '../../hooks/auth/useAppAuth';

export const LoginPage: FC = () => {
  const [token] = useQueryParam('internalLoginToken', StringParam);
  const { setInternalToken } = useAppAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      void setInternalToken(token);
      navigate(DEFAULT_LOGGED_PAGE);
    }
  }, [token]);

  if (!token) {
    return <Navigate to={RoutePaths.HOME} />;
  }

  return <Navigate to={DEFAULT_LOGGED_PAGE} />;
};
