import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import type { MongoAbility } from '@casl/ability';
import {
  AbilityBuilder,
  createMongoAbility,
  defineAbility,
} from '@casl/ability';
import type { UserDataResponse } from '@safc/api-client';
import { AccountMembershipStatus } from '@safc/api-client';
import { RaPermissions } from './ra.permissions';
import { CrPermissions } from './cr.permissions';
import { ArPermissions } from './ar.permissions';
import type { PermissionsContext } from './permission.types';

type CreatePermissionsContext = {
  user?: UserDataResponse;
  companyId?: string | null;
};

const grantPermissionsToUser = (
  can: AbilityBuilder<any>['can'],
  cannot: AbilityBuilder<any>['cannot'],
  permissionsContext: PermissionsContext,
) => {
  const { user, company } = permissionsContext;

  if (user.isRegistryAdmin) {
    return new RaPermissions(
      { can, cannot },
      permissionsContext,
    ).grantPermissions();
  }
  if (company?.role === 'companyRepresentative') {
    return new CrPermissions(
      { can, cannot },
      permissionsContext,
    ).grantPermissions();
  }
  if (
    user.accountMemberships.some(
      (account) => account.status === AccountMembershipStatus.active,
    )
  ) {
    return new ArPermissions(
      { can, cannot },
      permissionsContext,
    ).grantPermissions();
  }
};

export const createUserAbilities = (
  createPermissionsContext: CreatePermissionsContext,
) =>
  defineAbility((can, cannot) => {
    const { user, companyId } = createPermissionsContext;

    const company = companyId
      ? user?.companies.find((c) => c.id === companyId)!
      : undefined;

    if (!user?.isRegistryAdmin && (!user || !company)) {
      return;
    }

    const permissionsContext = {
      user,
      company,
    };

    return grantPermissionsToUser(can, cannot, permissionsContext);
  });

export const updatePermissions = (
  ability: MongoAbility,
  user: UserDataResponse,
  companyId: string,
) => {
  const { can, cannot, rules } = new AbilityBuilder(createMongoAbility);

  const company = user.companies.find((c) => c.id === companyId);

  if (!user || !company) {
    return;
  }

  const permissionsContext = {
    user,
    company,
  };

  grantPermissionsToUser(can, cannot, permissionsContext);

  ability.update(rules);
};

export const AbilityContext = createContext(
  defineAbility((can, cannot) => {
    cannot('manage', 'all');
  }),
);

export const Can = createContextualCan(AbilityContext.Consumer);
