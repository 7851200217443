import { Action, Entity, PermissionClient } from './entities';
import { buildUnitPermissions } from './common.permissions';

export class CrPermissions extends PermissionClient {
  grantPermissions() {
    this.buildAccountPermissions();
    this.buildCompanyPermissions();
    this.buildUnitPermissions();
  }

  buildAccountPermissions() {
    const { user } = this.permissionsContext;

    if (user.status !== 'active') {
      return;
    }

    this.can(Action.ViewUnits, Entity.Account, {
      id: { $in: user.accountMemberships.map((a) => a.accountId) },
     });
    this.can(Action.View, Entity.Account);
    this.can(Action.Edit, Entity.Account);
    this.can(Action.Close, Entity.Account);
    this.can(Action.Create, Entity.Account);
    this.can(Action.Trust, Entity.Account);
  }

  buildCompanyPermissions() {
    const { user, company } = this.permissionsContext;
    const withCompanyId = { id: company?.id };
    this.can(Action.View, Entity.Company, withCompanyId);

    if (user.status !== 'active') {
      return;
    }

    this.can(Action.View, Entity.CompanyRepresentative);

    this.can(Action.Edit, Entity.Company, withCompanyId);
    this.can(Action.Close, Entity.Company, withCompanyId);

    this.can(Action.Edit, Entity.User);
    this.can(Action.Close, Entity.User);
    this.can(Action.View, Entity.User);

    this.can(Action.Edit, Entity.AccountRole);

    this.can(Action.View, Entity.CompanyUsers);
    this.can(Action.Invite, Entity.CompanyRepresentative);
    this.can(Action.Invite, Entity.AccountRepresentative);
    this.can(Action.Remove, Entity.CompanyRepresentative);
    this.can(Action.Remove, Entity.AccountRepresentative);

    this.can(Action.View, Entity.Billing);
  }

  buildUnitPermissions() {
    buildUnitPermissions(
      { can: this.can, cannot: this.cannot },
      this.permissionsContext,
    );
  }
}
