import { ConfirmModal } from '@safc/ui-components/components/modals/ConfirmModal';
import { RoutePaths } from '@safc/ui-utils/routes';
import { useNavigate } from 'react-router-dom';
import { authError } from '@safc/ui-utils/auth/useAuth';

export const Auth0Error = () => {
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const error = searchParams.get('authError') as keyof typeof authError | null;

  if (!error) {
    return null;
  }

  const message = (() => {
    switch (error) {
      case authError.inactivity: return 'Logged out due to inactivity';
      case authError.notRegistered: return 'User is not registered in the system';
      case authError.unknown: return 'Unknown authentication error. Please try again';
      default:
        const _: never = error;
        return 'Unknown authentication error.';
    }
  })();

  return (
    <ConfirmModal
      open
      actions={[
        {
          title: 'Close',
          onClick: () => {
            navigate(RoutePaths.HOME);
          },
          buttonProps: { variant: 'contained' },
        },
      ]}
    >
      {message}
    </ConfirmModal>
  );
};
