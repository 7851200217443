import { createContext, useContext } from 'react';

type SetSelectedCompany = (companyId: string) => void;

export type UndefinedContext = {
  companyId: undefined;
  companyName: undefined;
  setSelectedCompany: undefined;
};

export type DefinedContext = {
  companyId: string;
  companyName: string;
  setSelectedCompany: SetSelectedCompany;
};

export type RAContext = {
  companyId: null;
  companyName: null;
  setSelectedCompany: null;
};

export type CompanyContextProps = UndefinedContext | DefinedContext | RAContext;

export const CompanyContext = createContext<CompanyContextProps>({
  companyId: undefined,
  companyName: undefined,
  setSelectedCompany: undefined,
});

export const useCurrentCompany = <
  ContextType extends CompanyContextProps = DefinedContext,
>(): ContextType => {
  const { companyId, companyName } = useContext(CompanyContext);

  return { companyId, companyName } as ContextType;
};
