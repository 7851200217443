import { Select } from 'components/form/Select';
import { useCompanyContextSelectEffects } from './CompanyContextSelect.effects';

export const CompanyContextSelect = () => {
  const { selectedCompanyId, setCurrentCompany, field } =
    useCompanyContextSelectEffects();

  if (!selectedCompanyId || !setCurrentCompany || field.options.length < 2) {
    return null;
  }

  return (
    <Select
      value={selectedCompanyId}
      onChange={(event) => setCurrentCompany(event.target.value)}
      field={field}
    />
  );
};
