export enum DateFormatEnum {
  MDY = 'MMM DD, YYYY',
  DMYD = 'DD-MM-YYYY',
  YMD = 'YYYY-MM-DD',
  MDYT = 'MMM DD, YYYY HH:mm',
  ISO = 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  DMY = 'DD MMM, YYYY',
  DM = 'DD MMMM',
  MD = 'MMMM DD',
  MY = 'MMMM YYYY',
  MDYHM = 'MMM DD, YYYY HH:mm',
  YYYY = 'YYYY'
}
export enum ErrorStatus {
  Unauthorized = 401,
  Forbidden = 403,
  GatewayTimeout = 504,
}

export enum IdParamsEnum {
  AccountId = 'accountId',
  UnitId = 'unitId',
  UserId = 'userId',
  ActionId = 'actionId',
  CompanyId = 'companyId',
  InvoiceId = 'invoiceId',
}

export enum ManifestEntities {
  Issue = 'Issue',
  Transfer = 'Transfer',
  Claim = 'Claim',
  Split = 'Split',
  Onboarding = 'Onboarding',
}

export enum EmissionsTypeEnum {
  eec = 'eec',
  el = 'el',
  ep = 'ep',
  etd = 'etd',
  eu = 'eu',
  esca = 'esca',
  eccs = 'eccs',
  eccr = 'eccr',
  eee = 'eee',
}

export enum AccountStatus {
  Active = 'Active',
  Closed = 'Closed',
  Blocked = 'Blocked',
  AwaitingReview = 'Awaiting Review',
  Rejected = 'Rejected',
  Removed = 'Closed',
}

