import type { ThemeOptions } from '@mui/material/styles';
import { variables } from './variables';

const { breakpoints, colors, typography } = variables;

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: colors.primary.main,
      dark: colors.primary.dark,
      disabled: colors.primary.disabled,
    },
    action: {
      active: colors.action.active,
    },
    success: {
      main: colors.success.main,
    },
    warning: {
      main: colors.warning.main,
      dark: colors.warning.dark,
    },
    error: {
      main: colors.error.main,
      dark: colors.error.dark,
    },
    info: {
      main: colors.info.main,
    },
    grey: {
      ...variables.colors.grey,
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
      disabled: colors.text.disabled,
    },
    background: {
      paper: variables.colors.paper,
      default: variables.colors.background,
    },
  },
  typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  breakpoints,
};
