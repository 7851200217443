import { alpha, styled } from '@mui/material/styles';
import type { CheckboxColorType } from './Checkbox.types';
import { icons } from '@safc/assets/icons';

export const CheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 19,
  height: 19,
  border: `1px solid ${theme.palette.grey[600]}`,
  boxShadow: `0px 4px 12px ${alpha(theme.palette.common.black, 0.12)}`,
  backgroundColor: theme.palette.background.paper,
}));

export const CheckboxCheckedIcon = styled(CheckboxIcon)<{
  color: CheckboxColorType;
}>(({ theme, color }) => ({
  backgroundColor: theme.palette[color ?? 'primary'].main,
  border: '1px solid transparent',
  '&:before': {
    display: 'block',
    width: 17,
    height: 17,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${color === 'primary' ? icons.checkSvg : icons.checkDarkSvg})`,
    boxShadow: `0px 4px 12px ${alpha(theme.palette.common.black, 0.12)}`,
    content: '""',
  },
}));

export const CheckboxIndeterminateIcon = styled(CheckboxIcon)<{
  color: CheckboxColorType;
}>(({ theme, color }) => ({
  backgroundColor: theme.palette[color ?? 'primary'].main,
  border: '1px solid transparent',
  '&:before': {
    display: 'block',
    width: 17,
    height: 17,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${color === 'primary' ? icons.minusSvg : icons.minusDarkSvg})`,
    boxShadow: `0px 4px 12px ${alpha(theme.palette.common.black, 0.12)}`,
    content: '""',
  },
}));

export const checkboxStyles = {
  padding: 0,
  '&:hover': { bgcolor: 'transparent' },
};
