import { Checkbox } from '@safc/ui-components/components/form/Checkbox';
import { FormControlLabel } from '@safc/ui-components/components/form/FormControlLabel';
import type { FormInputProps } from '@safc/ui-components/components/form/FormInput';
import { Controller } from 'react-hook-form';

export const FormCheckbox = ({ field, control }: FormInputProps) => {
  return (
    <Controller
      key={`${field.name}`}
      name={field.name}
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <FormControlLabel
            label={field?.label ?? ''}
            labelPlacement="end"
            control={
              <Checkbox
                name={field.name}
                disabled={field?.disabled}
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
              />
            }
          />
        );
      }}
    />
  );
};
