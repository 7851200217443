import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { COOKIE_CONSENT_NAME } from '@safc/configuration-frontend';

export const useCookieConsentBarEffects = () => {
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get(COOKIE_CONSENT_NAME);
    if (!cookieConsent || cookieConsent !== 'true') {
      setShowCookieConsent(true);
    }
  }, []);

  const handleAcceptCookieConsent = useCallback(() => {
    Cookies.set(COOKIE_CONSENT_NAME, 'true', { expires: 365 });
    setShowCookieConsent(false);
  }, [setShowCookieConsent]);

  return [showCookieConsent, handleAcceptCookieConsent] as const;
};
