import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import outlinedInputClasses from '@mui/material/OutlinedInput/outlinedInputClasses';
import iconButtonClasses from '@mui/material/IconButton/iconButtonClasses';
import inputBaseClasses from '@mui/material/InputBase/inputBaseClasses';
import inputAdornmentClasses from '@mui/material/InputAdornment/inputAdornmentClasses';
import formHelperTextClasses from '@mui/material/FormHelperText/formHelperTextClasses';
import Typography from '@mui/material/Typography';

export const StyledInput = styled(TextField)(({ theme }) => ({
  [`& .${outlinedInputClasses.root}`]: {
    borderRadius: 10,
    paddingRight: 0,
    backgroundColor: theme.palette.common.white,
    padding: 0,
    [`&.${inputBaseClasses.error}`]: {
      [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
        border: `1px solid ${theme.palette.error.main}`,
      },
      [`& .${outlinedInputClasses.input}`]: {
        color: theme.palette.error.main,
      },
    },
    [`&.${inputBaseClasses.disabled}`]: {
      backgroundColor: theme.palette.info.main,
      [`& .${outlinedInputClasses.input}`]: {
        color: theme.palette.grey[800],
      },
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        border: 'none !important',
      },
      [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
        border: 'none !important',
      },
    },
    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
      border: `1px solid ${theme.palette.grey[600]} !important`,
    },
    [`& .${iconButtonClasses.root}`]: {
      cursor: 'text',
    },
    [`&.${inputBaseClasses.focused}`]: {
      [`& .${iconButtonClasses.root}`]: {
        opacity: 1,
        cursor: 'pointer',
      },
      [`& .${outlinedInputClasses.input}`]: {
        caretColor: theme.palette.primary.main,
      },
    },
  },
  [`& .${outlinedInputClasses.input}`]: {
    padding: '11px 16px',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.primary.main,
    fontFamily: theme.typography.body2.fontFamily,
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    minHeight: 24,
    '&::placeholder': {
      color: theme.palette.grey[800],
      opacity: 0.4,
    },
  },
  [`& .${inputAdornmentClasses.positionEnd}`]: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
    color: alpha(theme.palette.grey[800], 0.75),
    fontFamily: theme.typography.body1.fontFamily,
    paddingRight: 16,
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: `1px solid ${theme.palette.grey[600]} !important`,
    borderRadius: 10,
    borderWidth: '1px !important',
  },
  [`& .${formHelperTextClasses.root}`]: {
    position: 'absolute',
    top: '100%',
    margin: '4px 0 0',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    color: theme.palette.text.primary,
    fontFamily: theme.typography.body2.fontFamily,
  },
}));

export const InputAdornmentText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '16px',
  color: theme.palette.text.secondary,
  fontFamily: theme.typography.body1.fontFamily,
  marginLeft: theme.spacing(0.5),
}));
