import { Route } from 'react-router-dom';
import { RoutePaths } from '@safc/ui-utils/routes';
import { asLazy } from 'utils/lazy.utils';
import { Collaboration } from '../../pages/Home/Collaboration';
const TermsAndConditionsPage = asLazy(
  import('../../pages/TermsAndConditions'),
  'TermsAndConditionsPage',
);
const Homepage = asLazy(import('../../pages/Home/Homepage'), 'Homepage');
const PublicUnitsPage = asLazy(
  import('../../pages/PublicUnitsPage'),
  'PublicUnitsPage',
);

export const usePublicRoutes = () => {
  return (
    <>
      <Route
        path={RoutePaths.RETIREMENT}
        element={<PublicUnitsPage type="Claim" />}
      />
      <Route path={RoutePaths.TERMS} element={<TermsAndConditionsPage />} />
      <Route path={RoutePaths.HOME} element={<Homepage />} />
      <Route path={RoutePaths.COLLABORATION} element={<Collaboration />} />
    </>
  );
};
