import { Claim } from './claim';
import { Issue } from './issue';
import { IssueMeta } from './issue-metadata';
import { Onboarding } from './onboarding';
import { Split } from './split';
import { Transfer } from './transfer';

export const manifest = {
  System: {},
  Entities: {
    Claim,
    Issue,
    IssueMeta,
    Onboarding,
    Split,
    Transfer,
  },
};
