import FormLabel from '@mui/material/FormLabel';
import { alpha, styled } from '@mui/material/styles';

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: alpha(theme.palette.common.black, 0.75),
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 600,
  marginBottom: '10px',
  '&:focus': {
    color: alpha(theme.palette.common.black, 0.75),
  },
}));
