import { Outlet } from 'react-router-dom';
import GlobalStyles from '@mui/material/GlobalStyles';
import { Wrapper } from './AuthLayout.styled';
import { RegisterSidebar } from 'components/common/RegisterSidebar';

export const AuthLayout = () => {
  return (
    <Wrapper>
      <GlobalStyles
        styles={{
          '& html body': {
            overflowY: 'scroll',
          },
        }}
      />
      <RegisterSidebar />
      {<Outlet />}
    </Wrapper>
  );
};
