/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * SAFc Backend
 * The 24/7 API description
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AccountControllerCloseAccountRepParams,
  AccountControllerCreateParams,
  AccountControllerGetMyAccountsParams,
  AccountControllerGetUserAccountsParams,
  AccountControllerListAccountsToTransferParams,
  AccountControllerUpdateAccountParams,
  AccountCreationResponse,
  AccountInfoResponse,
  AccountResponse,
  AccountWithRoleResponse,
  AddAccountRepRequest,
  BlockAccountRequest,
  ChangeRoleRequest,
  CreateAccountRequest,
  RevokeARInvitationRequest,
  SimpleAccountResponse,
  ToggleTrustedAccountRequest,
  UpdateAccountRequest
} from './sAFcBackend.schemas'
import accountControllerListAccountsToTransferMutator from '../response-type';
import accountControllerGetMutator from '../response-type';
import accountControllerUpdateAccountMutator from '../response-type';
import accountControllerUpdateTrustedRecipientMutator from '../response-type';
import accountControllerCreateMutator from '../response-type';
import accountControllerGetMyAccountsMutator from '../response-type';
import accountControllerGetUserAccountsMutator from '../response-type';
import accountControllerAddAccountRepMutator from '../response-type';
import accountControllerRevokeAccountRepInviteMutator from '../response-type';
import accountControllerCloseAccountMutator from '../response-type';
import accountControllerCloseAccountRepMutator from '../response-type';
import accountControllerChangeRoleMutator from '../response-type';
import accountControllerBlockAccountMutator from '../response-type';
import accountControllerUnblockAccountMutator from '../response-type';
import { customFormData } from '../../mutators/custom-file-data';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


export const accountControllerListAccountsToTransfer = (
    params: AccountControllerListAccountsToTransferParams,
 options?: SecondParameter<typeof accountControllerListAccountsToTransferMutator>,signal?: AbortSignal
) => {
      
      
      return accountControllerListAccountsToTransferMutator<SimpleAccountResponse[]>(
      {url: `/api/account/to-transfer`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getAccountControllerListAccountsToTransferQueryKey = (params: AccountControllerListAccountsToTransferParams,) => {
    
    return [`/api/account/to-transfer`, ...(params ? [params]: [])] as const;
    }

    
export const getAccountControllerListAccountsToTransferQueryOptions = <TData = Awaited<ReturnType<typeof accountControllerListAccountsToTransfer>>, TError = unknown>(params: AccountControllerListAccountsToTransferParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof accountControllerListAccountsToTransfer>>, TError, TData>, request?: SecondParameter<typeof accountControllerListAccountsToTransferMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAccountControllerListAccountsToTransferQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof accountControllerListAccountsToTransfer>>> = ({ signal }) => accountControllerListAccountsToTransfer(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof accountControllerListAccountsToTransfer>>, TError, TData> & { queryKey: QueryKey }
}

export type AccountControllerListAccountsToTransferQueryResult = NonNullable<Awaited<ReturnType<typeof accountControllerListAccountsToTransfer>>>
export type AccountControllerListAccountsToTransferQueryError = unknown

export const useAccountControllerListAccountsToTransfer = <TData = Awaited<ReturnType<typeof accountControllerListAccountsToTransfer>>, TError = unknown>(
 params: AccountControllerListAccountsToTransferParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof accountControllerListAccountsToTransfer>>, TError, TData>, request?: SecondParameter<typeof accountControllerListAccountsToTransferMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAccountControllerListAccountsToTransferQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const accountControllerGet = (
    accountId: string,
 options?: SecondParameter<typeof accountControllerGetMutator>,signal?: AbortSignal
) => {
      
      
      return accountControllerGetMutator<AccountInfoResponse>(
      {url: `/api/account/${accountId}`, method: 'get', signal
    },
      options);
    }
  

export const getAccountControllerGetQueryKey = (accountId: string,) => {
    
    return [`/api/account/${accountId}`] as const;
    }

    
export const getAccountControllerGetQueryOptions = <TData = Awaited<ReturnType<typeof accountControllerGet>>, TError = unknown>(accountId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof accountControllerGet>>, TError, TData>, request?: SecondParameter<typeof accountControllerGetMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAccountControllerGetQueryKey(accountId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof accountControllerGet>>> = ({ signal }) => accountControllerGet(accountId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof accountControllerGet>>, TError, TData> & { queryKey: QueryKey }
}

export type AccountControllerGetQueryResult = NonNullable<Awaited<ReturnType<typeof accountControllerGet>>>
export type AccountControllerGetQueryError = unknown

export const useAccountControllerGet = <TData = Awaited<ReturnType<typeof accountControllerGet>>, TError = unknown>(
 accountId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof accountControllerGet>>, TError, TData>, request?: SecondParameter<typeof accountControllerGetMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAccountControllerGetQueryOptions(accountId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update account details
 */
export const accountControllerUpdateAccount = (
    accountId: string,
    updateAccountRequest: UpdateAccountRequest,
    params?: AccountControllerUpdateAccountParams,
 options?: SecondParameter<typeof accountControllerUpdateAccountMutator>,) => {
      
      const formData = customFormData(updateAccountRequest)
      return accountControllerUpdateAccountMutator<void>(
      {url: `/api/account/${accountId}`, method: 'patch',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData,
        params
    },
      options);
    }
  


export const getAccountControllerUpdateAccountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerUpdateAccount>>, TError,{accountId: string;data: UpdateAccountRequest;params?: AccountControllerUpdateAccountParams}, TContext>, request?: SecondParameter<typeof accountControllerUpdateAccountMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof accountControllerUpdateAccount>>, TError,{accountId: string;data: UpdateAccountRequest;params?: AccountControllerUpdateAccountParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountControllerUpdateAccount>>, {accountId: string;data: UpdateAccountRequest;params?: AccountControllerUpdateAccountParams}> = (props) => {
          const {accountId,data,params} = props ?? {};

          return  accountControllerUpdateAccount(accountId,data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AccountControllerUpdateAccountMutationResult = NonNullable<Awaited<ReturnType<typeof accountControllerUpdateAccount>>>
    export type AccountControllerUpdateAccountMutationBody = UpdateAccountRequest
    export type AccountControllerUpdateAccountMutationError = unknown

    export const useAccountControllerUpdateAccount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerUpdateAccount>>, TError,{accountId: string;data: UpdateAccountRequest;params?: AccountControllerUpdateAccountParams}, TContext>, request?: SecondParameter<typeof accountControllerUpdateAccountMutator>}
) => {

      const mutationOptions = getAccountControllerUpdateAccountMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update list of trusted recipients for account
 */
export const accountControllerUpdateTrustedRecipient = (
    accountId: string,
    toggleTrustedAccountRequest: ToggleTrustedAccountRequest,
 options?: SecondParameter<typeof accountControllerUpdateTrustedRecipientMutator>,) => {
      
      
      return accountControllerUpdateTrustedRecipientMutator<void>(
      {url: `/api/account/${accountId}/trusted`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: toggleTrustedAccountRequest
    },
      options);
    }
  


export const getAccountControllerUpdateTrustedRecipientMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerUpdateTrustedRecipient>>, TError,{accountId: string;data: ToggleTrustedAccountRequest}, TContext>, request?: SecondParameter<typeof accountControllerUpdateTrustedRecipientMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof accountControllerUpdateTrustedRecipient>>, TError,{accountId: string;data: ToggleTrustedAccountRequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountControllerUpdateTrustedRecipient>>, {accountId: string;data: ToggleTrustedAccountRequest}> = (props) => {
          const {accountId,data} = props ?? {};

          return  accountControllerUpdateTrustedRecipient(accountId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AccountControllerUpdateTrustedRecipientMutationResult = NonNullable<Awaited<ReturnType<typeof accountControllerUpdateTrustedRecipient>>>
    export type AccountControllerUpdateTrustedRecipientMutationBody = ToggleTrustedAccountRequest
    export type AccountControllerUpdateTrustedRecipientMutationError = unknown

    export const useAccountControllerUpdateTrustedRecipient = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerUpdateTrustedRecipient>>, TError,{accountId: string;data: ToggleTrustedAccountRequest}, TContext>, request?: SecondParameter<typeof accountControllerUpdateTrustedRecipientMutator>}
) => {

      const mutationOptions = getAccountControllerUpdateTrustedRecipientMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create an account
 */
export const accountControllerCreate = (
    createAccountRequest: CreateAccountRequest,
    params: AccountControllerCreateParams,
 options?: SecondParameter<typeof accountControllerCreateMutator>,) => {
      
      const formData = customFormData(createAccountRequest)
      return accountControllerCreateMutator<AccountCreationResponse>(
      {url: `/api/account`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData,
        params
    },
      options);
    }
  


export const getAccountControllerCreateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerCreate>>, TError,{data: CreateAccountRequest;params: AccountControllerCreateParams}, TContext>, request?: SecondParameter<typeof accountControllerCreateMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof accountControllerCreate>>, TError,{data: CreateAccountRequest;params: AccountControllerCreateParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountControllerCreate>>, {data: CreateAccountRequest;params: AccountControllerCreateParams}> = (props) => {
          const {data,params} = props ?? {};

          return  accountControllerCreate(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AccountControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof accountControllerCreate>>>
    export type AccountControllerCreateMutationBody = CreateAccountRequest
    export type AccountControllerCreateMutationError = unknown

    export const useAccountControllerCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerCreate>>, TError,{data: CreateAccountRequest;params: AccountControllerCreateParams}, TContext>, request?: SecondParameter<typeof accountControllerCreateMutator>}
) => {

      const mutationOptions = getAccountControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const accountControllerGetMyAccounts = (
    params?: AccountControllerGetMyAccountsParams,
 options?: SecondParameter<typeof accountControllerGetMyAccountsMutator>,signal?: AbortSignal
) => {
      
      
      return accountControllerGetMyAccountsMutator<AccountResponse[]>(
      {url: `/api/account`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getAccountControllerGetMyAccountsQueryKey = (params?: AccountControllerGetMyAccountsParams,) => {
    
    return [`/api/account`, ...(params ? [params]: [])] as const;
    }

    
export const getAccountControllerGetMyAccountsQueryOptions = <TData = Awaited<ReturnType<typeof accountControllerGetMyAccounts>>, TError = unknown>(params?: AccountControllerGetMyAccountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof accountControllerGetMyAccounts>>, TError, TData>, request?: SecondParameter<typeof accountControllerGetMyAccountsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAccountControllerGetMyAccountsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof accountControllerGetMyAccounts>>> = ({ signal }) => accountControllerGetMyAccounts(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof accountControllerGetMyAccounts>>, TError, TData> & { queryKey: QueryKey }
}

export type AccountControllerGetMyAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof accountControllerGetMyAccounts>>>
export type AccountControllerGetMyAccountsQueryError = unknown

export const useAccountControllerGetMyAccounts = <TData = Awaited<ReturnType<typeof accountControllerGetMyAccounts>>, TError = unknown>(
 params?: AccountControllerGetMyAccountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof accountControllerGetMyAccounts>>, TError, TData>, request?: SecondParameter<typeof accountControllerGetMyAccountsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAccountControllerGetMyAccountsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const accountControllerGetUserAccounts = (
    userId: string,
    params: AccountControllerGetUserAccountsParams,
 options?: SecondParameter<typeof accountControllerGetUserAccountsMutator>,signal?: AbortSignal
) => {
      
      
      return accountControllerGetUserAccountsMutator<AccountWithRoleResponse[]>(
      {url: `/api/account/by-user/${userId}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getAccountControllerGetUserAccountsQueryKey = (userId: string,
    params: AccountControllerGetUserAccountsParams,) => {
    
    return [`/api/account/by-user/${userId}`, ...(params ? [params]: [])] as const;
    }

    
export const getAccountControllerGetUserAccountsQueryOptions = <TData = Awaited<ReturnType<typeof accountControllerGetUserAccounts>>, TError = unknown>(userId: string,
    params: AccountControllerGetUserAccountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof accountControllerGetUserAccounts>>, TError, TData>, request?: SecondParameter<typeof accountControllerGetUserAccountsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAccountControllerGetUserAccountsQueryKey(userId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof accountControllerGetUserAccounts>>> = ({ signal }) => accountControllerGetUserAccounts(userId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof accountControllerGetUserAccounts>>, TError, TData> & { queryKey: QueryKey }
}

export type AccountControllerGetUserAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof accountControllerGetUserAccounts>>>
export type AccountControllerGetUserAccountsQueryError = unknown

export const useAccountControllerGetUserAccounts = <TData = Awaited<ReturnType<typeof accountControllerGetUserAccounts>>, TError = unknown>(
 userId: string,
    params: AccountControllerGetUserAccountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof accountControllerGetUserAccounts>>, TError, TData>, request?: SecondParameter<typeof accountControllerGetUserAccountsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAccountControllerGetUserAccountsQueryOptions(userId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create invitation to become given account's representative
 */
export const accountControllerAddAccountRep = (
    id: string,
    addAccountRepRequest: AddAccountRepRequest,
 options?: SecondParameter<typeof accountControllerAddAccountRepMutator>,) => {
      
      
      return accountControllerAddAccountRepMutator<void>(
      {url: `/api/account/${id}/account-rep`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: addAccountRepRequest
    },
      options);
    }
  


export const getAccountControllerAddAccountRepMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerAddAccountRep>>, TError,{id: string;data: AddAccountRepRequest}, TContext>, request?: SecondParameter<typeof accountControllerAddAccountRepMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof accountControllerAddAccountRep>>, TError,{id: string;data: AddAccountRepRequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountControllerAddAccountRep>>, {id: string;data: AddAccountRepRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  accountControllerAddAccountRep(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AccountControllerAddAccountRepMutationResult = NonNullable<Awaited<ReturnType<typeof accountControllerAddAccountRep>>>
    export type AccountControllerAddAccountRepMutationBody = AddAccountRepRequest
    export type AccountControllerAddAccountRepMutationError = unknown

    export const useAccountControllerAddAccountRep = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerAddAccountRep>>, TError,{id: string;data: AddAccountRepRequest}, TContext>, request?: SecondParameter<typeof accountControllerAddAccountRepMutator>}
) => {

      const mutationOptions = getAccountControllerAddAccountRepMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Revoke invitation to given account
 */
export const accountControllerRevokeAccountRepInvite = (
    id: string,
    revokeARInvitationRequest: RevokeARInvitationRequest,
 options?: SecondParameter<typeof accountControllerRevokeAccountRepInviteMutator>,) => {
      
      
      return accountControllerRevokeAccountRepInviteMutator<void>(
      {url: `/api/account/${id}/revoke-invitation`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: revokeARInvitationRequest
    },
      options);
    }
  


export const getAccountControllerRevokeAccountRepInviteMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerRevokeAccountRepInvite>>, TError,{id: string;data: RevokeARInvitationRequest}, TContext>, request?: SecondParameter<typeof accountControllerRevokeAccountRepInviteMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof accountControllerRevokeAccountRepInvite>>, TError,{id: string;data: RevokeARInvitationRequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountControllerRevokeAccountRepInvite>>, {id: string;data: RevokeARInvitationRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  accountControllerRevokeAccountRepInvite(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AccountControllerRevokeAccountRepInviteMutationResult = NonNullable<Awaited<ReturnType<typeof accountControllerRevokeAccountRepInvite>>>
    export type AccountControllerRevokeAccountRepInviteMutationBody = RevokeARInvitationRequest
    export type AccountControllerRevokeAccountRepInviteMutationError = unknown

    export const useAccountControllerRevokeAccountRepInvite = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerRevokeAccountRepInvite>>, TError,{id: string;data: RevokeARInvitationRequest}, TContext>, request?: SecondParameter<typeof accountControllerRevokeAccountRepInviteMutator>}
) => {

      const mutationOptions = getAccountControllerRevokeAccountRepInviteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Close an account
 */
export const accountControllerCloseAccount = (
    id: string,
 options?: SecondParameter<typeof accountControllerCloseAccountMutator>,) => {
      
      
      return accountControllerCloseAccountMutator<void>(
      {url: `/api/account/${id}/close`, method: 'post'
    },
      options);
    }
  


export const getAccountControllerCloseAccountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerCloseAccount>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof accountControllerCloseAccountMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof accountControllerCloseAccount>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountControllerCloseAccount>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  accountControllerCloseAccount(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AccountControllerCloseAccountMutationResult = NonNullable<Awaited<ReturnType<typeof accountControllerCloseAccount>>>
    
    export type AccountControllerCloseAccountMutationError = unknown

    export const useAccountControllerCloseAccount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerCloseAccount>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof accountControllerCloseAccountMutator>}
) => {

      const mutationOptions = getAccountControllerCloseAccountMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Remove account representative from account
 */
export const accountControllerCloseAccountRep = (
    id: string,
    accountRepresentativeId: string,
    params?: AccountControllerCloseAccountRepParams,
 options?: SecondParameter<typeof accountControllerCloseAccountRepMutator>,) => {
      
      
      return accountControllerCloseAccountRepMutator<void>(
      {url: `/api/account/${id}/account-rep/${accountRepresentativeId}`, method: 'delete',
        params
    },
      options);
    }
  


export const getAccountControllerCloseAccountRepMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerCloseAccountRep>>, TError,{id: string;accountRepresentativeId: string;params?: AccountControllerCloseAccountRepParams}, TContext>, request?: SecondParameter<typeof accountControllerCloseAccountRepMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof accountControllerCloseAccountRep>>, TError,{id: string;accountRepresentativeId: string;params?: AccountControllerCloseAccountRepParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountControllerCloseAccountRep>>, {id: string;accountRepresentativeId: string;params?: AccountControllerCloseAccountRepParams}> = (props) => {
          const {id,accountRepresentativeId,params} = props ?? {};

          return  accountControllerCloseAccountRep(id,accountRepresentativeId,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AccountControllerCloseAccountRepMutationResult = NonNullable<Awaited<ReturnType<typeof accountControllerCloseAccountRep>>>
    
    export type AccountControllerCloseAccountRepMutationError = unknown

    export const useAccountControllerCloseAccountRep = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerCloseAccountRep>>, TError,{id: string;accountRepresentativeId: string;params?: AccountControllerCloseAccountRepParams}, TContext>, request?: SecondParameter<typeof accountControllerCloseAccountRepMutator>}
) => {

      const mutationOptions = getAccountControllerCloseAccountRepMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update account representative role
 */
export const accountControllerChangeRole = (
    id: string,
    userId: string,
    changeRoleRequest: ChangeRoleRequest,
 options?: SecondParameter<typeof accountControllerChangeRoleMutator>,) => {
      
      
      return accountControllerChangeRoleMutator<void>(
      {url: `/api/account/${id}/account-rep/${userId}/role`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: changeRoleRequest
    },
      options);
    }
  


export const getAccountControllerChangeRoleMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerChangeRole>>, TError,{id: string;userId: string;data: ChangeRoleRequest}, TContext>, request?: SecondParameter<typeof accountControllerChangeRoleMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof accountControllerChangeRole>>, TError,{id: string;userId: string;data: ChangeRoleRequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountControllerChangeRole>>, {id: string;userId: string;data: ChangeRoleRequest}> = (props) => {
          const {id,userId,data} = props ?? {};

          return  accountControllerChangeRole(id,userId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AccountControllerChangeRoleMutationResult = NonNullable<Awaited<ReturnType<typeof accountControllerChangeRole>>>
    export type AccountControllerChangeRoleMutationBody = ChangeRoleRequest
    export type AccountControllerChangeRoleMutationError = unknown

    export const useAccountControllerChangeRole = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerChangeRole>>, TError,{id: string;userId: string;data: ChangeRoleRequest}, TContext>, request?: SecondParameter<typeof accountControllerChangeRoleMutator>}
) => {

      const mutationOptions = getAccountControllerChangeRoleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Block an account
 */
export const accountControllerBlockAccount = (
    accountId: string,
    blockAccountRequest: BlockAccountRequest,
 options?: SecondParameter<typeof accountControllerBlockAccountMutator>,) => {
      
      
      return accountControllerBlockAccountMutator<void>(
      {url: `/api/account/${accountId}/block`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: blockAccountRequest
    },
      options);
    }
  


export const getAccountControllerBlockAccountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerBlockAccount>>, TError,{accountId: string;data: BlockAccountRequest}, TContext>, request?: SecondParameter<typeof accountControllerBlockAccountMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof accountControllerBlockAccount>>, TError,{accountId: string;data: BlockAccountRequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountControllerBlockAccount>>, {accountId: string;data: BlockAccountRequest}> = (props) => {
          const {accountId,data} = props ?? {};

          return  accountControllerBlockAccount(accountId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AccountControllerBlockAccountMutationResult = NonNullable<Awaited<ReturnType<typeof accountControllerBlockAccount>>>
    export type AccountControllerBlockAccountMutationBody = BlockAccountRequest
    export type AccountControllerBlockAccountMutationError = unknown

    export const useAccountControllerBlockAccount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerBlockAccount>>, TError,{accountId: string;data: BlockAccountRequest}, TContext>, request?: SecondParameter<typeof accountControllerBlockAccountMutator>}
) => {

      const mutationOptions = getAccountControllerBlockAccountMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Unblock an account
 */
export const accountControllerUnblockAccount = (
    accountId: string,
 options?: SecondParameter<typeof accountControllerUnblockAccountMutator>,) => {
      
      
      return accountControllerUnblockAccountMutator<void>(
      {url: `/api/account/${accountId}/unblock`, method: 'patch'
    },
      options);
    }
  


export const getAccountControllerUnblockAccountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerUnblockAccount>>, TError,{accountId: string}, TContext>, request?: SecondParameter<typeof accountControllerUnblockAccountMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof accountControllerUnblockAccount>>, TError,{accountId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountControllerUnblockAccount>>, {accountId: string}> = (props) => {
          const {accountId} = props ?? {};

          return  accountControllerUnblockAccount(accountId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AccountControllerUnblockAccountMutationResult = NonNullable<Awaited<ReturnType<typeof accountControllerUnblockAccount>>>
    
    export type AccountControllerUnblockAccountMutationError = unknown

    export const useAccountControllerUnblockAccount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerUnblockAccount>>, TError,{accountId: string}, TContext>, request?: SecondParameter<typeof accountControllerUnblockAccountMutator>}
) => {

      const mutationOptions = getAccountControllerUnblockAccountMutationOptions(options);

      return useMutation(mutationOptions);
    }
    