import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  padding: '72px 12px 20px 92px',
  [theme.breakpoints.down('md')]: {
    padding: '80px 16px 20px 16px',
  },
}));
