import type { MouseEvent } from 'react';
import { useState } from 'react';
import { RoutePaths } from '@safc/ui-utils/routes';
import { useNavigate } from 'react-router-dom';

export const useTopBarAvatarEffects = () => {
  const [menuAnchorElement, setMenuAnchor] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const navigateToProfile = () => {
    closeMenu();
    navigate(RoutePaths.PROFILE_SETTINGS);
  };

  const navigateToBilling = () => {
    closeMenu();
    navigate(RoutePaths.BILLING);
  };

  const navigateToTermsAndConditions = () => {
    closeMenu();
    window.open(RoutePaths.TERMS, '_blank');
  };

  return {
    closeMenu,
    openMenu,
    menuAnchorElement,
    isMenuOpen: Boolean(menuAnchorElement),
    navigateToProfile,
    navigateToBilling,
    navigateToTermsAndConditions,
  };
};
