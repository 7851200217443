import type { FC } from 'react';
import { useSideBarEffects } from './SideBar.effects';
import { Drawer } from './SideBar.styles';
import type { SideBarProps } from './SideBar.types';

export const SideBar: FC<SideBarProps> = ({ isNavOpen, children }) => {
  const { drawerOpen, drawerVariant } = useSideBarEffects({ isNavOpen });

  return (
    <Drawer open={drawerOpen} anchor="left" variant={drawerVariant}>
      {children}
    </Drawer>
  );
};
