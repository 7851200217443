import type { FC } from 'react';
import { memo } from 'react';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { Label, ListItemButton } from './NavMenuItem.styles';
import type { IconKey } from '@safc/assets/Icon';
import { Icon } from '@safc/assets/Icon';

export type TMenuItem = {
  url: string;
  label: string;
  icon: IconKey;
  testId?: string;
};

export const NavMenuItem: FC<TMenuItem> = memo(
  ({ url, label, icon, testId }) => {
    const resolvedPath = useResolvedPath(url);
    const match = useMatch({ path: resolvedPath.pathname, end: false });
    const selected = Boolean(match);

    return (
      <ListItemButton
        component={NavLink}
        selected={selected}
        to={url}
        disableGutters
        disableRipple
        data-testid={testId}
      >
        <Icon
          icon={icon}
          sx={{ mb: 0.85, color: 'red', height: 18, width: 18 }}
        />
        <Label variant="body1">{label}</Label>
      </ListItemButton>
    );
  },
);

NavMenuItem.displayName = 'NavMenuItem';
