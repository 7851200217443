import type { TSizes } from './Avatar.types';

export const sizes: TSizes = {
  small: {
    width: 16,
    height: 16,
  },
  medium: {
    width: 32,
    height: 32,
  },
  large: {
    width: 48,
    height: 48,
  },
};
