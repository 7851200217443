import type { LandingPageDataResponse } from '@safc/api-client';
import { usePublicControllerGetLandingPageStats } from '@safc/api-client';
import { publicRoutes } from '@safc/ui-utils/routes';
import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { MouseEvent } from 'react';
import { useAppAuth } from '../../hooks/auth/useAppAuth';

export const useHomepageEffects = (isMobile: boolean) => {
  // const { data, isLoading } = usePublicControllerGetLandingPageStats();
  const [scrolled, setScrolled] = useState(false);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  // const landingPageData = data ?? ({} as LandingPageDataResponse);
  const aboutRef = useRef<HTMLElement>(null);
  const documentationRef = useRef<HTMLElement>(null);
  const contactUsRef = useRef<HTMLElement>(null);

  const { login } = useAppAuth();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 64) {
        return setScrolled(true);
      }
      return setScrolled(false);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = (currentRef: React.RefObject<HTMLElement>) => {
    if (currentRef.current) {
      if (isMobile) {
          setTimeout(() => {
            currentRef.current!.scrollIntoView({
              behavior: 'smooth',
            });
          }, 100);
      } else {
          currentRef.current.scrollIntoView({
            behavior: 'smooth',
          });
      }
    }
  };

  const toggleDrawer = useCallback(() => {
    setIsDrawerOpened((state) => !state);
  }, [setIsDrawerOpened]);

  const handleAboutClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleScroll(aboutRef);
  };

  const handleDocumentationClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleScroll(documentationRef);
  };

  const handleContactUsClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleScroll(contactUsRef);
  };

  // const {
  //   supplyCountriesOfOrigin,
  //   supplyFacilities,
  //   safcABeneficiaries,
  //   safcEBeneficiaries,
  //   safCaVolume,
  //   logisticsProviderBeneficiaries,
  //   co2Abated,
  // } = landingPageData;

  // const issuanceStatisticProps = {
  //   buttonText: 'Visit the issuance table',
  //   header: {
  //     stat: safCaVolume?.toLocaleString('en-US'),
  //     explainer: 'Metric tonnes of neat SAF recorded in the Registry',
  //   },
  //   stats: [
  //     {
  //       stat: supplyFacilities?.toLocaleString('en-US'),
  //       explainer: 'Fuel producing facilities',
  //     },
  //     {
  //       stat: supplyCountriesOfOrigin?.toLocaleString('en-US'),
  //       explainer: 'Fuel producing countries',
  //     },
  //   ],
  // };

  // const retirementStatisticProps = {
  //   buttonText: 'See more details on retirements',
  //   link: publicRoutes.RETIREMENT,
  //   header: {
  //     stat: co2Abated?.toLocaleString('en-US'),
  //     explainer: 'Metric tonnes of CO2e abated from SAFc retired',
  //   },
  //   stats: [
  //     {
  //       stat: safcEBeneficiaries?.toLocaleString('en-US'),
  //       explainer: 'Aviation customers',
  //     },
  //     {
  //       stat: safcABeneficiaries?.toLocaleString('en-US'),
  //       explainer: 'Air transport providers',
  //     },
  //     {
  //       stat: logisticsProviderBeneficiaries?.toLocaleString('en-US'),
  //       explainer: 'Logistics service providers',
  //     },
  //   ],
  // };

  return {
    // retirementStatisticProps,
    // issuanceStatisticProps,
    // isLoading,
    aboutRef,
    documentationRef,
    handleAboutClick,
    handleDocumentationClick,
    login,
    scrolled,
    isDrawerOpened,
    toggleDrawer,
    contactUsRef,
    handleContactUsClick,
  };
};
