import {
  getAccountControllerGetMyAccountsQueryKey,
  useAccountControllerGetMyAccounts,
} from '@safc/api-client';
import { useCompanyContext } from './useCompanyContext';
import { createContext, useContext, useEffect } from 'react';
import first from 'lodash/first';
import { AbilityContext } from './permissions/usePermissions';
import { AccountEntity, Action } from './permissions/entities';

type SetSelectedAccount = (accountId?: string) => void;

export const AccountContext = createContext<{
  accountId: string | undefined;
  setSelectedAccountId: SetSelectedAccount | undefined;
}>({
  accountId: undefined,
  setSelectedAccountId: undefined,
});

export const useAccountUnitContext = () => {
  const { accountId, setSelectedAccountId } = useContext(AccountContext);
  const { companyId } = useCompanyContext();
  const ability = useContext(AbilityContext);
  const { data, isLoading } = useAccountControllerGetMyAccounts(
    { companyId: companyId ?? undefined },
    {
      query: {
        enabled: companyId !== undefined,
        queryKey: getAccountControllerGetMyAccountsQueryKey({
          companyId: companyId ?? undefined,
        }),
      },
    },
  );

  const holdingsAccounts = (data ?? []).filter((a) =>
    ability.can(Action.ViewUnits, new AccountEntity(a.id)),
  );

  const account =
    holdingsAccounts.find(({ id }) => accountId === id) ??
    first(holdingsAccounts);

  useEffect(() => {
    if (!accountId && holdingsAccounts?.[0]?.id) {
      setSelectedAccountId?.(holdingsAccounts[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, holdingsAccounts?.length]);

  useEffect(() => {
    if (holdingsAccounts?.length === 0 && !isLoading) {
      setSelectedAccountId?.(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!account) {
    return {
      accountId: undefined,
      accounts: undefined,
      setSelectedAccount: undefined,
      isLoading,
    };
  }

  return {
    accountId: account.id,
    setSelectedAccount: setSelectedAccountId,
    account,
    accounts: holdingsAccounts,
    isLoading,
  };
};
