import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import type { ZodType } from 'zod';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import type {
  GenericFormEffectsProps,
  FormSectionItem,
  FormItem,
} from './GenericForm.types';

export const useGenericFormEffects = ({
  initialValues,
  inputsToWatch,
  items = [],
  withSections,
  mode,
  onWatchHandler,
  shouldUpdateInitialValues,
  submitHandler,
}: GenericFormEffectsProps) => {
  const inputs = withSections
    ? items.flatMap((item) =>
        (item as FormSectionItem).fields.flatMap((field) => field.inputs),
      )
    : (items as FormItem[]);

  const formValidator = Object.fromEntries(
    inputs
      .filter((input) => input.config?.name && input.validate)
      .map((item) => [item.config.name, item.validate] as [string, ZodType]),
  );
  const defaultValues =
    initialValues ??
    Object.fromEntries(
      inputs.map((field) => [field.config?.name, field.initialValue]),
    );

  const form = useForm({
    mode: mode ?? 'onBlur',
    reValidateMode: 'onChange',
    resolver: zodResolver(z.object(formValidator)),
    defaultValues,
  });

  useEffect(() => {
    if (shouldUpdateInitialValues) {
      form.reset(defaultValues);
    }
  }, [JSON.stringify(defaultValues), shouldUpdateInitialValues]);

  const watchedFields =
    inputsToWatch && inputsToWatch.length > 0 ? form.watch(inputsToWatch as string[]) : [];

  const stringifiedWatchedFields = JSON.stringify(watchedFields);

  useEffect(() => {
    onWatchHandler && onWatchHandler(watchedFields, form);
  }, [stringifiedWatchedFields]);

  const onSubmit = form.handleSubmit((values) => submitHandler!(values));

  return {
    ...form,
    clearErrors: async (name?: string | string[] | readonly string[] | undefined) => {
      form.clearErrors(name);
      await form.trigger(name);
    },
    onSubmit,
  };
};
