import type { FC } from 'react';
import { memo } from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { stringToInitials } from './InitialsIcon.utils';
import { useRootStyles } from './InitialsIcon.styles';
import type { InitialsIconProps } from './InitialsIcon.types';

export const InitialsIcon: FC<InitialsIconProps> = memo(
  ({ text, color, size, type = 'circle', variant, ...props }) => {
    const rootStyles = useRootStyles({
      color,
      size,
      variant,
      text,
      type,
    });

    return (
      <Avatar component={'div'} {...props} sx={rootStyles}>
        <Typography variant="body1">{stringToInitials(text)}</Typography>
      </Avatar>
    );
  },
);

InitialsIcon.displayName = 'InitialsIcon';
