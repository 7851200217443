import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { paperClasses } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';

export const StyledDialog = styled(Dialog)({
  [`& .${paperClasses.root}`]: {
    minWidth: 540,
    minHeight: 350,
  },
});

export const StyledDialogContent = styled(DialogContent)({
  display: 'grid',
  placeItems: 'center',
});

export const StyledDialogActions = styled(DialogActions)({
  display: 'flex',
  justifyContent: 'center',
  padding: 0,
});

export const ButtonsWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
});

export const ContentWrapper = styled(Box)({
  marginBottom: 'auto',
  display: 'grid',
  placeItems: 'center',
});
