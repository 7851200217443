import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import type { BoxProps } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import type {
  Control,
  DeepMap,
  DeepPartial,
  FieldError,
  FieldValues,
  UseFormClearErrors,
  UseFormResetField,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import type { GenericFormProps, FormSectionItem } from '../GenericForm/GenericForm.types';
import { useFormSectionEffects } from './FormSection.effects';
import {
  Description,
  Label,
  StyledAsterisk,
  StyledDivider,
  StyledStepper,
  SubTitle,
  Title,
  FormGridContainer,
} from './FormSection.styled';
import { SingleColumnForm } from 'components/form/SingleColumnForm';
import { StyledTab, StyledTabs } from 'components/layout/StyledTabs';

interface FormSectionProps<
  FormValues extends FieldValues = Record<string, any>,
> {
  fields: FormSectionItem['fields'];
  control: Control<FormValues>;
  errors: DeepMap<DeepPartial<FormValues>, FieldError>;
  setError: UseFormSetError<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  resetField: UseFormResetField<FormValues>;
  clearErrors: UseFormClearErrors<FormValues>;
  showSubtitle?: boolean;
  showDivider?: boolean;
  title?: string;
  wrapperProps?: BoxProps;
  stepperProps?: GenericFormProps['stepperProps'];
}

export const FormSection = ({
  fields,
  showDivider,
  showSubtitle,
  title,
  stepperProps,
  wrapperProps,
  ...props
}: FormSectionProps) => {
  const { currentTab, onChangeTabHandler } = useFormSectionEffects();

  return (
    <Box mt={4} position="relative" width="100%" {...wrapperProps}>
      {title && (
        <>
          <Title>{title}</Title>
          {showSubtitle && (
            <SubTitle>
              <StyledAsterisk>*</StyledAsterisk> indicates required fields.
            </SubTitle>
          )}
        </>
      )}
      <Box mt={7}>
        {stepperProps?.steps && (
          <Box mb={3.75}>
            <StyledStepper activeStep={stepperProps.activeStep}>
              {stepperProps.steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </StyledStepper>
          </Box>
        )}
        {fields.map((field, index) => {
          return (
            <Box
              mb={5.5}
              key={`form-section-${field.label}-${index}`}
              {...field?.wrapperProps}
            >
              <FormGridContainer container>
                {field?.tabs ? (
                  <Box display="flex" flexDirection="column" width="100%">
                    <StyledTabs
                      value={currentTab}
                      onChange={onChangeTabHandler}
                    >
                      {field.tabs?.map((tab) => {
                        return (
                          <StyledTab
                            key={`tab-${tab.label}`}
                            label={tab.label}
                          />
                        );
                      })}
                    </StyledTabs>
                    <Grid item xs={12} md={4} {...field.labelWrapperProps}>
                      <Label style={{ marginTop: 20 }}>
                        {field.inputs[currentTab]?.config?.label ?? ''}
                      </Label>
                    </Grid>
                    <Grid item xs={12} md={8} {...field.inputsWrapperProps}>
                      <SingleColumnForm
                        fields={[field.inputs[currentTab]]}
                        {...props}
                      />
                    </Grid>
                  </Box>
                ) : (
                  <>
                    <Grid item xs={12} md={4} {...field.labelWrapperProps}>
                      <Label>
                        {field.label}{' '}
                        {field.required && <StyledAsterisk>*</StyledAsterisk>}
                      </Label>
                      {field?.description && (
                        <Description>{field.description}</Description>
                      )}
                    </Grid>
                    <Grid item xs={12} md={8} {...field.inputsWrapperProps}>
                      <SingleColumnForm fields={field.inputs} {...props} />
                    </Grid>
                  </>
                )}
              </FormGridContainer>
            </Box>
          );
        })}
      </Box>
      {showDivider && <StyledDivider />}
    </Box>
  );
};
