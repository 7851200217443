import type { PropsWithChildren } from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import { Wrapper } from './PageWrapper.styles';

export const PageWrapper = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Wrapper>
      <GlobalStyles
        styles={{
          '& html body': {
            overflowY: 'scroll',
          },
        }}
      />
      {children}
    </Wrapper>
  );
};
