import type { JSONSchema } from 'json-schema-to-typescript';
import dayjs from 'dayjs';

const issueSchema = () => ({
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  definitions: {
    booleanTrueReadOnly: {
      type: 'boolean',
      default: true,
      readOnly: true,
    },
    booleanFalseReadOnly: {
      type: 'boolean',
      default: false,
      readOnly: true,
    },
    emissions: {
      type: 'object',
      properties: {
        greenhouseGas: {
          type: 'object',
          properties: {
            recyclingEmissionsCredits: {
              type: 'number',
              minimum: 0,
            },
            landfillEmissionsCredits: {
              type: 'number',
              minimum: 0,
            },
          },
          required: [
            'recyclingEmissionsCredits',
            'landfillEmissionsCredits',
          ],
        },
      },
    },
    totalLCAValueincludeEmissionsFromDeliveryToAirport: {
      oneOf: [
        {
          type: 'object',
          properties: {
            totalLCAValueincludeEmissionsFromDeliveryToAirport: {
              enum: [false],
            },
            useDefaultValueForDeliveryToAirport: {
              type: 'boolean',
            },
          },
          required: ['useDefaultValueForDeliveryToAirport'],
        },
        {
          type: 'object',
          properties: {
            totalLCAValueincludeEmissionsFromDeliveryToAirport: {
              enum: [true],
            },
          },
        },
      ],
    },
    useDefaultValueForDeliveryToAirport: {
      oneOf: [
        {
          type: 'object',
          properties: {
            useDefaultValueForDeliveryToAirport: {
              enum: [false],
            },
            actualGHGIntensityAirportDelivery: {
              type: 'integer',
            },
          },
          required: ['actualGHGIntensityAirportDelivery'],
        },
        {
          type: 'object',
          properties: {
            useDefaultValueForDeliveryToAirport: {
              enum: [true],
            },
          },
        },
      ],
    },
    corsiaDefault: {
      type: 'object',
      properties: {
        greenhouseGas: {
          type: 'object',
          properties: {
            totalLCAValueCorsiaDefault: {
              type: 'number',
            },
            coreLCAValue: {
              type: 'number',
            },
            inducedLandUseChange: {
              type: 'number',
            },
            ghgDisplacementEmissions: {
              type: 'number',
            },
            totalLCAValueincludeEmissionsFromDeliveryToAirport: {
              type: 'boolean',
            },
          },
          required: [
            'totalLCAValueCorsiaDefault',
            'coreLCAValue',
            'inducedLandUseChange',
            'ghgDisplacementEmissions',
            'totalLCAValueincludeEmissionsFromDeliveryToAirport',
          ],
          dependencies: {
            totalLCAValueincludeEmissionsFromDeliveryToAirport: {
              $ref: '#/definitions/totalLCAValueincludeEmissionsFromDeliveryToAirport',
            },
            useDefaultValueForDeliveryToAirport: {
              $ref: '#/definitions/useDefaultValueForDeliveryToAirport',
            },
          },
        },
      },
    },
    corsiaActual: {
      type: 'object',
      properties: {
        greenhouseGas: {
          type: 'object',
          properties: {
            totalLCAValueCorsiaActual: {
              type: 'number',
            },
            inducedLandUseChange: {
              type: 'number',
            },
            production: {
              type: 'number',
            },
            conditioning: {
              type: 'number',
            },
            processing: {
              type: 'number',
            },
            transportation: {
              type: 'number',
            },
            conversionProcesses: {
              type: 'number',
            },
            fuelTransportation: {
              type: 'number',
            },
            combustion: {
              type: 'number',
            },
            ghgDisplacementEmissions: {
              type: 'number',
            },
            emissionsSavingsDueToSoilCarbonSequestration: {
              type: 'number',
              minimum: 0,
            },
            emissionsSavingsFromCSS: {
              type: 'number',
              minimum: 0,
            },
            totalLCAValueincludeEmissionsFromDeliveryToAirport: {
              type: 'boolean',
            },
          },
          required: [
            'totalLCAValueCorsiaActual',
            'inducedLandUseChange',
            'production',
            'conditioning',
            'processing',
            'transportation',
            'conversionProcesses',
            'fuelTransportation',
            'combustion',
            'totalLCAValueincludeEmissionsFromDeliveryToAirport',
          ],
          dependencies: {
            totalLCAValueincludeEmissionsFromDeliveryToAirport: {
              $ref: '#/definitions/totalLCAValueincludeEmissionsFromDeliveryToAirport',
            },
            useDefaultValueForDeliveryToAirport: {
              $ref: '#/definitions/useDefaultValueForDeliveryToAirport',
            },
          },
        },
      },
    },
    euDefault: {
      type: 'object',
      properties: {
        greenhouseGas: {
          type: 'object',
          properties: {
            totalLCAValueEuDefault: {
              type: 'number',
            },
            inducedLandUseChange: {
              type: 'number',
            },
            ghgDisplacementEmissions: {
              type: 'number',
            },
            totalLCAValueincludeEmissionsFromDeliveryToAirport: {
              type: 'boolean',
            },
          },
          required: [
            'totalLCAValueEuDefault',
            'inducedLandUseChange',
            'totalLCAValueincludeEmissionsFromDeliveryToAirport',
          ],
          dependencies: {
            totalLCAValueincludeEmissionsFromDeliveryToAirport: {
              $ref: '#/definitions/totalLCAValueincludeEmissionsFromDeliveryToAirport',
            },
            useDefaultValueForDeliveryToAirport: {
              $ref: '#/definitions/useDefaultValueForDeliveryToAirport',
            },
          },
        },
      },
    },
    euActual: {
      type: 'object',
      properties: {
        greenhouseGas: {
          type: 'object',
          properties: {
            totalLCAValueEuActual: {
              type: 'number',
            },
            inducedLandUseChange: {
              type: 'number',
            },
            displacementEmissions: {
              type: 'number',
            },
            extractionEmissions: {
              type: 'number',
            },
            annualisedEmissions: {
              type: 'number',
            },
            processingEmissions: {
              type: 'number',
            },
            transportEmissions: {
              type: 'number',
            },
            inUseFuelEmissions: {
              type: 'number',
            },
            savingsFromSoil: {
              type: 'number',
              minimum: 0,
            },
            savingsFromCarbonAndStorage: {
              type: 'number',
              minimum: 0,
            },
            savingsFromCarbonAndReplacement: {
              type: 'number',
              minimum: 0,
            },
            savingsFromExcess: {
              type: 'number',
              minimum: 0,
            },
            totalLCAValueincludeEmissionsFromDeliveryToAirport: {
              type: 'boolean',
            },
          },
          required: [
            'totalLCAValueEuActual',
            'extractionEmissions',
            'processingEmissions',
            'transportEmissions',
            'inUseFuelEmissions',
            'savingsFromSoil',
            'savingsFromCarbonAndStorage',
            'savingsFromCarbonAndReplacement',
            'inducedLandUseChange',
            'savingsFromExcess',
            'annualisedEmissions',
            'totalLCAValueincludeEmissionsFromDeliveryToAirport',
          ],
          dependencies: {
            totalLCAValueincludeEmissionsFromDeliveryToAirport: {
              $ref: '#/definitions/totalLCAValueincludeEmissionsFromDeliveryToAirport',
            },
            useDefaultValueForDeliveryToAirport: {
              $ref: '#/definitions/useDefaultValueForDeliveryToAirport',
            },
          },
        },
      },
    },
    conditionalCorsiaDefault: {
      if: {
        oneOf: [
          {
            type: 'object',
            properties: {
              certification: {
                type: 'object',
                properties: {
                  scheme: {
                    const: 'rsbCorsia',
                  },
                  type: {
                    const: 'default',
                  },
                },
              },
            },
          },
          {
            type: 'object',
            properties: {
              certification: {
                type: 'object',
                properties: {
                  scheme: {
                    const: 'isccCorsia',
                  },
                  type: {
                    const: 'default',
                  },
                },
              },
            },
          },
        ],
      },
      then: {
        $ref: '#/definitions/corsiaDefault',
      },
    },
    conditionalCorsiaActual: {
      if: {
        oneOf: [
          {
            type: 'object',
            properties: {
              certification: {
                type: 'object',
                properties: {
                  scheme: {
                    const: 'rsbCorsia',
                  },
                  type: {
                    const: 'actual',
                  },
                },
              },
            },
          },
          {
            type: 'object',
            properties: {
              certification: {
                type: 'object',
                properties: {
                  scheme: {
                    const: 'isccCorsia',
                  },
                  type: {
                    const: 'actual',
                  },
                },
              },
            },
          },
        ],
      },
      then: {
        $ref: '#/definitions/corsiaActual',
      },
    },
    conditionalEmissions: {
      if: {
        allOf: [
          {
            not: {
              properties: {
                certification: {
                  type: 'object',
                  properties: {
                    scheme: {
                      const: '',
                    },
                  },
                },
              },
            },
          },
          {
            not: {
              properties: {
                certification: {
                  type: 'object',
                  properties: {
                    type: {
                      const: '',
                    },
                  },
                },
              },
            },
          },
        ],
        type: 'object',
        properties: {
          product: {
            type: 'object',
            properties: {
              feedStock: {
                type: 'object',
                properties: {
                  isSolidWaste: {
                    const: true,
                  },
                },
              },
            },
          },
        },
      },
      then: {
        $ref: '#/definitions/emissions',
      },
    },
    conditionalEuDefault: {
      if: {
        oneOf: [
          {
            type: 'object',
            properties: {
              certification: {
                type: 'object',
                properties: {
                  scheme: {
                    const: 'rsbEuRed',
                  },
                  type: {
                    const: 'default',
                  },
                },
              },
            },
          },
          {
            type: 'object',
            properties: {
              certification: {
                type: 'object',
                properties: {
                  scheme: {
                    const: 'isccEu',
                  },
                  type: {
                    const: 'default',
                  },
                },
              },
            },
          },
          {
            type: 'object',
            properties: {
              certification: {
                type: 'object',
                properties: {
                  scheme: {
                    const: 'rsbGlobal',
                  },
                  type: {
                    const: 'default',
                  },
                },
              },
            },
          },
          {
            type: 'object',
            properties: {
              certification: {
                type: 'object',
                properties: {
                  scheme: {
                    const: 'isccPlus',
                  },
                  type: {
                    const: 'default',
                  },
                },
              },
            },
          },
        ],
      },
      then: {
        $ref: '#/definitions/euDefault',
      },
    },
    conditionalEuActual: {
      if: {
        oneOf: [
          {
            type: 'object',
            properties: {
              certification: {
                type: 'object',
                properties: {
                  scheme: {
                    const: 'rsbEuRed',
                  },
                  type: {
                    const: 'actual',
                  },
                },
              },
            },
          },
          {
            type: 'object',
            properties: {
              certification: {
                type: 'object',
                properties: {
                  scheme: {
                    const: 'isccEu',
                  },
                  type: {
                    const: 'actual',
                  },
                },
              },
            },
          },
          {
            type: 'object',
            properties: {
              certification: {
                type: 'object',
                properties: {
                  scheme: {
                    const: 'rsbGlobal',
                  },
                  type: {
                    const: 'actual',
                  },
                },
              },
            },
          },
          {
            type: 'object',
            properties: {
              certification: {
                type: 'object',
                properties: {
                  scheme: {
                    const: 'isccPlus',
                  },
                  type: {
                    const: 'actual',
                  },
                },
              },
            },
          },
        ],
      },
      then: {
        $ref: '#/definitions/euActual',
      },
    },
    country: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          minLength: 1,
        },
        code: {
          enum: [
            'AF',
            'AX',
            'AL',
            'DZ',
            'AS',
            'AD',
            'AO',
            'AI',
            'AQ',
            'AG',
            'AR',
            'AM',
            'AW',
            'AU',
            'AT',
            'AZ',
            'BH',
            'BS',
            'BD',
            'BB',
            'BY',
            'BE',
            'BZ',
            'BJ',
            'BM',
            'BT',
            'BO',
            'BQ',
            'BA',
            'BW',
            'BV',
            'BR',
            'IO',
            'BN',
            'BG',
            'BF',
            'BI',
            'KH',
            'CM',
            'CA',
            'CV',
            'KY',
            'CF',
            'TD',
            'CL',
            'CN',
            'CX',
            'CC',
            'CO',
            'KM',
            'CG',
            'CD',
            'CK',
            'CR',
            'CI',
            'HR',
            'CU',
            'CW',
            'CY',
            'CZ',
            'DK',
            'DJ',
            'DM',
            'DO',
            'EC',
            'EG',
            'SV',
            'GQ',
            'ER',
            'EE',
            'ET',
            'FK',
            'FO',
            'FJ',
            'FI',
            'FR',
            'GF',
            'PF',
            'TF',
            'GA',
            'GM',
            'GE',
            'DE',
            'GH',
            'GI',
            'GR',
            'GL',
            'GD',
            'GP',
            'GU',
            'GT',
            'GG',
            'GN',
            'GW',
            'GY',
            'HT',
            'HM',
            'VA',
            'HN',
            'HK',
            'HU',
            'IS',
            'IN',
            'ID',
            'IR',
            'IQ',
            'IE',
            'IM',
            'IL',
            'IT',
            'JM',
            'JP',
            'JE',
            'JO',
            'KZ',
            'KE',
            'KI',
            'KP',
            'KR',
            'KW',
            'KG',
            'LA',
            'LV',
            'LB',
            'LS',
            'LR',
            'LY',
            'LI',
            'LT',
            'LU',
            'MO',
            'MK',
            'MG',
            'MW',
            'MY',
            'MV',
            'ML',
            'MT',
            'MH',
            'MQ',
            'MR',
            'MU',
            'YT',
            'MX',
            'FM',
            'MD',
            'MC',
            'MN',
            'ME',
            'MS',
            'MA',
            'MZ',
            'MM',
            'NA',
            'NR',
            'NP',
            'NL',
            'NC',
            'NZ',
            'NI',
            'NE',
            'NG',
            'NU',
            'NF',
            'MP',
            'NO',
            'OM',
            'PK',
            'PW',
            'PS',
            'PA',
            'PG',
            'PY',
            'PE',
            'PH',
            'PN',
            'PL',
            'PT',
            'PR',
            'QA',
            'RE',
            'RO',
            'RU',
            'RW',
            'BL',
            'SH',
            'KN',
            'LC',
            'MF',
            'PM',
            'VC',
            'WS',
            'SM',
            'ST',
            'SA',
            'SN',
            'RS',
            'SC',
            'SL',
            'SG',
            'SX',
            'SK',
            'SI',
            'SB',
            'SO',
            'ZA',
            'GS',
            'SS',
            'ES',
            'LK',
            'SD',
            'SR',
            'SJ',
            'SZ',
            'SE',
            'CH',
            'SY',
            'TW',
            'TJ',
            'TZ',
            'TH',
            'TL',
            'TG',
            'TK',
            'TO',
            'TT',
            'TN',
            'TR',
            'TM',
            'TC',
            'TV',
            'UG',
            'UA',
            'AE',
            'GB',
            'US',
            'UM',
            'UY',
            'UZ',
            'VU',
            'VE',
            'VN',
            'VG',
            'VI',
            'WF',
            'EH',
            'YE',
            'ZM',
            'ZW',
          ],
        },
      },
      required: ['name', 'code'],
      additionalProperties: false,
    },
    address: {
      type: 'object',
      properties: {
        addressLine: {
          type: 'string',
          minLength: 1,
        },
        city: {
          type: 'string',
          minLength: 1,
        },
        state: {
          type: 'string',
          minLength: 1,
        },
        postalCode: {
          type: 'string',
          minLength: 1,
        },
        country: {
          $ref: '#/definitions/country',
        },
      },
      required: [
        'addressLine',
        'city',
        'state',
        'postalCode',
        'country',
      ],
      additionalProperties: false,
    },
    certificationScheme: {
      type: 'string',
      oneOf: [
        {
          title: 'RSB CORSIA',
          const: 'rsbCorsia',
        },
        {
          title: 'ISCC CORSIA',
          const: 'isccCorsia',
        },
        {
          title: 'RSB EU RED',
          const: 'rsbEuRed',
        },
        {
          title: 'ISCC EU',
          const: 'isccEu',
        },
        {
          title: 'RSB Global',
          const: 'rsbGlobal',
        },
        {
          title: 'ISCC PLUS',
          const: 'isccPlus',
        },
      ],
    },
    batch: {
      type: 'object',
      properties: {
        companyName: {
          type: 'string',
          minLength: 1,
        },
        leaveDate: {
          type: 'string',
          format: 'date',
          minLength: 1,
          formatMinimum: dayjs().subtract(30, 'day').toISOString().slice(0, 10),
        },
        batchNumber: {
          type: 'string',
          minLength: 1,
        },
        productionYear: {
          type: 'integer',
          maximum: new Date().getFullYear(),
        },
        upliftCountry: {
          $ref: '#/definitions/country',
        },
        upliftAirport: {
          type: 'string',
          minLength: 1,
        },
        regulatorySchemesDeclaration:
        {
          type: 'array',
          uniqueItems: true,
          default: [],
          contains: {
            type: 'string',
          },
          items: {
            type: 'string',
            enum: [
              'France Blending Mandate *',
              'Norway Blending Mandate *',
              'Sweden Blending Mandate *',
              'Refuel EU *',
              'Netherlands HBE *',
              'California LCFS',
              'Washington State Clean Fuels Standard',
              'Oregon Clean Fuels Program',
              'British Columbia LCFS',
              'US IRA tax credits',
              'US RFS RINs',
              'UK Renewable Fuels Transport Obligation (RTFO)',
              'None',
            ],
          },
        },
      },
      required: [
        'companyName',
        'leaveDate',
        'batchNumber',
        'productionYear',
        'regulatorySchemesDeclaration',
      ],
      additionalProperties: false,
    },
    blending: {
      type: 'object',
      properties: {
        isBlended: {
          const: true,
        },
      },
      required: ['isBlended'],
      dependencies: {
        isBlended: {
          oneOf: [
            {
              properties: {
                isBlended: {
                  enum: [true],
                },
                blendingDate: {
                  type: 'string',
                  format: 'date',
                  formatMaximum: new Date().toISOString().slice(0, 10),
                  minLength: 1,
                },
                blendingLocation: {
                  $ref: '#/definitions/address',
                },
              },
              required: ['blendingDate', 'blendingLocation'],
            },
            {
              properties: {
                isBlended: {
                  enum: [false],
                },
              },
            },
          ],
        },
      },
    },
    supplier: {
      type: 'object',
      properties: {
        siteName: {
          type: 'string',
          minLength: 1,
        },
        siteAddress: {
          $ref: '#/definitions/address',
        },
      },
      required: ['siteName', 'siteAddress'],
      additionalProperties: false,
    },
    certification: {
      type: 'object',
      properties: {
        scheme: {
          default: '',
          $ref: '#/definitions/certificationScheme',
        },
        type: {
          type: 'string',
          default: '',
          oneOf: [
            {
              title: 'Default',
              const: 'default',
            },
            {
              title: 'Actual',
              const: 'actual',
            },
          ],
        },
        certificateNumber: {
          type: 'string',
          minLength: 1,
        },
        certificationBody: {
          type: 'string',
          minLength: 1,
        },
        expiryDate: {
          type: 'string',
          format: 'date',
          formatMinimum: new Date().toISOString().slice(0, 10),
          minLength: 1,
        },
        certificationBodyEmail: {
          type: 'string',
          format: 'email',
          minLength: 1,
        },
        traderCertificationNumber: {
          type: 'string',
          minLength: 1,
        },
        additionalScheme: {
          type: 'string',
          default: '',
          oneOf: [
            {
              title: 'RSB CORSIA',
              const: 'rsbCorsia',
            },
            {
              title: 'ISCC CORSIA',
              const: 'isccCorsia',
            },
            {
              title: 'RSB EU RED',
              const: 'rsbEuRed',
            },
            {
              title: 'ISCC EU',
              const: 'isccEu',
            },
            {
              title: 'RSB Global',
              const: 'rsbGlobal',
            },
            {
              title: 'ISCC PLUS',
              const: 'isccPlus',
            },
            {
              title: 'Other, please specify',
              const: 'other',
            },
            {
              const: '',
            },
          ],
        },
        isLowILUCRiskConfirmed: {
          type: 'boolean',
        },
        isLowDisplacementRiskConfirmed: {
          type: 'boolean',
        },
      },
      required: [
        'scheme',
        'type',
        'certificateNumber',
        'certificationBody',
        'expiryDate',
        'certificationBodyEmail',
        'traderCertificationNumber',
        'isLowILUCRiskConfirmed',
        'isLowDisplacementRiskConfirmed',
      ],
      allOf: [
        {
          if: {
            not: {
              type: 'object',
              properties: {
                additionalScheme: {
                  const: null,
                },
              },
            },
            type: 'object',
            properties: {
              additionalScheme: {
                const: 'other',
              },
            },
          },
          then: {
            type: 'object',
            properties: {
              additionalSchemeName: {
                type: 'string',
                minLength: 1,
              },
            },
            required: ['additionalSchemeName'],
          },
        },
        {
          if: {
            not: {
              type: 'object',
              properties: {
                isLowILUCRiskConfirmed: {
                  const: null,
                },
              },
            },
            type: 'object',
            properties: {
              isLowILUCRiskConfirmed: {
                const: true,
              },
            },
          },
          then: {
            type: 'object',
            properties: {
              lowRiskAuditCertificateNumber: {
                type: 'string',
                minLength: 1,
              },
              lowRiskAuditExpiryDate: {
                type: 'string',
                format: 'date',
                formatMinimum: new Date().toISOString().slice(0, 10),
                minLength: 1,
              },
              lowRiskAuditCertificationBody: {
                type: 'string',
                minLength: 1,
              },
              lowRiskAuditCertificationBodyEmail: {
                type: 'string',
                format: 'email',
                minLength: 1,
              },
            },
            required: [
              'lowRiskAuditCertificateNumber',
              'lowRiskAuditExpiryDate',
              'lowRiskAuditCertificationBody',
              'lowRiskAuditCertificationBodyEmail',
            ],
          },
        },
        {
          if: {
            not: {
              type: 'object',
              properties: {
                isLowDisplacementRiskConfirmed: {
                  const: null,
                },
              },
            },
            type: 'object',
            properties: {
              isLowDisplacementRiskConfirmed: {
                const: true,
              },
            },
          },
          then: {
            type: 'object',
            properties: {
              lowDisplacementRiskAuditCertificateNumber: {
                type: 'string',
                minLength: 1,
              },
              lowDisplacementRiskAuditExpiryDate: {
                type: 'string',
                format: 'date',
                formatMinimum: new Date().toISOString().slice(0, 10),
                minLength: 1,
              },
              lowDisplacementRiskAuditCertificationBody: {
                type: 'string',
                minLength: 1,
              },
              lowDisplacementRiskAuditCertificationBodyEmail: {
                type: 'string',
                format: 'email',
                minLength: 1,
              },
            },
            required: [
              'lowDisplacementRiskAuditCertificateNumber',
              'lowDisplacementRiskAuditExpiryDate',
              'lowDisplacementRiskAuditCertificationBody',
              'lowDisplacementRiskAuditCertificationBodyEmail',
            ],
          },
        },
        {
          if: {
            not: {
              type: 'object',
              properties: {
                additionalScheme: {
                  const: '',
                },
              },
            },
          },
          then: {
            type: 'object',
            properties: {
              additionalCertificateNumber: {
                type: 'string',
                minLength: 1,
              },
              additionalExpiryDate: {
                type: 'string',
                format: 'date',
                formatMinimum: new Date().toISOString().slice(0, 10),
                minLength: 1,
              },
              additionalCertificationBody: {
                type: 'string',
                minLength: 1,
              },
              additionalCertificationBodyEmail: {
                type: 'string',
                format: 'email',
                minLength: 1,
              },
            },
            required: [
              'additionalCertificateNumber',
              'additionalExpiryDate',
              'additionalCertificationBody',
              'additionalCertificationBodyEmail',
            ],
          },
        },
      ],
    },
    product: {
      type: 'object',
      allOf: [
        {
          if: {
            not: {
              type: 'object',
              properties: {
                feedStock: {
                  type: 'object',
                  properties: {
                    name: {
                      const: null,
                    },
                  },
                },
              },
            },
            type: 'object',
            properties: {
              feedStock: {
                type: 'object',
                properties: {
                  isSolidWaste: {
                    const: true,
                  },
                },
              },
            },
          },
          then: {
            type: 'object',
            properties: {
              isFeedStockMsw: {
                type: 'number',
                minimum: 1,
                maximum: 100,
              },
            },
            required: ['isFeedStockMsw'],
          },
        }
      ],
      properties: {
        feedStock: {
          type: 'object',
          required: [
            'name',
            'wasCertifiedAsProductionResidue',
            'isSolidWaste',
          ],
          anyOf: [
            {
              title: ' ',
              const: null,
            },
            {
              title: 'Agricultural residues',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'agriculturalResidues',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Algae',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'algae',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Animal fats, oils and other animal processing by-products',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'animalFatsOilsAndOther',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Atmospheric CO2 from direct air capture',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'atmosphericCO2FromDirectAirCapture',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Biogenic CO2 and precursors (from fermentation and gasification)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'biogenicCO2AndPrecursors',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Biowaste',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'biowaste',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Black-liquor, brown liquor and pulp mill',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'blackBrownLiquorAndPulpMill',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Brassica carinata oil',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'brassicaCarinataOil',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Camelina oil',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'camelinaOil',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Cereals excluding maize',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'cerealsExcludingMaize',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Corn (maize) or corn grain',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'cornMaizeOrGrain',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Corn oil',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'cornOil',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Corn oil (technical)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'cornOilTechnical',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Fats, oils and grease (from wastewater)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'fatsOilsAndGrease',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Fatty acids and alcohols (from distillation and esters)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'fattyAcidsAndAlcohols',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Food wastes',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'foodWastes',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Fossil CO2 captured from industrial processes',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'fossilCO2CapturedFromIndustrialProcesses',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Geological sources of CO2 previously released naturally',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'geologicalSourcesOfCO2ReleasedNaturally',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Glycerine',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'glycerine',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Grape marcs and wine lees',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'grapeMarcsAndWineLees',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Harvesting residues (straw, husks, cobs, tops, stem, limbs, saplings and leaves)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'harvestingResidues',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Hydrogen',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'hydrogen',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Industrial waste',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'industrialWaste',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
            },
            {
              title: 'Jatropha oil',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'jatrophaOil',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Manure and/or sewage sludge',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'manureOrSewageSludge',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Miscanthus (herbaceous energy crops)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'miscanthus',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Molasses',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'molasses',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Municipal solid waste (MSW)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'municipalSolidWaste',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Non-biogenic CO2 and precursors (in waste gas)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'nonBiogenicCO2AndPrecursors',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Off-spec material from production processes that would otherwise be landfilled or incinerated',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'offSpecMaterialFromProductionProcesses',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Palm fatty acid distillate (PFAD)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'palmFattyAcidDistillate',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Palm kernel meal',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'palmKernelMeal',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Palm oil',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'palmOil',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
            },
            {
              title: 'Palm oil mill effluent',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'palmOilMillEffluent',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Poplar (short-rotation woody crops)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'poplar',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Processing residues (bagasse, nutshells, bran, gums, soap stocks, pomace and palm kernel shells)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'processingResidues',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Production residues from chemical production processes',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'processingResidues',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Rape seed',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'rapeSeed',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Rape seed oil',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'rapeSeedOil',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Recyclable materials of non-biological origin (mixed plastic waste, waste textiles, end-of-life tires)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'recyclableMaterialsOfNonBiologicalOrigin',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Recycled carbon fuels',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'recycledCarbonFuels',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
            },
            {
              title: 'Renewable sources of ETBE, MTBE, TAEE, TAME, THxEE, and/or THxME',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'renewableSources',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Sawdust, shavings, bark',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'sawdustShavingsBark',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Soybean',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'soybean',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Soybean oil',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'soybeanOil',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Spoiled or contaminated grain or other commodities',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'spoiledOrContaminatedGrain',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Straw',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'straw',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Sugarbeet',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'sugarbeet',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Sugarcane',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'sugarcane',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Sunflower',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'sunflower',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Sunflower oil',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'sunflowerOil',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Switchgrass (herbaceous energy crops)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'switchgrass',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Synthesis gas',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'synthesisGas',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Synthetic liquid fuels',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'syntheticLiquidFuels',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Synthetic liquid fuels',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'syntheticLiquidFuels',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Tall oil and tall oil pitch',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'tallOilAndTallOilPitch',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Tallow',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'tallow',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Used cooking oil and/or waste cooking oil',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'usedCookingOilOrWasteCookingOil',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Used solvents',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'usedSolvents',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Vegetable acid oils (from refining, neutralisation and other residues)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'vegetableAcidOils',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Waste and residues from agriculture, forestry, fisheries and aquaculture',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'wasteAndResiduesFromAgricultureForestryFisheriesAndAquaculture',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Waste gases',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'wasteGases',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Waste plastics (polyethylene, polypropylene)',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'wastePlastics',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Waste vegetable or animal oil',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'wasteVegetableOrAnimalOil',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Wastewater',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'wastewater',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanTrueReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Wheat',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'wheat',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Wheat straw',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'wheatStraw',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Wood',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'wood',
                },
                isSolidWaste: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
                wasCertifiedAsProductionResidue: {
                  $ref: '#/definitions/booleanFalseReadOnly',
                },
              },
              required: [
                'name',
                'wasCertifiedAsProductionResidue',
                'isSolidWaste',
              ],
            },
            {
              title: 'Other',
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                  default: 'other',
                },
                isSolidWaste: {
                  type: 'boolean',
                  default: false,
                },
                wasCertifiedAsProductionResidue: {
                  type: 'boolean',
                  default: false,
                },
                otherFeedStock: {
                  type: 'string',
                  default: '',
                },
              },
              required: ['otherFeedStock'],
            },
          ],
        },
        quantityMT: {
          type: 'number',
          minimum: 0,
        },
        countries: {
          type: 'array',
          uniqueItems: true,
          contains: {
            type: 'object',
            properties: {
              code: {
                type: 'string',
              },
              name: {
                type: 'string',
              },
            },
          },
          items: {
            $ref: '#/definitions/country',
          },
        },
        conversionProcess: {
          type: 'string',
          oneOf: [
            {
              title: 'HEFA (Hydroprocessed Esters and Fatty Esters)',
              const: 'hefa',
            },
            {
              title: 'ATJ (Alcohol/Ethanol to jet) - integrated conversion design',
              const: 'atjIntegrated',
            },
            {
              title: 'ATJ (Alcohol/Ethanol to jet) - standalone conversion design',
              const: 'atjStandalone',
            },
            {
              title: 'FT (Fischer-Tropsch)',
              const: 'ft',
            },
            {
              title: 'HEFA co-processed at petroleum refinery',
              const: 'hefaCoProcessed',
            },
            {
              title: 'SIP (synthesized iso-paraffins)',
              const: 'sip',
            },
            {
              title: 'PtL (power to liquids, including e-fuels, RFNBOs)',
              const: 'ptl',
            },
          ],
        },
        lowerHeatingValue: {
          type: 'number',
          minimum: 0,
        },
        jetFuelBaselineCarbonIntensity: {
          type: 'string',
          oneOf: [
            {
              title: '89g (CORSIA jet fuel)',
              const: '89g',
            },
            {
              title: '94g (EU RED)',
              const: '94g',
            },
            {
              title: '90g (RSB Global)',
              const: '90g',
            },
            {
              title: '95g (CORSIA aviation gasoline)',
              const: '95g',
            },
          ],
        },
      },
      required: [
        'feedStock',
        'quantityMT',
        'countries',
        'conversionProcess',
        'lowerHeatingValue',
        'jetFuelBaselineCarbonIntensity',
      ],
    },
  },
  properties: {
    type: {
      type: 'string',
      default: 'SAFcA',
      minLength: 1,
    },
    assuranceLevel: {
      type: 'string',
      default: 'VAL',
      enum: ['VAL', 'REV', 'VER', 'UR'],
      minLength: 1,
    },
    accountId: {
      type: 'string',
      format: 'uuid',
      minLength: 1,
    },
    documentationType: {
      type: 'string',
      oneOf: [
        {
          title: 'Proof of Sustainability',
          const: 'pos',
        },
        {
          title: 'Proof of Compliance',
          const: 'poc',
        },
      ],
    },
    fileTool: {
      type: 'string',
    },
    batch: {
      $ref: '#/definitions/batch',
    },
    blending: {
      $ref: '#/definitions/blending',
    },
    supplier: {
      $ref: '#/definitions/supplier',
    },
    certification: {
      $ref: '#/definitions/certification',
    },
    product: {
      $ref: '#/definitions/product',
    },
    greenhouseGas: {
      type: 'object',
    },
    adjustedLCAValue: {
      type: 'number',
    },
    sustainabilityTier: {
      type: 'string',
      enum: ['A', 'B', 'C'],
    },
  },
  allOf: [
    {
      $ref: '#/definitions/conditionalCorsiaDefault',
    },
    {
      $ref: '#/definitions/conditionalCorsiaActual',
    },
    {
      $ref: '#/definitions/conditionalEuDefault',
    },
    {
      $ref: '#/definitions/conditionalEuActual',
    },
    {
      $ref: '#/definitions/conditionalEmissions',
    },
  ],
  required: [
    'accountId',
    'fileTool',
    'batch',
    'blending',
    'supplier',
    'certification',
    'product',
    'greenhouseGas',
    'documentationType',
    'assuranceLevel',
  ],
  additionalProperties: false,
}) satisfies JSONSchema;

const issueUiSchema = {
  'ui:field': 'wizard',
  'ui:title': 'Issue SAFcA',
  'ui:style': {
    marginTop: 20,
  },
  'ui:buttonsWrapperStyle': {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 74,
    zIndex: 2,
    maxWidth: 'md',
    margin: '0 auto',
    alignItems: 'center',
  },
  'assuranceLevel': {
    'ui:widget': 'hidden',
    'ui:title': 'Assurance level',
    'ui:enableMarkdownInTitle': true,
  },
  'documentationType': {
    'ui:widget': 'radio',
    'ui:title': 'Are you submitting a Proof of Sustainability (POS) <br> or Proof of Compliance (POC)?',
    'ui:tootlipText': 'Upload either the POS or POC of the SAF batch​​',
    'ui:enableMarkdownInTitle': true,
  },
  'fileTool': {
    'ui:widget': 'fileApi',
    'ui:title': 'Supporting documentation upload',
    'ui:description': 'Upload your Proof of Sustainability or Proof of Compliance document. <br> Accepts file upload in appropriate formats PDF or CSV. Max 10MB',
    'ui:enableMarkdownInDescription': true,
    'ui:options': {
      style: {
        marginBottom: 24,
        marginTop: 10,
      },
      accept: {
        'text/csv': ['.csv'],
        'application/pdf': ['.pdf'],
      },
      maxSize: 10485760,
    },
  },
  'batch': {
    companyName: {
      'ui:title': 'Fuel producing company name',
      'ui:placeholder': 'Company name',
      'ui:tootlipText': 'Enter name of company that produced the batch of SAF​​​​​',
    },
    leaveDate: {
      'ui:title': 'Date of SAF leaving the issuer\'s <br> chain of custody',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Date',
      'ui:options': {
        minDate: dayjs().subtract(30, 'day'),
      },
    },
    batchNumber: {
      'ui:title': 'Batch Number of SAF',
      'ui:placeholder': 'Batch Number of SAF',
    },
    productionYear: {
      'ui:widget': 'date',
      'ui:title': 'Year of SAF production',
      'ui:placeholder': 'Year',
      'ui:options': {
        views: ['year'],
        disableFuture: true,
      },
    },
    upliftCountry: {
      'ui:field': 'country',
      'ui:title': 'Country of SAF uplift',
      'ui:placeholder': 'Country of SAF uplift',
      'ui:tootlipText': 'Enter country where the batch of SAF will be delivered to an airport and uplifted.​',
      'code': {
        'ui:title': 'Country of SAF uplift',
      },
    },
    upliftAirport: {
      'ui:title': 'Airport of SAF uplift',
      'ui:placeholder': 'Airport of SAF uplift',
      'ui:tootlipText': 'Enter name of airport where the batch of SAF will be uplifted from. Also, enter in parenthesis the ICAO airport code if known​ [for example, Heathrow (EGLL)]​​',
    },
    regulatorySchemesDeclaration: {
      'ui:widget': 'autocomplete',
      'ui:title': 'Regulatory schemes applicable <br> to the production of the SAF <br> (select one or more)',
      'ui:tootlipText': 'If any scheme with “*” in their name is selected, the unit will only be valid for compliance.​​',
      'ui:placeholder': 'Regulatory schemes',
      'ui:enableMarkdownInTitle': true,
      'ui:options': {
        freeSolo: true,
        multiple: true,
      },
    },
  },
  'blending': {
    isBlended: {
      'ui:widget': 'radio',
      'ui:title': 'Is the SAF blended with conventional <br> aviation fuel or certified as drop-in <br> according to ASTM Standard D7566?',
      'ui:enableMarkdownInTitle': true,
      'ui:error': {
        param: 'allowedValue',
        message: 'based on current specifications, the SAF must be blended',
      },
    },
    blendingDate: {
      'ui:title': 'Date of SAF blending with conventional jet fuel <br> or certification as drop-in?',
      'ui:placeholder': 'Date',
      'ui:tootlipText': 'Enter date batch of SAF was blended with jet fuel or certified to ASTM D7566​​​',
      'ui:enableMarkdownInTitle': true,
      'ui:options': {
        disableFuture: true,
      },
    },
    blendingLocation: {
      'ui:field': 'address',
      'ui:title': 'Location of SAF blending with conventional jet fuel <br> or certification as drop-in',
      'ui:tootlipText': 'Enter full address of the facility where the SAF batch was blended or certified to ASTM D7566​​​​',
      'ui:enableMarkdownInTitle': true,
      'city': {
        'ui:title': 'Blending location city',
        'ui:placeholder': 'City',
      },
      'state': {
        'ui:title': 'Blending location state or region',
        'ui:placeholder': 'State or region',
      },
      'country': {
        'ui:placeholder': 'Select country',
        'code': {
          'ui:title': 'Blending location country',
        },
        'ui:options': {
          label: false,
        },
      },
      'postalCode': {
        'ui:title': 'Blending location postal code',
        'ui:placeholder': 'Postal code',
      },
      'addressLine': {
        'ui:title': 'Blending location address',
        'ui:placeholder': 'Address',
      },
    },
  },
  'supplier': {
    siteName: {
      'ui:title': 'Fuel producing facility name',
      'ui:placeholder': 'Facility name',
      'ui:tootlipText': 'Enter name of facility where the SAF batch was produced​​​​​',
    },
    siteAddress: {
      'ui:field': 'address',
      'ui:title': 'Fuel producing facility address',
      'city': {
        'ui:title': 'Fuel producing facility city',
        'ui:placeholder': 'City',
      },
      'state': {
        'ui:title': 'Fuel producing facility state or region',
        'ui:placeholder': 'State or region',
      },
      'country': {
        'ui:placeholder': 'Select country',
        'code': {
          'ui:title': 'Fuel producing facility country',
        },
        'ui:options': {
          label: false,
        },
      },
      'postalCode': {
        'ui:title': 'Fuel producing facility postal code',
        'ui:placeholder': 'Postal code',
      },
      'addressLine': {
        'ui:title': 'Fuel producing facility address',
        'ui:placeholder': 'Address',
      },
    },
  },
  'certification': {
    'ui:order': [
      'scheme',
      'type',
      'certificateNumber',
      'expiryDate',
      'certificationBody',
      'certificationBodyEmail',
      'traderCertificationNumber',
      'additionalScheme',
      'additionalSchemeName',
      'additionalCertificateNumber',
      'additionalExpiryDate',
      'additionalCertificationBody',
      'additionalCertificationBodyEmail',
      'isLowILUCRiskConfirmed',
      'lowRiskAuditCertificateNumber',
      'lowRiskAuditExpiryDate',
      'lowRiskAuditCertificationBody',
      'lowRiskAuditCertificationBodyEmail',
      'isLowDisplacementRiskConfirmed',
      'lowDisplacementRiskAuditCertificateNumber',
      'lowDisplacementRiskAuditExpiryDate',
      'lowDisplacementRiskAuditCertificationBody',
      'lowDisplacementRiskAuditCertificationBodyEmail',
      '*',
    ],
    'scheme': {
      'ui:widget': 'select',
      'ui:title': 'Fuel certification scheme <br> (refer to the certification that is applicable <br> to this batch of SAF)​',
      'ui:placeholder': 'Fuel certification scheme',
      'ui:tootlipText': 'Select the primary fuel certification scheme applicable to the SAF batch. In the event that your supply chain has multiple valid certifications, enter the certification that matches the LCA methodology of the values reflected in your POS or POC documentation here.​​​​',
      'ui:enableMarkdownInTitle': true,
    },
    'type': {
      'ui:widget': 'radio',
      'ui:title': 'Actual or Default LCA?',
      'ui:tootlipText': 'Select whether default or actual LCA values are used for the SAF batch​​​​​',
    },
    'certificateNumber': {
      'ui:title': 'Certification number of the company <br> that has produced the SAF',
      'ui:placeholder': 'Certification number',
      'ui:tootlipText': 'Enter sustainability certificate number of the SAF batch. This certificate might be the same as the RSB Trader certificate if the SAF provider is also the producer.​​​​​​',
      'ui:enableMarkdownInTitle': true,
    },
    'certificationBody': {
      'ui:title': 'Name of the certification body <br> responsible for the certification of the company <br> that has produced the SAF​',
      'ui:placeholder': 'Name of the certification body',
      'ui:enableMarkdownInTitle': true,
      'ui:tootlipText': 'Enter name of entity that certified the SAF batch (e.g. SCS Global, Control Union). This certificate might be the same as the RSB Trader certificate if the SAF provider is also the producer.​​​​​',
    },
    'expiryDate': {
      'ui:title': 'Expiry date of the certification of the company <br> that has produced the SAF​',
      'ui:placeholder': 'Expiry date',
      'ui:tootlipText': 'Enter expiry date of the sustainability certificate of the SAF batch. This certificate might be the same as the RSB Trader certificate if the SAF provider is also the producer.​​​​​​',
      'ui:enableMarkdownInTitle': true,
      'ui:options': {
        disablePast: true,
      },
    },
    'certificationBodyEmail': {
      'ui:title': 'Contact email of the certification body <br> responsible for the certification of the company <br> that has produced the SAF​',
      'ui:placeholder': 'Contact email',
      'ui:tootlipText': 'Enter contact email of entity that certified the SAF batch (e.g. SCS Global, Control Union)​​​​​​',
      'ui:enableMarkdownInTitle': true,
    },
    'traderCertificationNumber': {
      'ui:title': 'RSB Trader certification number of your company​',
      'ui:placeholder': 'Trader certification number',
      'ui:enableMarkdownInTitle': true,
    },
    'additionalScheme': {
      'ui:title': 'Any additional fuel certification schemes, <br> standards and evaluation methodologies',
      'ui:description': 'Leave empty if not applicable',
      'ui:placeholder': 'Additional fuel certification scheme',
      'ui:tootlipText': 'Enter any other fuel certification schemes, standards and/or evaluation methods that apply to the SAF batch​​​​​​​',
      'ui:enableMarkdownInTitle': true,
    },
    'additionalSchemeName': {
      'ui:title': 'Additional fuel certification scheme name. <br> Please, specify:',
      'ui:placeholder': 'Certification scheme name',
      'ui:enableMarkdownInTitle': true,
    },
    'additionalCertificateNumber': {
      'ui:title': 'Certification number of the company <br> that has produced the SAF',
      'ui:placeholder': 'Certification number (additional scheme)',
      'ui:tootlipText': 'Enter sustainability certificate number of the SAF batch. This certificate might be the same as the RSB Trader certificate if the SAF provider is also the producer.​​​​​​',
      'ui:enableMarkdownInTitle': true,
    },
    'additionalExpiryDate': {
      'ui:title': 'Expiry date of the certification of the company <br> that has produced the SAF',
      'ui:placeholder': 'Expiry date (additional scheme)',
      'ui:tootlipText': 'Enter expiry date of the sustainability certificate of the SAF batch. This certificate might be the same as the RSB Trader certificate if the SAF provider is also the producer.​​​​​​',
      'ui:enableMarkdownInTitle': true,
      'ui:options': {
        disablePast: true,
      },
    },
    'additionalCertificationBody': {
      'ui:title': 'Name of the certification body <br> responsible for the certification of the company <br> that has produced the SAF​',
      'ui:placeholder': 'Name of the certification body (additional scheme)',
      'ui:tootlipText': 'Enter name of entity that certified the SAF batch (e.g. SCS Global, Control Union). This certificate might be the same as the RSB Trader certificate if the SAF provider is also the producer.​​​​​',
      'ui:enableMarkdownInTitle': true,
    },
    'additionalCertificationBodyEmail': {
      'ui:title': 'Contact email of the certification body <br> responsible for the certification of the company <br> that has produced the SAF​',
      'ui:placeholder': 'Contact email (additional scheme)',
      'ui:tootlipText': 'Enter contact email of entity that certified the SAF batch (e.g. SCS Global, Control Union)​​​​​​',
      'ui:enableMarkdownInTitle': true,
    },
    'isLowILUCRiskConfirmed': {
      'ui:widget': 'radio',
      'ui:title': 'Was low ILUC risk <br> confirmed by an audit?​',
      'ui:enableMarkdownInTitle': true,
      'ui:tootlipText': 'Select if the SAF batch was confirmed to have low ILUC risk by an audit – i.e. a low ILUC risk certification to RSB or ISCC standards.​<br>​<br> If selection is \'no\', the SAF certificate will not qualify for the SABA-preferred or SABA-eligible sustainability tiers.​​',
    },
    'lowRiskAuditCertificateNumber': {
      'ui:title': 'Certification number <br> of low-ILUC risk audit',
      'ui:placeholder': 'Certification number (low-ILUC)',
      'ui:enableMarkdownInTitle': true,
    },
    'lowRiskAuditExpiryDate': {
      'ui:title': 'Expiry date of the certification <br> of low-ILUC risk audit',
      'ui:placeholder': 'Expiry date (low-ILUC)',
      'ui:enableMarkdownInTitle': true,
      'ui:options': {
        disablePast: true,
      },
    },
    'lowRiskAuditCertificationBody': {
      'ui:title': 'Name of the certification body responsible <br> for the low-ILUC risk audit',
      'ui:placeholder': 'Name of the certification body (low-ILUC)',
      'ui:enableMarkdownInTitle': true,
    },
    'lowRiskAuditCertificationBodyEmail': {
      'ui:title': 'Contact email of certification body responsible <br> for the low-ILUC risk audit',
      'ui:placeholder': 'Contact email (low-ILUC)',
      'ui:enableMarkdownInTitle': true,
    },
    'isLowDisplacementRiskConfirmed': {
      'ui:widget': 'radio',
      'ui:title': 'Was low displacement risk <br> confirmed by an audit?',
      'ui:enableMarkdownInTitle': true,
      'ui:tootlipText': 'Select if the SAF batch was confirmed to have low displacement risk by an audit – i.e. a low displacement risk evaluation to the RSB methodology.​<br>​<br>If selection is \'no\', the SAF certificate will not qualify for the SABA-preferred sustainability tier​​',
    },
    'lowDisplacementRiskAuditCertificateNumber': {
      'ui:title': 'Certification number <br> of low-displacement risk audit',
      'ui:placeholder': 'Certification number (low-displacement)',
      'ui:enableMarkdownInTitle': true,
    },
    'lowDisplacementRiskAuditExpiryDate': {
      'ui:title': 'Expiry date of the certification <br> of low-displacement risk audit',
      'ui:placeholder': 'Expiry date (low-displacement)',
      'ui:enableMarkdownInTitle': true,
      'ui:options': {
        disablePast: true,
      },
    },
    'lowDisplacementRiskAuditCertificationBody': {
      'ui:title': 'Name of the certification body responsible <br> for the low-displacement risk audit',
      'ui:placeholder': 'Name of the certification body (low-displacement)',
      'ui:enableMarkdownInTitle': true,
    },
    'lowDisplacementRiskAuditCertificationBodyEmail': {
      'ui:title': 'Contact email of certification body <br> for the low-displacement risk audit',
      'ui:placeholder': 'Contact email (low-displacement)',
      'ui:enableMarkdownInTitle': true,
    },
  },
  'product': {
    'ui:order': ['feedStock', 'isFeedStockMsw', 'quantityMT', '*'],
    'feedStock': {
      'ui:options': {
        sanitize: false,
      },
      'ui:order': [
        'otherFeedStock',
        'wasCertifiedAsProductionResidue',
        'isSolidWaste',
        '*',
      ],
      'ui:field': 'ObjectField',
      'ui:title': 'The feedstock used to make the SAF',
      'ui:placeholder': 'The feedstock',
      'name': {
        'ui:widget': 'hidden',
      },
      'otherFeedStock': {
        'ui:title': 'Other feedstock. Please, specify:​',
        'ui:placeholder': 'Feedstock',
      },
      'wasCertifiedAsProductionResidue': {
        'ui:widget': 'radio',
        'ui:title': 'Was the feedstock certified as a waste, <br> by-product or residue?',
        'ui:enableMarkdownInTitle': true,
      },
      'isSolidWaste': {
        'ui:widget': 'radio',
        'ui:title': 'Is feedstock municipal solid waste?',
      },
    },
    'quantityMT': {
      'ui:title': 'Mass of SAF (metric tonnes) at issuance',
      'ui:description': 'Mass of SAF (in metric tonnes)',
      'ui:placeholder': 'Mass of SAF',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'MT',
        decimalPoints: 3,
      },
    },
    'isSolidWaste': {
      'ui:widget': 'radio',
      'ui:title': 'Is feedstock municipal solid waste?',
    },
    'isFeedStockMsw': {
      'ui:title': 'If municipal solid waste,<br> % of non-biological content',
      'ui:placeholder': '% of content',
      'ui:enableMarkdownInTitle': true,
      'ui:options': {
        endAdornment: '%',
      },
      'ui:inputStyle': {
        width: 200,
      },
    },
    'countries': {
      'ui:field': 'country',
      'ui:title': 'Feedstock country or countries of origin',
      'ui:placeholder': 'Country or countries of origin',
      'ui:options': {
        multiple: true,
      },
    },
    'conversionProcess': {
      'ui:title': 'SAF conversion process',
      'ui:placeholder': 'SAF conversion process',
    },
    'lowerHeatingValue': {
      'ui:title': 'SAF lower heating value',
      'ui:placeholder': 'Heating value',
      'ui:options': {
        endAdornment: 'MJ/kg',
      },
      'ui:inputStyle': {
        width: 200,
      },
    },
    'jetFuelBaselineCarbonIntensity': {
      'ui:title': 'Conventional jet fuel baseline <br> carbon intensity, well to wake',
      'ui:placeholder': 'Jet fuel baseline carbon intensity',
      'ui:enableMarkdownInTitle': true,
      'ui:tootlipText': 'Select the carbon intensity of conventional jet fuel used as baseline to calculate LCA of the SAF batch. This should be specified by the fuel certification scheme used to verify the fuel’s carbon intensity.​<br><br>• If compared to this baseline, emission reduction of \'adjusted LCA to determine SABA-preferred qualification\' is up to 60%, the SAF certificate can be designated as SABA-preferred​​<br><br>• If above test is not met, the SAF certificate can be designated as SABA-eligible provided that compared to this baseline, emission reduction of \'adjusted LCA to determine SABA-eligible qualification\' is up to 60%​​<br><br>• If above test is not met, the SAF certificate will be designated as SCS-eligible​​',
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
  },
  'greenhouseGas': {
    'ui:order': [
      'totalLCAValueCorsiaDefault',
      'totalLCAValueCorsiaActual',
      'totalLCAValueEuActual',
      'totalLCAValueEuDefault',
      'coreLCAValue',
      'inducedLandUseChange',
      'ghgDisplacementEmissions',
      'recyclingEmissionsCredits',
      'landfillEmissionsCredits',
      '*',
    ],
    'totalLCAValueCorsiaDefault': {
      'ui:title': 'a. Total SAF LCA value',
      'ui:placeholder': 'Total LCA value',
      'ui:enableMarkdownInDescription': true,
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'totalLCAValueCorsiaActual': {
      'ui:title': 'a. Total SAF LCA value',
      'ui:placeholder': 'Total LCA value',
      'ui:enableMarkdownInDescription': true,
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'totalLCAValueEuDefault': {
      'ui:title': 'a. Total SAF LCA value',
      'ui:placeholder': 'Total LCA value',
      'ui:enableMarkdownInDescription': true,
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'totalLCAValueEuActual': {
      'ui:title': 'a. Total SAF LCA value',
      'ui:placeholder': 'Total LCA value',
      'ui:enableMarkdownInDescription': true,
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'coreLCAValue': {
      'ui:title': 'b. Core LCA value',
      'ui:placeholder': 'Core LCA value',
      'ui:tootlipText': 'Enter value of emissions that are not due to ILUC or displacement effects from producing the SAF batch​​',
      'ui:enableMarkdownInDescription': true,
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'inducedLandUseChange': {
      'ui:title': 'c. Induced Land Use Change (ILUC) value',
      'ui:placeholder': 'Induced Land Use Change value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'recyclingEmissionsCredits': {
      'ui:title': 'n. Value of Recycling Emissions Credits (RECs) <br> in the total SAF LCA value',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'RECs value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'landfillEmissionsCredits': {
      'ui:title': 'o. Value of Landfill Emissions Credits (LECs) <br> in the total SAF LCA value',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'LECs value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'ghgDisplacementEmissions': {
      'ui:title': 'p. GHG Emissions from displacement',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'GHG value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'displacementEmissions': {
      'ui:title': 'p. edi = GHG Emissions from displacement',
      'ui:description': 'Leave empty or enter zero if not applicable',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Edi value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'production': {
      'ui:title': 'd. Production at source (e.g. feedstock cultivation)',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Production at source value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'conditioning': {
      'ui:title': 'e. Conditioning at source (e.g. feedstock harvesting, collection and recovery)',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Conditioning at source value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'processing': {
      'ui:title': 'f. Feedstock processing and extraction',
      'ui:placeholder': 'Feedstock processing and extraction value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'transportation': {
      'ui:title': 'g. Feedstock transportation to processing and fuel production facilities',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Transaportation value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'fuelTransportation': {
      'ui:title': 'i. Fuel transportation and distribution to the blend point',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Fuel transportation and distribution value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'conversionProcesses': {
      'ui:title': 'h. Feedstock-to-fuel conversion processes',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Feedstock-to-fuel conversion processes value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'combustion': {
      'ui:title': 'j. Fuel combustion in an aircraft engine',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Fuel combustion value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'emissionsSavingsDueToSoilCarbonSequestration': {
      'ui:title': 'q. Emissions savings due to soil carbon sequestration',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Emission value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'emissionsSavingsFromCSS': {
      'ui:title': 'r.  Emissions savings from CCS (if applicable)',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Emissions savings from CCS',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'savingsFromSoil': {
      'ui:title': 'q. esca = GHG emissions savings from soil carbon accumulation via improved agricultural mgmt',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Esca value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'extractionEmissions': {
      'ui:title': 'd. eec = emissions from extraction or cultivation <br> of raw materials',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Eec value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'annualisedEmissions': {
      'ui:title': 't. el = annualised (over 20 yrs) GHG emissions <br> from carbon stock change due to land use change',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'El value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'processingEmissions': {
      'ui:title': 'f. ep = emissions from processing',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Ep value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'transportEmissions': {
      'ui:title': 'g. etd = emissions from transport and distribution',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Etd value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'inUseFuelEmissions': {
      'ui:title': 'j. eu = emissions from the fuel in use',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Eu value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'savingsFromCarbonAndStorage': {
      'ui:title': 'r. eccs = GHG emissions savings from carbon <br> capture and geological storage',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Eccs value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'savingsFromCarbonAndReplacement': {
      'ui:title': 's. eccr = GHG Emissions savings from carbon <br> capture and replacement',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Eccr value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'savingsFromExcess': {
      'ui:title': 'u. eee = GHG emissions savings from excess <br> electricity from cogeneration',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Eee value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
    'totalLCAValueincludeEmissionsFromDeliveryToAirport': {
      'ui:widget': 'radio',
      'ui:title': 'Does the total LCA value include emissions <br> from delivery to airport?',
      'ui:enableMarkdownInTitle': true,
    },
    'useDefaultValueForDeliveryToAirport': {
      'ui:widget': 'radio',
      'ui:title': 'm. Do you want to use the default 0.9 gCO2e/MJ <br> value for delivery to airport?',
      'ui:enableMarkdownInTitle': true,
    },
    'actualGHGIntensityAirportDelivery': {
      'ui:title': 'm.  The actual GHG intensity value of airport delivery <br> (if not included in total LCA)',
      'ui:enableMarkdownInTitle': true,
      'ui:placeholder': 'Airport Delivery value',
      'ui:inputStyle': {
        width: 200,
      },
      'ui:options': {
        endAdornment: 'gCO2e/MJ',
      },
    },
  },
  'ui:options': {
    nextButtonText: 'Submit',
    fields: [
      {
        name: 'Account',
        items: [
          {
            name: 'accountId',
            $ref: '#/properties/accountId',
          },
        ],
        uiSchema: {
          'ui:field': 'component',
          'ui:title': 'Issue SAFcA for',
          'ui:style': {
            marginBottom: 30,
            marginTop: 16,
          },
          'ui:options': {
            componentName: 'Account',
            gridProps: {
              label: {
                xs: 3,
              },
              component: {
                xs: 9,
              },
            },
          },
        },
      },
    ],
    steps: [
      {
        name: 'Questions',
        fields: [
          {
            items: [
              {
                name: 'assuranceLevel',
                $ref: '#/properties/assuranceLevel',
              },
              {
                name: 'documentationType',
                $ref: '#/properties/documentationType',
              },
              {
                name: 'fileTool',
                $ref: '#/properties/fileTool',
              },
            ],
          },
          {
            items: [
              {
                name: 'documentationType',
                $ref: '#/properties/documentationType',
              },
              {
                name: 'fileTool',
                $ref: '#/properties/fileTool',
              },
              {
                name: 'batch',
                $ref: '#/properties/batch',
              },
              {
                name: 'blending',
                $ref: '#/properties/blending',
              },
              {
                name: 'supplier',
                $ref: '#/properties/supplier',
              },
              {
                name: 'certification',
                $ref: '#/properties/certification',
              },
              {
                name: 'product',
                $ref: '#/properties/product',
              },
              {
                name: 'greenhouseGas',
                $ref: '#/properties/greenhouseGas',
              },
            ],
            uiSchema: {
              'ui:field': 'tabs',
            },
          },
        ],
      },
      {
        name: 'Results',
        fields: [
          {
            items: [
              {
                name: 'results',
                type: 'null',
              },
            ],
            uiSchema: {
              'ui:field': 'component',
              'ui:options': {
                label: false,
                componentName: 'Results',
              },
            },
          },
        ],
      },
    ],
    tabs: [
      {
        name: 'Batch',
        fields: [
          {
            items: [
              {
                name: 'batch',
                $ref: '#/properties/batch',
              },
            ],
          },
        ],
      },
      {
        name: 'Blending',
        fields: [
          {
            items: [
              {
                name: 'blending',
                $ref: '#/properties/blending',
              },
            ],
          },
        ],
      },
      {
        name: 'Supplier',
        fields: [
          {
            items: [
              {
                name: 'supplier',
                $ref: '#/properties/supplier',
              },
            ],
          },
        ],
      },
      {
        name: 'Certification',
        fields: [
          {
            items: [
              {
                name: 'certification',
                $ref: '#/properties/certification',
              },
            ],
          },
        ],
      },
      {
        name: 'Product information',
        fields: [
          {
            items: [
              {
                name: 'product',
                $ref: '#/properties/product',
              },
            ],
          },
        ],
      },
      {
        name: 'Greenhouse gas information',
        tooltip: {
          handler: 'getGreenhouseTooltipText',
          data: {
            CORSIA_DEFAULT: 'How the registry calculates an adjusted LCA value for CORSIA-certified SAF using default LCA values: <br> • \'SABA preferred\' designation = b + c + m + p <br> • \'SABA eligible\' designation = b + c + m <br>​• \'SCS eligible\' designation = b + c + m - n - o​',
            CORSIA_ACTUAL: 'How the registry calculates an adjusted LCA value for CORSIA-certified SAF using actual LCA values: <br> • \'SABA preferred\' designation = c + d + e + f + g + h + i + j + m + p + q - r <br> • \'SABA eligible\' designation = c + d + e + f + g + h +i + j + m + q - r​ <br>​• \'SCS eligible\' designation = c + d + e + f + g + h + i + j + m - n - o - r​​​',
            EU_ACTUAL: 'How the registry calculates an adjusted LCA value for EU-certified SAF, ISCC PLUS-certified SAF and RSB Global-certified SAF using actual LCA values: <br> • \'SABA preferred\' designation = c + d + t + f + g + j + m + p - r - s - u <br>​• \'SABA eligible\' designation = c + d + t + f + g + j + m - r - s - u​ <br>​• \'SCS eligible\' designation = d + t + f + g + j + m - q - r - s - u​',
            EU_DEFAULT: 'How the registry calculates an adjusted LCA value for EU-certified SAF, ISCC PLUS-certified SAF and RSB Global-certified SAF using default LCA values: <br>• \'SABA preferred\' designation = a + c + m + p <br>​• \'SABA eligible\' designation = a + c + m​​ <br> • \'SCS eligible\' designation = a + m​​​​',
          },
        },
        fields: [
          {
            items: [
              {
                name: 'greenhouseGas',
                $ref: '#/properties/greenhouseGas',
              },
            ],
          },
        ],
      },
    ],
  },
};

export const Issue = {
  schema: issueSchema,
  uiSchema: issueUiSchema,
  mapping: {
    volume: 'product.quantityMT',
    accountId: 'accountId',
  },
  nonUpdatable: ['product.quantityMT', 'accountId'],
};
