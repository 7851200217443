import dayjs, { type Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { useMemo } from 'react';
import type { TextFieldProps } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDatePickerEffects } from './DatePicker.effects';
import { DatePickerInput } from '../DatePickerInput';
import { popperStyles } from './DatePicker.styles';
import { DateFormatEnum } from '@safc/ui-utils/enums';
import { Icon } from '@safc/assets/Icon';

dayjs.extend(localeData);

export interface DatePickerProps {
  onChange: (value: Dayjs) => void;
  value: Dayjs | null;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  shouldDisable?: (date: Dayjs) => boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  views?: MuiDatePickerProps<Dayjs>['views'];
  format?: string;
  componentProps?: MuiDatePickerProps<Dayjs>;
}

export class DateAdapter extends AdapterDayjs {
  getWeekdays = (): string[] => dayjs.weekdaysShort();
}

export const DatePicker = ({
  disablePast,
  disableFuture = true,
  value,
  onChange,
  shouldDisable,
  disabled,
  placeholder,
  label,
  componentProps,
  format,
  minDate,
  maxDate,
  views,
}: DatePickerProps) => {
  const { handleClose, handleOpen, open } = useDatePickerEffects();

  const textField = useMemo(
    // eslint-disable-next-line react/display-name
    () => (props: TextFieldProps) => (
      <DatePickerInput
        {...props}
        onClick={handleOpen}
        placeholder={placeholder}
        label={label}
      />
    ),
    [label, placeholder],
  );

  const openPickerIcon = () => <Icon icon="calendarSvg" />;
  const rightArrowIcon = () => <Icon icon="calendarRightArrowSvg" />;
  const leftArrowIcon = () => <Icon icon="calendarLeftArrowSvg" />;

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <MuiDatePicker
        disableFuture={disableFuture}
        disablePast={disablePast}
        minDate={minDate}
        maxDate={maxDate}
        reduceAnimations
        dayOfWeekFormatter={(day) => day}
        open={open}
        onClose={handleClose}
        disableHighlightToday={true}
        showDaysOutsideCurrentMonth={true}
        shouldDisableDate={shouldDisable}
        disabled={disabled}
        onChange={onChange as (value: Dayjs | null) => void}
        value={value instanceof Date ? dayjs(value) : (value as Dayjs | null)}
        format={format ?? DateFormatEnum.MDY}
        views={views}
        slots={{
          textField,
          openPickerIcon,
          rightArrowIcon,
          leftArrowIcon,
        }}
        slotProps={{
          openPickerButton: {
            disableRipple: true,
            style: { cursor: 'pointer' },
          },
          previousIconButton: {
            disableRipple: true,
          },
          nextIconButton: {
            disableRipple: true,
          },
          popper: {
            placement: 'bottom',
            sx: popperStyles,
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 6],
                },
              },
            ],
          },
          switchViewIcon: {
            color: 'primary',
          },
          leftArrowIcon: {
            color: 'primary',
          },
          rightArrowIcon: {
            color: 'primary',
          },
        }}
        onOpen={handleOpen}
        {...componentProps}
      />
    </LocalizationProvider>
  );
};
