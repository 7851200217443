import { useEffect } from 'react';
import { useMyUser } from './getMyUser';
import { useQueryClient } from '@tanstack/react-query';
import { useEventListener } from 'usehooks-ts';
import first from 'lodash/first';
import { useSessionStorage } from './useSessionStorage';
import type { CompanyContextProps } from './useCurrentCompany';

export const useCompanyContext = (): CompanyContextProps => {
  const { user } = useMyUser();
  const [selectedCompany, setSelectedCompany] = useSessionStorage<
    string | undefined
  >('currentCompany', undefined);
  const queryClient = useQueryClient();

  useEventListener('session-storage', () => void queryClient.resetQueries());

  useEffect(() => {
    if (!selectedCompany && !user?.isRegistryAdmin && user?.companies?.[0]?.id) {
      setSelectedCompany(user?.companies?.[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isRegistryAdmin, user?.companies?.length]);

  if (user?.isRegistryAdmin) {
    return {
      companyId: null,
      companyName: null,
      setSelectedCompany: null,
    };
  }

  const company =
    user?.companies?.find((company) => company.id === selectedCompany) ??
    first(user?.companies);

  if (!company) {
    return {
      companyId: undefined,
      companyName: undefined,
      setSelectedCompany: undefined,
    };
  }

  return {
    companyId: company.id,
    companyName: company.name,
    setSelectedCompany: (companyId) => setSelectedCompany(companyId),
  };
};
