import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const ErrorTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: 24,
  fontWeight: 800,
  lineHeight: '21px',
  fontFamily: theme.typography.body1.fontFamily,
  marginBottom: theme.spacing(2),
  textTransform: 'uppercase',
}));

export const ErrorDescription = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 400,
  lineHeight: '24px',
  color: theme.palette.text.secondary,
  fontFamily: theme.typography.body1.fontFamily,
}));


export const ErrorID = styled(Typography)(({ theme }) => ({
  fontSize: 11,
  fontWeight: 400,
  lineHeight: '24px',
  color: theme.palette.text.secondary,
  fontFamily: theme.typography.body1.fontFamily,
  marginBottom: 8,
}));
