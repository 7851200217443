import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiIconButton from '@mui/material/IconButton';
import MuiToolbar from '@mui/material/Toolbar';
import MuiMenu from '@mui/icons-material/Menu';
import { IconButton } from '@ui-components/components/icons/IconButton';

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  boxShadow: 'none',
  height: 52,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 6,
  left: '10px',
  width: 'calc(100vw - 20px)',
  top: 10,
}));

export const ToolBar = styled(MuiToolbar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: 'auto',
  boxShadow: 'none',
  borderRadius: 6,
  padding: '0px 20px !important',
  minHeight: 52,
  [theme.breakpoints.down('md')]: {
    padding: '10px !important',
  },
}));

export const LogoWrapper = styled(IconButton)({
  display: 'flex',
  padding: 0,
});

export const Menu = styled(MuiMenu)(({ theme }) => ({
  '& path': {
    fill: theme.palette.primary.main,
  },
}));

export const MenuButton = styled(MuiIconButton)(({ theme }) => ({
  height: 45,
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));
