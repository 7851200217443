// TODO: move as icon

export const WarningIcon = () => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 72 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="34.609"
        y="25.4"
        width="2.7"
        height="17.2"
        rx="1.35"
        fill="#FF782D"
        stroke="#FF782D"
        strokeWidth="0.8"
      />
      <circle
        cx="35.959"
        cy="46.75"
        r="1.35"
        fill="#FF782D"
        stroke="#FF782D"
        strokeWidth="0.8"
      />
      <ellipse
        cx="35.9997"
        cy="36.3571"
        rx="33.7907"
        ry="34.3571"
        stroke="#FF782D"
        strokeWidth="3.61538"
      />
    </svg>
  );
};
