import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '64px 64px 24px 64px',
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    padding: '8px 16px',
    flexDirection: 'column',
  },
}));

export const Text = styled(Typography)(() => ({
    color: 'black',
    fontSize: '12px',
    fontWeight: '400',
}));

export const TextBold = styled(Typography)(() => ({
    color: 'black',
    fontSize: '14px',
    fontWeight: '400',
}));

export const PolicyLinks = styled(Typography)(() => ({
    color: '#518D9B',
    fontSize: '12px',
    fontWeight: '400',
}));
