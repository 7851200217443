import {
  getUserControllerGetMyUserDataQueryKey,
  useUserControllerGetMyUserData,
} from '@safc/api-client';
import {
  AbilityContext,
  updatePermissions,
} from './permissions/usePermissions';
import { useContext } from 'react';
import { useCurrentCompany } from './useCurrentCompany';

export const useMyUser = () => {
  const ability = useContext(AbilityContext);
  const { companyId } = useCurrentCompany();

  const {
    data: user,
    isLoading,
    error,
  } = useUserControllerGetMyUserData({
    query: { queryKey: getUserControllerGetMyUserDataQueryKey() },
  });

  if (user && companyId) {
    updatePermissions(ability, user, companyId);
  }

  return {
    user,
    isLoggedIn: Boolean(user?.email),
    isLoading,
    error,
  };
};
