import { memo } from 'react';
import { Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { Input } from '../Input';
import { FormControlLabel } from '../FormControlLabel';
import type { FormInputProps } from './FormInput.types';

export const FormInput = memo(
  ({
    control,
    field,
    disabled = field.disabled ?? false,
  }: FormInputProps) => {
    return (
      <Controller
        key={`${field.name}`}
        name={field.name}
        control={control}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
          <Box {...field?.wrapperProps}>
            <FormControlLabel
              label={field?.label ?? ''}
              labelPlacement="top"
              control={
                <Input
                  fullWidth
                  key={field.name}
                  value={value}
                  margin="none"
                  type={field.type ?? 'text'}
                  inputRef={ref}
                  error={Boolean(error)}
                  helperText={error?.message}
                  required={field.required}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  data-testid={`input-${field.name}`}
                  disabled={
                    disabled || field?.inputProps?.disabled || field?.disabled
                  }
                  inputProps={{
                    ...field.inputProps,
                    placeholder: field?.placeholder ?? '',
                  }}
                  InputProps={{
                    startAdornment: field?.startAdornment && (
                      <InputAdornment position="start">
                        {field.startAdornment}
                      </InputAdornment>
                    ),
                    endAdornment: field?.endAdornment && (
                      <InputAdornment position="end">
                        {field?.endAdornment}
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
          </Box>
        )}
      />
    );
  },
);

FormInput.displayName = 'FormInput';
