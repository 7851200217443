import { IdParamsEnum } from './enums';
export const authRoutes = {
  REGISTER: '/register',
  REGISTER_CR: '/register/cr',
  REGISTER_AR: (invitationId: string | ':invitationId' = ':invitationId') =>
    `/register/authorised-representative/${invitationId}`,
  REGISTER_RA: (invitationId: string | ':invitationId' = ':invitationId') =>
    `/register/registry-admin/${invitationId}`,
  REGISTER_CR_CREATE_COMPANY: (
    invitationId: string | ':invitationId' = ':invitationId',
  ) => `/register/to-create-company/${invitationId}`,
  REGISTER_CR_JOIN_COMPANY: (
    invitationId: string | ':invitationId' = ':invitationId',
  ) => `/register/to-join-company/${invitationId}`,
  LOGIN: '/login',
  LOGIN_SUCCESS: '/login-success',
};

export const mainRoutes = {
  ACCOUNT: (
    accountId:
      | string
      | `:${IdParamsEnum.AccountId}` = `:${IdParamsEnum.AccountId}`,
  ) => `/accounts/${accountId}`,
  COLLEAGUE: (
    userId: string | `:${IdParamsEnum.UserId}` = `:${IdParamsEnum.UserId}`,
  ) => `/colleagues/${userId}`,
  COMPANY_VIEW: (
    companyId:
      | string
      | `:${IdParamsEnum.CompanyId}` = `:${IdParamsEnum.CompanyId}`,
  ) => `/companies/${companyId}`,
  USER_VIEW: (
    userId: string | `:${IdParamsEnum.UserId}` = `:${IdParamsEnum.UserId}`,
  ) => `/users/${userId}`,
  INVOICE_VIEW: (
    invoiceId: string | `:${IdParamsEnum.InvoiceId}` = `:${IdParamsEnum.InvoiceId}`,
  ) => `/invoice/${invoiceId}`,
  UNIT_VIEW: (
    unitId: string | `:${IdParamsEnum.UnitId}` = `:${IdParamsEnum.UnitId}`,
  ) => `/units/${unitId}`,
};

export const dashboardRoutes = {
  COMPANY: '/company',
  COMPANIES: '/companies',
  ACCOUNTS: '/accounts',
  ACTIONS: '/actions',
  LOGS: '/logs',
  COLLEAGUES: '/colleagues',
  HOLDINGS: '/holdings',
  BILLING: '/billing',
  PROFILE_SETTINGS: '/profile-settings',
  USERS: '/users',
  ANALYTICS: '/analytics',
};

export const publicRoutes = {
  ISSUANCE: '/issuance',
  RETIREMENT: '/retirement',
  TERMS: '/terms',
  HOME: '/',
  COLLABORATION: '/collaboration',
};

export const RoutePaths = {
  ...authRoutes,
  ...dashboardRoutes,
  ...mainRoutes,
  ...publicRoutes,
};

export const DEFAULT_LOGGED_PAGE = RoutePaths.LOGIN_SUCCESS;
export const DEFAULT_NOT_LOGGED_PAGE = RoutePaths.HOME;
