import React from 'react';
import Box, { type BoxProps } from '@mui/material/Box';
import {
  menuStyles,
  NoOptions,
  Placeholder,
  StyledCircularProgress,
  StyledMenuItem,
  StyledTextField,
} from './Select.styled';
import type { GenericFormField } from '@safc/ui-components/components/form/GenericForm';
import { FormLabel } from '@safc/ui-components/components/form/FormLabel';

export interface SelectProps {
  field: GenericFormField;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorExists?: boolean;
  errorText?: string;
  disabled?: boolean;
  wrapperProps?: BoxProps;
}

export const Select = ({
  field,
  onChange,
  errorExists = false,
  errorText = '',
  value = '',
  disabled = false,
  wrapperProps,
}: SelectProps) => {
  const options = field.options || [];

  return (
    <Box {...wrapperProps} flexShrink={0}>
      {field?.label && (
        <FormLabel label={field?.label} required={field?.required} />
      )}
      <StyledTextField
        select
        fullWidth
        name={`${field.name}`}
        error={errorExists}
        helperText={errorText}
        margin="none"
        variant="outlined"
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={field.required}
        inputProps={{
          ...field.inputProps,
          placeholder: field?.placeholder ?? '',
        }}
        InputProps={{
          endAdornment: field?.loading ? (
            <StyledCircularProgress size={18} />
          ) : null,
        }}
        SelectProps={{
          MenuProps: {
            sx: menuStyles,
          },
          renderValue: (value: any) => {
            const label = options.find((option) => option.value === value)
              ?.label;
            return value?.length === 0 ? (
              <Placeholder>
                {field?.placeholder || field.inputProps?.placeholder}
              </Placeholder>
            ) : (
              label
            );
          },
          displayEmpty: true,
        }}
      >
        {options.length === 0 ? (
          <NoOptions>No options</NoOptions>
        ) : (
          options.map((option) => (
            <StyledMenuItem key={option.label} value={option.value}>
              {option.label}
            </StyledMenuItem>
          ))
        )}
      </StyledTextField>
    </Box>
  );
};
