import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { listClasses, listItemIconClasses, paperClasses, svgIconClasses } from '@mui/material';
import { Avatar as BaseAvatar } from '@safc/ui-components/components/Avatar';

export const StyledMenuHeader = styled(Box)({
  display: 'flex',
  padding: '11px 16px',
  marginBottom: 5,
});

export const StyledMenuItem = styled(MenuItem)({
  padding: '11px 16px',
  marginBottom: 5,
  [`& .${listItemIconClasses.root}`]: {
    minWidth: 16,
  },
});

export const MenuLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
});

export const MenuItemLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '24px',
  color: theme.palette.text.secondary,
}));

export const Username = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '17px',
  color: theme.palette.text.secondary,
  maxWidth: 150,
  marginBottom: 3,
}));

export const Email = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '15px',
  color: alpha(theme.palette.common.black, 0.5),
  maxWidth: 150,
}));

export const Avatar = styled(BaseAvatar)({
  marginTop: 'auto',
  marginBottom: 'auto',
});

export const UserMenuListIcon = styled(ListItemIcon)(({ theme }) => ({
  marginRight: theme.spacing(1.25),
  color: theme.palette.primary.main,
  [`& .${svgIconClasses.root}`]: {
    fontSize: 18,
  },
}));

export const UserMenu = styled(Menu)(({ theme }) => ({
  [`& .${paperClasses.root}`]: {
    boxShadow: `1px 1px 8px ${alpha(theme.palette.common.black, 0.05)}`,
    width: 221,
    marginTop: -10,
    borderRadius: 6,
  },
  [`& .${listClasses.root}`]: {
    paddingTop: '5px',
    paddingBottom: 0,
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: '0 0 5px 0 !important',
  borderColor: theme.palette.grey[300],
}));
