import { Controller } from 'react-hook-form';
import { Autocomplete } from '../Autocomplete';
import type { FormSelectProps } from '../FormSelect/FormSelect.types';
import { Box } from '@mui/system';
import type { FormSelectOption } from '@safc/ui-components/components/form/GenericForm';

export const FormAutocomplete = ({ control, field }: FormSelectProps) => {
  return (
    <Controller
      key={`${field.name}`}
      name={field.name}
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <Box
            height={50}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <Autocomplete
              field={field}
              onChange={(_e, option) => {
                onChange((option as FormSelectOption)?.value ?? '');
              }}
              value={value}
            />
          </Box>
        );
      }}
    />
  );
};
