import MuiCheckbox from '@mui/material/Checkbox';
import {
  CheckboxCheckedIcon,
  CheckboxIcon,
  CheckboxIndeterminateIcon,
  checkboxStyles,
} from './Checkbox.styled';
import type { CheckboxProps } from './Checkbox.types';

export const Checkbox = (props: CheckboxProps) => {
  return (
    <MuiCheckbox
      sx={checkboxStyles}
      disableRipple
      checkedIcon={<CheckboxCheckedIcon color={props.color ?? 'primary'} />}
      indeterminateIcon={
        <CheckboxIndeterminateIcon color={props.color ?? 'primary'} />
      }
      icon={<CheckboxIcon />}
      {...props}
    />
  );
};
