import type { TextFieldProps as InputProps } from '@mui/material/TextField';
import { StyledInput } from './Input.styled';

export const Input = (props: InputProps) => {
  return (
    <StyledInput
      fullWidth
      variant="outlined"
      {...props}
      onWheel={(e) => (e.target as HTMLElement).blur()}
    />
  );
};
