import { useTopBarAvatarEffects } from './TopBarAvatar.effects';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import {
  Avatar,
  Email,
  MenuItemLabel,
  MenuLink,
  StyledDivider,
  StyledMenuHeader,
  StyledMenuItem,
  UserMenu,
  UserMenuListIcon,
  Username,
} from './TopBarAvatar.styles';
import { CompanyContextSelect } from 'containers/CompanyContextSelect';
import { useAppAuth } from '../../hooks/auth/useAppAuth';
import { Icon } from '@safc/assets/Icon';

interface TopBarProps {
  username: string;
  email: string;
}

export const TopBarAvatar = ({ username, email }: TopBarProps) => {
  const {
    openMenu,
    closeMenu,
    isMenuOpen,
    menuAnchorElement,
    navigateToProfile,
    // navigateToBilling,
    navigateToTermsAndConditions,
  } = useTopBarAvatarEffects();
  const { logout } = useAppAuth();

  return (
    <>
      <Box display="flex">
        <Avatar
          src={username}
          size="medium"
          onClick={openMenu}
          data-testid="user-topbar-menu"
        />
      </Box>
      <UserMenu
        id="menu-appbar"
        anchorEl={menuAnchorElement}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 165,
        }}
        TransitionComponent={Fade}
        transitionDuration={200}
        open={isMenuOpen}
        onClose={closeMenu}
      >
        <StyledMenuHeader>
          <Avatar src={username} size="medium" />
          <Box width={200} pl={1.25}>
            <Username noWrap>{username}</Username>
            <Email noWrap>{email}</Email>
          </Box>
        </StyledMenuHeader>
        <StyledMenuItem
          onClick={navigateToProfile}
          data-testid="profile-settings-button"
        >
          <UserMenuListIcon>
            <Icon icon="navigationProfileSettingsSvg" />
          </UserMenuListIcon>
          <MenuItemLabel>Profile settings</MenuItemLabel>
        </StyledMenuItem>
        <StyledMenuItem onClick={navigateToTermsAndConditions}>
          <UserMenuListIcon>
            <Icon
              sx={{ fill: 'transparent' }}
              icon="navigationTcLegalPrivacySvg"
            />
          </UserMenuListIcon>
          <MenuItemLabel>Terms of service</MenuItemLabel>
        </StyledMenuItem>
        <StyledMenuItem onClick={closeMenu}>
          <MenuLink
            href="https://docs.safcregistry.org/user-guide"
            target="_blank"
            rel="noopener noreferrer"
          >
            <UserMenuListIcon>
              <Icon icon="helpOutlineRoundedSvg" />
            </UserMenuListIcon>
            <MenuItemLabel>User guide</MenuItemLabel>
          </MenuLink>
        </StyledMenuItem>

        {/*
        * Disabled for first launch
        *
        * <Can I={Action.View} a={Entity.Billing}>
          <StyledMenuItem onClick={navigateToBilling}>
            <UserMenuListIcon>
              <Icon icon='navigationBillingSvg' />
            </UserMenuListIcon>
            <MenuItemLabel>Billing</MenuItemLabel>
          </StyledMenuItem>
        </Can> */}

        <Box p={1}>
          <CompanyContextSelect />
        </Box>

        <StyledDivider />

        <StyledMenuItem onClick={logout} data-testid="logout-button">
          <UserMenuListIcon>
            <Icon icon="navigationLogoutSvg" />
          </UserMenuListIcon>
          <MenuItemLabel>Logout</MenuItemLabel>
        </StyledMenuItem>

        <StyledDivider />

        <Typography textAlign={'right'} p={1} color="gray" fontSize={12}>
          version: {process.env.VERSION}
        </Typography>
      </UserMenu>
    </>
  );
};
