import { styled } from '@mui/material/styles';
import Box, { type BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export const Wrapper = styled(({ className, ...props }: BoxProps) => (
  <Box className={className} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 6,
  minWidth: 457,
  width: '100%',
  minHeight: 76,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  position: 'relative',
  padding: 10,
  [theme.breakpoints.down('sm')]: {
    minWidth: 250,
  },
}));

export const FilesWrapper = styled(Box)(({ theme }) => ({
  'maxHeight': 112,
  'overflowY': 'auto',
  'marginTop': 21,
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    paddingRight: '3px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[600],
    borderRadius: '10px',
  },
  '& .MuiBox-root:last-child': {
    marginBottom: 0,
  },
}));

export const UploadBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `0.6px dashed ${theme.palette.grey[600]}`,
  width: '100%',
  borderRadius: 6,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  height: 56,
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '18px',
  color: theme.palette.primary.main,
  marginLeft: 9,
  whiteSpace: 'pre-line',
}));

export const FileContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  width: '100%',
  borderRadius: 6,
  height: 32,
  padding: '6px 10px 6px 14px',
  marginBottom: 5,
}));

export const FileText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  lineHeight: '16px',
  fontFamily: theme.typography.body2.fontFamily,
}));

export const StyledIconButton = styled(IconButton)({
  'marginLeft': 'auto',
  'padding': 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
});
