import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '16px',
  fontWeight: 400,
  color: theme.palette.text.primary,
  fontFamily: theme.typography.body2.fontFamily,
  marginBottom: 4,
}));

export const FieldLabel = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '16px',
  fontWeight: 600,
  color: theme.palette.text.secondary,
  fontFamily: theme.typography.body1.fontFamily,
  paddingTop: 12,
  whiteSpace: 'pre-line',
}));

export const FieldAsterisk = styled('span')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '17px',
  color: theme.palette.error.main,
}));

export const FieldDescription = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '16px',
  fontWeight: 400,
  color: theme.palette.text.secondary,
  fontFamily: theme.typography.body1.fontFamily,
  marginTop: 7,
  whiteSpace: 'pre-line',
}));
