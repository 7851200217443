import type { DoneInvokeEvent } from 'xstate';
import { assign } from 'xstate';
import { getErrorMessage } from '@safc/ui-utils/errorMessage';

export const setErrorMessage = () => ({
  target: 'Error',
  actions: assign({
    errorMessage: (_, event: DoneInvokeEvent<any>) =>
      getErrorMessage(event.data).message,
  }),
});
