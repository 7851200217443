import { forwardRef } from 'react';
import type { ButtonProps } from '@mui/material';
import { ButtonText, StyledButton } from './Button.styled';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <StyledButton disableElevation disableRipple ref={ref} {...props}>
        <ButtonText>{children}</ButtonText>
      </StyledButton>
    );
  },
);

Button.displayName = 'Button';
