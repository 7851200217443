import { ConfirmModal } from '@safc/ui-components/components/modals/ConfirmModal';
import { useHelpdeskEffects } from 'components/helpdesk/Helpdesk.effects';
import { StyledIconButton } from 'components/helpdesk/Helpdesk.styled';
import { Icon } from '@safc/assets/Icon';

export const Helpdesk = () => {
  const {
    modalId,
    isHelpdeskModalOpen,
    onHelpdeskButtonClick,
    onCloseHelpdeskModal,
    getModalContent,
    dialogActions,
  } = useHelpdeskEffects();

  const modalContent = getModalContent();

  return (
    <>
      <StyledIconButton
        aria-label="helpdesk-button"
        onClick={onHelpdeskButtonClick}
      >
        <Icon icon="helpOutlineRoundedSvg" sx={{ height: 25, width: 25 }} />
      </StyledIconButton>
      <ConfirmModal
        id={modalId}
        open={isHelpdeskModalOpen}
        onClose={onCloseHelpdeskModal}
        Icon={modalContent.Icon}
        actions={dialogActions}
      >
        {modalContent.content}
      </ConfirmModal>
    </>
  );
};
