import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from '@tanstack/react-query';
import { useErrorStore } from 'components/layout/GlobalErrorHandler';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { AxiosError } from 'axios';

export const useQueryClient = () => {
  const errorContext = useErrorStore();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
            throwOnError: (error, query) => {
              if (
                error instanceof AxiosError &&
                query.state.errorUpdatedAt >
                  (errorContext.error?.updatedAt ?? 0)
              ) {
                errorContext.setError({
                  error,
                  updatedAt: query.state.errorUpdatedAt,
                });
              }
              return false;
            },
          },
        },
      }),
    [],
  );

  return queryClient;
};

export const QueryClientProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const queryClient = useQueryClient();

  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  );
};
