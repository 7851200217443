import { memo } from 'react';
import { Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TextArea } from '../TextArea';
import { FormControlLabel } from '../FormControlLabel';
import type { FormInputProps } from '../FormInput';

export const FormTextArea = memo(
  ({
    control,
    field,
    disabled = field.disabled ?? false,
  }: FormInputProps) => {
    return (
      <Controller
        key={`${field.name}`}
        name={field.name}
        control={control}
        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
          const fieldError = fieldState.error;
          return (
            <Box {...field?.wrapperProps}>
              <FormControlLabel
                label={field?.label ?? ''}
                labelPlacement="top"
                control={
                  <TextArea
                    key={field.name}
                    value={value}
                    inputRef={ref}
                    required={field.required}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!fieldError}
                    helperText={fieldError ? <Typography gutterBottom variant="caption">{fieldError.message}</Typography> : null}
                    disabled={
                      disabled || field?.inputProps?.disabled || field?.disabled
                    }
                    inputProps={{
                      ...field.inputProps,
                      placeholder: field?.placeholder ?? '',
                    }}
                    data-testid={`input-${field?.name}`}
                  />
                }
              />
            </Box>
          );
        }}
      />
    );
  },
);

FormTextArea.displayName = 'FormTextArea';
