import { useNotificationBellEffects } from './NotificationBell.effects';
import {
  AnimatedNotificationDot,
  NotificationButton,
  NotificationDot,
  NotificationLabel,
} from './NotificationBell.styles';
import { Icon } from '@safc/assets/Icon';

export const NotificationBell = () => {
  const {
    actionsCount,
    notificationButtonDisabled,
    navigateToActions,
    showAnimation,
  } = useNotificationBellEffects();

  const DotComponent = showAnimation
    ? AnimatedNotificationDot
    : NotificationDot;
  return (
    <NotificationButton
      onClick={navigateToActions}
      disabled={notificationButtonDisabled}
    >
      <Icon
        sx={{
          height: 24,
          rotate: notificationButtonDisabled ? undefined : '45deg',
        }}
        icon="navigationBellSvg"
      />
      {actionsCount > 0 && (
        <DotComponent>
          <NotificationLabel>
            {actionsCount < 100 ? actionsCount : '99'}
          </NotificationLabel>
        </DotComponent>
      )}
    </NotificationButton>
  );
};
