import { type FC, useEffect } from 'react';
import { useMyUser } from '@safc/ui-utils/getMyUser';
import { Backdrop } from '@safc/ui-components/components/Backdrop';
import { useNavigateToDefaultPage } from '@safc/ui-utils/useNavigateToDefaultPage';

export const LoginSuccessPage: FC = () => {
  const { user } = useMyUser();
  const { navigateToDefaultPage } = useNavigateToDefaultPage({ user });

  useEffect(() => {
    navigateToDefaultPage();
  }, [user]);

  return <Backdrop open />;
};
