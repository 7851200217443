import { alpha, styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { stepClasses, stepConnectorClasses, stepIconClasses, stepLabelClasses, svgIconClasses, typographyClasses } from '@mui/material';

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 22,
  lineHeight: '16px',
  fontWeight: 600,
  color: theme.palette.primary.dark,
  fontFamily: theme.typography.body1.fontFamily,
}));

export const FormGridContainer = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 30,
  fontSize: 12,
  lineHeight: '15px',
  fontWeight: 400,
  color: theme.palette.text.secondary,
  fontFamily: theme.typography.body1.fontFamily,
}));

export const Label = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '16px',
  fontWeight: 600,
  color: theme.palette.text.secondary,
  fontFamily: theme.typography.body1.fontFamily,
  paddingTop: 12,
  whiteSpace: 'pre-line',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    marginBottom: 0,
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '16px',
  fontWeight: 400,
  color: theme.palette.text.secondary,
  fontFamily: theme.typography.body1.fontFamily,
  marginTop: 7,
  whiteSpace: 'pre-line',
}));

export const StyledAsterisk = styled('span')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '17px',
  color: theme.palette.error.main,
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.grey[400],
}));

export const StyledStepper = styled(Stepper)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  borderRadius: 8,
  minHeight: 50,
  padding: '0 140px',
  [`& .${stepConnectorClasses.root}.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.primary.main,
  },
  [`& .${stepClasses.root}.${stepClasses.completed}`]: {
    [`& .${stepLabelClasses.label}`]: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    [`& .${stepLabelClasses.label} + .${typographyClasses.root}`]: {
      color: theme.palette.text.secondary,
    },
    [`& + .${stepConnectorClasses.root} .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepLabelClasses.label}`]: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
    fontFamily: theme.typography.body1.fontFamily,
    [`&.${stepLabelClasses.active}`]: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    [`&.${stepLabelClasses.disabled}`]: {
      color: theme.palette.text.disabled,
    },
  },
  [`& .${stepLabelClasses.active}`]: {
    color: theme.palette.common.white,
    [`& + .${typographyClasses.root}`]: {
      color: theme.palette.text.secondary,
    },
    [`& .${svgIconClasses.root}`]: {
      color: theme.palette.common.white,
    },
    [`& .${stepIconClasses.text}`]: {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
  },
  [`& .${stepClasses.root}.${stepClasses.completed}`]: {
    [`& .${stepLabelClasses.label}`]: {
      color: theme.palette.text.secondary,
    },
    [`& .${stepLabelClasses.label} + .${typographyClasses.root}`]: {
      color: theme.palette.text.secondary,
    },
    [`& + .${stepConnectorClasses.root} .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
    [`& .${svgIconClasses.root}`]: {
      color: theme.palette.success.main,
    },
  },
  [`& .${svgIconClasses.root}`]: {
    fontSize: 24,
    color: alpha(theme.palette.common.white, 0.25),
  },
  [`& .${stepIconClasses.text}`]: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
    color: theme.palette.primary.disabled,
    fill: theme.palette.primary.disabled,
    fontFamily: theme.typography.body1.fontFamily,
  },
}));
