import {
  autocompleteListBoxStyles,
  AutocompletePaper,
  StyledAutocomplete,
} from '@safc/ui-components/components/form/Autocomplete';
import type {
  FormSelectOption,
  GenericFormField,
} from '@safc/ui-components/components/form/GenericForm';
import type { HTMLAttributes, SyntheticEvent } from 'react';
import { Input } from '@safc/ui-components/components/form/Input';

interface AutocompleteProps {
  field: GenericFormField;
  value: string;
  onChange: (event: SyntheticEvent<Element, Event>, value: any) => void;
}

export const Autocomplete = ({ field, onChange, value }: AutocompleteProps) => {
  return (
    <StyledAutocomplete
      options={field?.options ?? []}
      clearIcon={null}
      value={
        (field?.options ?? []).find((option) => option.value === value) ?? ''
      }
      onChange={onChange}
      getOptionLabel={(option) => (option as FormSelectOption)?.label ?? ''}
      ListboxProps={{ sx: autocompleteListBoxStyles } as HTMLAttributes<HTMLUListElement>}
      PaperComponent={AutocompletePaper}
      renderInput={(props) => (
        <Input
          {...props}
          data-testid={`input-${field?.name}`}
          placeholder={field?.placeholder ?? ''}
          variant="outlined"
          margin="none"
          fullWidth
        />
      )}
    />
  );
};
