import type { ComponentType } from 'react';
import { lazy } from 'react';

export type ModuleImport = Promise<Record<string, ComponentType<any>>>;

export type ExportedKey = keyof Awaited<ModuleImport>;

export const asDefault =
  <T extends ModuleImport>(moduleImport: T) =>
  (key: ExportedKey) =>
    moduleImport.then((module) => ({ default: module[key] }));

export const asLazy = <T extends ModuleImport>(
  moduleImport: T,
  key: ExportedKey,
) => lazy(() => asDefault(moduleImport)(key));
