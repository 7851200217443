/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * SAFc Backend
 * The 24/7 API description
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AuthTokenCreatedResponse,
  AuthTokenResponse,
  CreateTokenRequest
} from './sAFcBackend.schemas'
import authControllerLogoutMutator from '../response-type';
import authTokenControllerListTokensMutator from '../response-type';
import authTokenControllerCreateTokenMutator from '../response-type';
import authTokenControllerRemoveTokenMutator from '../response-type';
import { customFormData } from '../../mutators/custom-file-data';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Invalidate auth token
 */
export const authControllerLogout = (
    
 options?: SecondParameter<typeof authControllerLogoutMutator>,) => {
      
      
      return authControllerLogoutMutator<void>(
      {url: `/api/auth/logout`, method: 'post'
    },
      options);
    }
  


export const getAuthControllerLogoutMutationOptions = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authControllerLogout>>, TError,TVariables, TContext>, request?: SecondParameter<typeof authControllerLogoutMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof authControllerLogout>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authControllerLogout>>, TVariables> = () => {
          

          return  authControllerLogout(requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AuthControllerLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof authControllerLogout>>>
    
    export type AuthControllerLogoutMutationError = unknown

    export const useAuthControllerLogout = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authControllerLogout>>, TError,TVariables, TContext>, request?: SecondParameter<typeof authControllerLogoutMutator>}
) => {

      const mutationOptions = getAuthControllerLogoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const authTokenControllerListTokens = (
    
 options?: SecondParameter<typeof authTokenControllerListTokensMutator>,signal?: AbortSignal
) => {
      
      
      return authTokenControllerListTokensMutator<AuthTokenResponse[]>(
      {url: `/api/auth/token`, method: 'get', signal
    },
      options);
    }
  

export const getAuthTokenControllerListTokensQueryKey = () => {
    
    return [`/api/auth/token`] as const;
    }

    
export const getAuthTokenControllerListTokensQueryOptions = <TData = Awaited<ReturnType<typeof authTokenControllerListTokens>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof authTokenControllerListTokens>>, TError, TData>, request?: SecondParameter<typeof authTokenControllerListTokensMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthTokenControllerListTokensQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authTokenControllerListTokens>>> = ({ signal }) => authTokenControllerListTokens(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authTokenControllerListTokens>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthTokenControllerListTokensQueryResult = NonNullable<Awaited<ReturnType<typeof authTokenControllerListTokens>>>
export type AuthTokenControllerListTokensQueryError = unknown

export const useAuthTokenControllerListTokens = <TData = Awaited<ReturnType<typeof authTokenControllerListTokens>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof authTokenControllerListTokens>>, TError, TData>, request?: SecondParameter<typeof authTokenControllerListTokensMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAuthTokenControllerListTokensQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create new auth token
 */
export const authTokenControllerCreateToken = (
    createTokenRequest: CreateTokenRequest,
 options?: SecondParameter<typeof authTokenControllerCreateTokenMutator>,) => {
      
      
      return authTokenControllerCreateTokenMutator<AuthTokenCreatedResponse>(
      {url: `/api/auth/token`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createTokenRequest
    },
      options);
    }
  


export const getAuthTokenControllerCreateTokenMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authTokenControllerCreateToken>>, TError,{data: CreateTokenRequest}, TContext>, request?: SecondParameter<typeof authTokenControllerCreateTokenMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof authTokenControllerCreateToken>>, TError,{data: CreateTokenRequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authTokenControllerCreateToken>>, {data: CreateTokenRequest}> = (props) => {
          const {data} = props ?? {};

          return  authTokenControllerCreateToken(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AuthTokenControllerCreateTokenMutationResult = NonNullable<Awaited<ReturnType<typeof authTokenControllerCreateToken>>>
    export type AuthTokenControllerCreateTokenMutationBody = CreateTokenRequest
    export type AuthTokenControllerCreateTokenMutationError = unknown

    export const useAuthTokenControllerCreateToken = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authTokenControllerCreateToken>>, TError,{data: CreateTokenRequest}, TContext>, request?: SecondParameter<typeof authTokenControllerCreateTokenMutator>}
) => {

      const mutationOptions = getAuthTokenControllerCreateTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete auth token
 */
export const authTokenControllerRemoveToken = (
    id: string,
 options?: SecondParameter<typeof authTokenControllerRemoveTokenMutator>,) => {
      
      
      return authTokenControllerRemoveTokenMutator<void>(
      {url: `/api/auth/token/${id}`, method: 'delete'
    },
      options);
    }
  


export const getAuthTokenControllerRemoveTokenMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authTokenControllerRemoveToken>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof authTokenControllerRemoveTokenMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof authTokenControllerRemoveToken>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authTokenControllerRemoveToken>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  authTokenControllerRemoveToken(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AuthTokenControllerRemoveTokenMutationResult = NonNullable<Awaited<ReturnType<typeof authTokenControllerRemoveToken>>>
    
    export type AuthTokenControllerRemoveTokenMutationError = unknown

    export const useAuthTokenControllerRemoveToken = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authTokenControllerRemoveToken>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof authTokenControllerRemoveTokenMutator>}
) => {

      const mutationOptions = getAuthTokenControllerRemoveTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    