import { Action, Entity, PermissionClient } from './entities';
import { buildUnitPermissions } from './common.permissions';
import { AccountApiStatus, AccountMembershipRole } from '@safc/api-client';
import partition from 'lodash/partition';

export class ArPermissions extends PermissionClient {
  grantPermissions() {
    this.buildAccountPermissions();
    this.buildCompanyPermissions();
    this.buildUnitPermissions();
  }

  buildAccountPermissions() {
    const { user } = this.permissionsContext;

    if (user.status !== 'active') {
      return;
    }

    const [activeAccounts, otherAccounts] = partition(
        user.accounts ?? [],
        (a) => a.status === AccountApiStatus.active,
    );
    const activeAccountIds = activeAccounts.map((a) => a.id);
    const inactiveAccountsIds = otherAccounts.map((a) => a.id);

    const notReadOnlyAccountIds = user.accountMemberships
      .filter(
        (membership) => membership.role !== AccountMembershipRole.readOnly,
      )
      .map((m) => m.accountId);

    this.can(Action.Trust, Entity.Account, {
      id: { $in: notReadOnlyAccountIds },
    });

    const allAccounts = [...activeAccountIds, ...inactiveAccountsIds];

    if (allAccounts.length > 0) {
      this.can(Action.View, Entity.Account, {
        id: { $in: allAccounts },
      });
    }

    const viewUnitAccounts = user.accountMemberships.map((a) => a.accountId);

    if (viewUnitAccounts.length > 0) {
      this.can(Action.ViewUnits, Entity.Account, {
        id: { $in: viewUnitAccounts },
      });
    }
  }

  buildCompanyPermissions() {
    const { user, company } = this.permissionsContext;
    const withCompanyId = { id: company?.id };
    this.can(Action.View, Entity.Company, withCompanyId);

    if (user.status !== 'active') {
      return;
    }

    this.can(Action.View, Entity.User, { id: user.id });
  }

  buildUnitPermissions() {
    buildUnitPermissions(
      { can: this.can, cannot: this.cannot },
      this.permissionsContext,
    );
  }
}
