import type { JSONSchema } from 'json-schema-to-typescript';

const onboardingSchema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  definitions: {
    country: {
      enum: [
        'US',
        'AF',
        'AX',
        'AL',
        'DZ',
        'AS',
        'AD',
        'AO',
        'AI',
        'AQ',
        'AG',
        'AR',
        'AM',
        'AW',
        'AU',
        'AT',
        'AZ',
        'BH',
        'BS',
        'BD',
        'BB',
        'BY',
        'BE',
        'BZ',
        'BJ',
        'BM',
        'BT',
        'BO',
        'BQ',
        'BA',
        'BW',
        'BV',
        'BR',
        'IO',
        'BN',
        'BG',
        'BF',
        'BI',
        'KH',
        'CM',
        'CA',
        'CV',
        'KY',
        'CF',
        'TD',
        'CL',
        'CN',
        'CX',
        'CC',
        'CO',
        'KM',
        'CG',
        'CD',
        'CK',
        'CR',
        'CI',
        'HR',
        'CU',
        'CW',
        'CY',
        'CZ',
        'DK',
        'DJ',
        'DM',
        'DO',
        'EC',
        'EG',
        'SV',
        'GQ',
        'ER',
        'EE',
        'ET',
        'FK',
        'FO',
        'FJ',
        'FI',
        'FR',
        'GF',
        'PF',
        'TF',
        'GA',
        'GM',
        'GE',
        'DE',
        'GH',
        'GI',
        'GR',
        'GL',
        'GD',
        'GP',
        'GU',
        'GT',
        'GG',
        'GN',
        'GW',
        'GY',
        'HT',
        'HM',
        'VA',
        'HN',
        'HK',
        'HU',
        'IS',
        'IN',
        'ID',
        'IR',
        'IQ',
        'IE',
        'IM',
        'IL',
        'IT',
        'JM',
        'JP',
        'JE',
        'JO',
        'KZ',
        'KE',
        'KI',
        'KP',
        'KR',
        'KW',
        'KG',
        'LA',
        'LV',
        'LB',
        'LS',
        'LR',
        'LY',
        'LI',
        'LT',
        'LU',
        'MO',
        'MK',
        'MG',
        'MW',
        'MY',
        'MV',
        'ML',
        'MT',
        'MH',
        'MQ',
        'MR',
        'MU',
        'YT',
        'MX',
        'FM',
        'MD',
        'MC',
        'MN',
        'ME',
        'MS',
        'MA',
        'MZ',
        'MM',
        'NA',
        'NR',
        'NP',
        'NL',
        'NC',
        'NZ',
        'NI',
        'NE',
        'NG',
        'NU',
        'NF',
        'MP',
        'NO',
        'OM',
        'PK',
        'PW',
        'PS',
        'PA',
        'PG',
        'PY',
        'PE',
        'PH',
        'PN',
        'PL',
        'PT',
        'PR',
        'QA',
        'RE',
        'RO',
        'RU',
        'RW',
        'BL',
        'SH',
        'KN',
        'LC',
        'MF',
        'PM',
        'VC',
        'WS',
        'SM',
        'ST',
        'SA',
        'SN',
        'RS',
        'SC',
        'SL',
        'SG',
        'SX',
        'SK',
        'SI',
        'SB',
        'SO',
        'ZA',
        'GS',
        'SS',
        'ES',
        'LK',
        'SD',
        'SR',
        'SJ',
        'SZ',
        'SE',
        'CH',
        'SY',
        'TW',
        'TJ',
        'TZ',
        'TH',
        'TL',
        'TG',
        'TK',
        'TO',
        'TT',
        'TN',
        'TR',
        'TM',
        'TC',
        'TV',
        'UG',
        'UA',
        'AE',
        'GB',
        'UM',
        'UY',
        'UZ',
        'VU',
        'VE',
        'VN',
        'VG',
        'VI',
        'WF',
        'EH',
        'YE',
        'ZM',
        'ZW',
      ],
    },
  },
  properties: {
    user: {
      type: 'object',
      properties: {
        firstName: {
          type: 'string',
          minLength: 1,
        },
        middleName: {
          type: 'string',
        },
        lastName: {
          type: 'string',
          minLength: 1,
        },
        employerName: {
          type: 'string',
          minLength: 1,
        },
        phoneNumber: {
          type: 'string',
          minLength: 1,
        },
      },
      required: ['firstName', 'lastName', 'employerName', 'phoneNumber'],
    },
    companyUploads: {
      type: 'array',
      items: {
        type: 'string',
      },
      minItems: 1,
      maxItems: 10,
    },
    company: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          minLength: 1,
        },
        address: {
          type: 'string',
          minLength: 1,
        },
        address2: {
          type: 'string',
        },
        city: {
          type: 'string',
          minLength: 1,
        },
        state: {
          type: 'string',
        },
        postcode: {
          type: 'string',
          minLength: 1,
        },
        country: {
          $ref: '#/definitions/country',
        },
        website: {
          type: 'string',
          minLength: 1
        },
        email: {
          type: 'string',
          pattern: '^\\S+@\\S+\\.\\S{2,67}',
          format: 'email',
          minLength: 6,
        },
      },
      required: [
        'name',
        'address',
        'city',
        'postcode',
        'country',
        'website',
        'email',
      ],
    },
  },
  required: ['user', 'companyUploads', 'company'],
} satisfies JSONSchema;

const onboardingUiSchema = {
  'ui:field': 'wizard',
  'ui:style': {
    marginTop: 60,
  },
  'user': {
    firstName: {
      'ui:title': 'First Name',
      'ui:placeholder': 'First Name',
      'ui:noDebounce': true,
    },
    middleName: {
      'ui:placeholder': 'Middle Name',
      'ui:noDebounce': true,
    },
    lastName: {
      'ui:title': 'Last Name',
      'ui:placeholder': 'Last Name',
      'ui:noDebounce': true,
    },
    employerName: {
      'ui:title': 'Employer name',
      'ui:placeholder': 'e.g. Acme Co.',
      'ui:noDebounce': true,
      'ui:options': {
        fieldProps: {
          labelGridProps: {
            xs: 4,
          },
          inputGridProps: {
            xs: 8,
            mb: 2,
          },
        },
      },
    },
    phoneNumber: {
      'ui:title': 'Phone number',
      'ui:placeholder': 'Phone number',
      'ui:noDebounce': true,
      'ui:options': {
        fieldProps: {
          labelGridProps: {
            xs: 4,
          },
          inputGridProps: {
            xs: 8,
          },
        },
      },
    },
  },
  'company': {
    name: {
      'ui:title': 'Company name',
      'ui:placeholder': 'e.g. Acme Co.',
      'ui:noDebounce': true,
      'ui:options': {
        fieldProps: {
          labelGridProps: {
            xs: 4,
          },
          inputGridProps: {
            xs: 8,
            mb: 2,
          },
        },
      },
    },
    city: {
      'ui:title': 'City',
      'ui:placeholder': 'City',
      'ui:noDebounce': true,
    },
    state: {
      'ui:title': 'Region or state',
      'ui:placeholder': 'Region or state',
      'ui:noDebounce': true,
    },
    postcode: {
      'ui:title': 'Postal code',
      'ui:placeholder': 'Postal code',
      'ui:noDebounce': true,
    },
    country: {
      'ui:field': 'country',
      'ui:title': 'Country',
      'ui:placeholder': 'Select Country',
      'ui:noDebounce': true,
    },
    address: {
      'ui:widget': 'textarea',
      'ui:title': 'Company address line 1',
      'ui:placeholder': 'e.g. 81 Anna Street',
      'ui:noDebounce': true,
      'ui:options': {
        fieldProps: {
          labelGridProps: {
            xs: 4,
          },
          inputGridProps: {
            xs: 8,
            mb: 2,
          },
        },
      },
    },
    address2: {
      'ui:widget': 'textarea',
      'ui:title': 'Company address line 2',
      'ui:placeholder': 'e.g. 81 Anna Street',
      'ui:noDebounce': true,
      'ui:options': {
        fieldProps: {
          labelGridProps: {
            xs: 4,
          },
          inputGridProps: {
            xs: 8,
            mb: 2,
          },
        },
      },
    },
    website: {
      'ui:title': 'Company website',
      'ui:placeholder': 'e.g. www.example.com',
      'ui:noDebounce': true,
      'ui:options': {
        fieldProps: {
          labelGridProps: {
            xs: 4,
          },
          inputGridProps: {
            xs: 8,
            mb: 2,
          },
        },
      },
    },
    email: {
      'ui:title': 'Company email address',
      'ui:placeholder': 'contact@company.com',
      'ui:noDebounce': true,
      'ui:options': {
        fieldProps: {
          labelGridProps: {
            xs: 4,
          },
          inputGridProps: {
            xs: 8,
            mb: 2,
          },
        },
      },
    },
  },
  'companyUploads': {
    'ui:widget': 'file',
    'ui:title': 'Evidence to support company <br> authorization',
    'ui:enableMarkdownInTitle': true,
    'ui:description': 'Provide a letter signed by an executive at your company authorizing you <br> to represent your company on the SAFc Registry by performing the role of <br> Company Representative <br> <br> Up to 10 images or files, Max 10MB each.<br>PNG, JPEG, or PDF are allowed. <br> <br> You can find the template to download <a href="https://docs.safcregistry.org/user-guide/i.-registration-and-login/registering-a-new-company-and-company-representative#authorization-letter" target="_blank">here</a>.',
    'ui:enableMarkdownInDescription': true,
    'ui:options': {
      filesToDisplay: 10,
      style: {
        marginBottom: 30,
        paddingBottom: 32,
      },
      accept: {
        'image/jpeg': ['.jpeg'],
        'image/png': ['.png'],
        'application/pdf': ['.pdf'],
      },
      maxSize: 10485760,
    },
  },
  'ui:options': {
    stepper: {
      variant: 'secondary',
    },
    steps: [
      {
        name: 'Personal Info',
        fields: [
          {
            title: 'Personal information',
            parent: 'user',
            items: [
              {
                name: 'firstName',
                $ref: '#/properties/user/properties/firstName',
              },
              {
                name: 'middleName',
                $ref: '#/properties/user/properties/middleName',
              },
              {
                name: 'lastName',
                $ref: '#/properties/user/properties/lastName',
              },
            ],
            uiSchema: {
              'ui:field': 'group',
              'ui:title': 'Name',
              'ui:options': {
                fieldProps: {
                  labelGridProps: {
                    xs: 4,
                  },
                  inputGridProps: {
                    mb: 2.4,
                    xs: 8,
                  },
                  inputGridItemProps: {
                    xs: 4,
                    mb: 1,
                  },
                },
              },
            },
          },
          {
            parent: 'user',
            items: [
              {
                name: 'employerName',
                $ref: '#/properties/user/properties/employerName',
              },
              {
                name: 'phoneNumber',
                $ref: '#/properties/user/properties/phoneNumber',
              },
            ],
          },
        ],
      },
      {
        name: 'Company information',
        fields: [
          {
            title: 'Company information',
            parent: 'company',
            items: [
              {
                name: 'name',
                $ref: '#/properties/company/properties/name',
              },
            ],
          },
          {
            parent: 'company',
            items: [
              {
                name: 'city',
                $ref: '#/properties/company/properties/city',
              },
              {
                name: 'state',
                $ref: '#/properties/company/properties/state',
              },
              {
                name: 'country',
                $ref: '#/properties/company/properties/country',
              },
              {
                name: 'postcode',
                $ref: '#/properties/company/properties/postcode',
              },
            ],
            uiSchema: {
              'ui:field': 'group',
              'ui:title': 'Company address',
              'ui:options': {
                fieldProps: {
                  labelGridProps: {
                    xs: 4,
                  },
                  inputGridProps: {
                    mb: 2.4,
                    xs: 8,
                  },
                  inputGridItemProps: {
                    xs: 4,
                    mb: 1,
                  },
                },
              },
            },
          },
          {
            parent: 'company',
            items: [
              {
                name: 'address',
                $ref: '#/properties/company/properties/address',
              },
              {
                name: 'address2',
                $ref: '#/properties/company/properties/address2',
              },
              {
                name: 'website',
                $ref: '#/properties/company/properties/website',
              },
              {
                name: 'email',
                $ref: '#/properties/company/properties/email',
              },
            ],
          },
        ],
      },
      {
        name: 'Upload',
        label: 'Supporting documents',
        fields: [
          {
            title: 'Upload',
            items: [
              {
                name: 'companyUploads',
                $ref: '#/properties/companyUploads',
              },
            ],
          },
        ],
      },
    ],
  },
};

export const Onboarding = {
  schema: onboardingSchema,
  uiSchema: onboardingUiSchema,
};
