import type { FC } from 'react';
import type { TMenuItem } from '../NavMenuItem';
import { SideBar } from 'components/navigation/SideBar';
import { MainNavigation } from 'components/navigation/MainNavigation';

interface NavigationBarProps {
  menuButtons: TMenuItem[];
  isNavOpen: boolean;
}

export const NavigationBar: FC<NavigationBarProps> = ({
  menuButtons,
  isNavOpen,
}) => {
  return (
    <SideBar isNavOpen={isNavOpen}>
      <MainNavigation menuButtons={menuButtons} />
    </SideBar>
  );
};

NavigationBar.displayName = 'NavigationBar';
