import Box from '@mui/material/Box';
import {
  Description,
  StyledDrawer,
  Wrapper,
} from './RegisterSidebar.styled';
import { Icon } from '@safc/assets/Icon';

export const RegisterSidebar = () => {
  return (
    <StyledDrawer open={true} anchor="left" variant="permanent">
      <Wrapper>
        <Icon icon="safIconDarkSvg" sx={{ height: 48 }} />
        <Description>
          Bringing consistency and transparency to the SAF certificate market
        </Description>
        <Box
          height="100%"
          display="flex"
          alignItems="flex-end"
          justifyContent="center"
        >
          <Icon icon="ewLogoSvg" alt="ew logo" sx={{ width: 171 }} />
        </Box>
      </Wrapper>
    </StyledDrawer>
  );
};
