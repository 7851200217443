import type { AxiosError, CanceledError } from 'axios';
import axios from 'axios';
import { authRoutes } from '@safc/ui-utils/routes';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { ClearCacheContext } from 'hooks/useClearCache';
import { useAppAuth } from '../hooks/auth/useAppAuth';
import { ErrorStatus } from '@safc/ui-utils/enums';

declare global {
  interface Window {
    config: {
      BACKEND_URL: string;
    };
  }
}

export const useInitializeApiConfig = () => {
  const { setShouldClearCache } = useContext(ClearCacheContext);
  const location = useLocation();
  const { getToken, logout, isAuthenticated } = useAppAuth();

  useEffect(() => {
    const interceptorId = axios.interceptors.request.use(async (config) => {
      const token = await getToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token.token}`;
      }

      return config;
    });

    return () => axios.interceptors.request.eject(interceptorId);
  }, [getToken]);

  useEffect(() => {
    const autoLogoutInterceptorId = axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError | CanceledError<any>) => {
        const keepDefaultBehaviour = Promise.reject(error);

        if (!axios.isAxiosError(error)) {
          return keepDefaultBehaviour;
        }

        if (error.response?.status !== ErrorStatus.Unauthorized) {
          return keepDefaultBehaviour;
        }

        const noAuthRequired = Object.values(authRoutes).includes(
          location.pathname,
        );

        if (noAuthRequired || !isAuthenticated) {
          return keepDefaultBehaviour;
        }

        void logout();
      },
    );

    return () => {
      axios.interceptors.response.eject(autoLogoutInterceptorId);
    };
  }, [isAuthenticated]);

  useEffect(() => {
    const clearCacheInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError | CanceledError<any>) => {
        const keepDefaultBehaviour = Promise.reject(error);

        if (!axios.isAxiosError(error)) {
          return keepDefaultBehaviour;
        }

        if (error.response?.status !== ErrorStatus.Forbidden) {
          return keepDefaultBehaviour;
        }

        // @TODO https://energyweb.atlassian.net/browse/SAF-1488
        setShouldClearCache(true);
      },
    );

    return () => {
      axios.interceptors.response.eject(clearCacheInterceptor);
    };
  }, []);

  useEffect(() => {
    const cloudflareTokenId = axios.interceptors.request.use(
      (config) => {
        // To properly receive 401 user when session is expired
        // https://developers.cloudflare.com/cloudflare-one/identity/users/session-management/#ajax
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.request.eject(cloudflareTokenId);
    };
  }, []);
};
