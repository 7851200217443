import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import BackgroundJpg from '@safc/assets/images/background.jpg?url';
import { Link } from 'react-router-dom';

export const Container = styled(Box)({
  backgroundColor: 'white',
  maxWidth: '1920px',
  margin: '0 auto',
});

export const TopBarSpacer = styled(Box)(({ theme }) => ({
  display: 'none',
  height: '95px',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export const HomeDrawer = styled(Drawer)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('lg')]: {
    display: 'block',
  },
}));

export const NavContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '35px',
  paddingBottom: '28px',
  paddingLeft: '70px',
  paddingRight: '70px',
  width: '100%',
  transition: 'background .2s ease',
  [theme.breakpoints.down('sm')]: {
    padding: '12px 16px',
    position: 'fixed',
    top: 0,
    left: 0,
    overflow: 'hidden',
  },
}));

export const NavButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '40px',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const NavLink = styled('a')(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '17px',
  fontWeight: 500,
  fontFamily: theme.typography.body1.fontFamily,
  color: '#1E1E1E',
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  gap: '15px',
  padding: '8px',
  paddingLeft: '64px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

export const SectionLink = styled(Typography)(() => ({
  textTransform: 'uppercase',
  fontSize: '12px',
  lineHeight: '14.52px',
  marginBottom: '12px',
  fontWeight: 800,
  color: '#1E1E1EBF',
}));

export const Masthead = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(to right, #FFFFFF 50.02%, transparent 67.24%), url(${BackgroundJpg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    backgroundPosition: 'top 100% left 65%',
  },
}));

export const SectionWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: '70px',
  paddingRight: '70px',
  backgroundColor: 'white',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const CardWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: '20px',
  },
}));

export const SectionTwo = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  width: '100%',
  padding: '64px 64px 0px 64px',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));

export const SectionFour = styled(Box)(({ theme }) => ({
  paddingLeft: '70px',
  paddingRight: '70px',
  backgroundColor: 'white',
  alignContent: 'center',
  width: '100%',
  background: 'linear-gradient(180deg, #FFF 0%, #C0D6D9 49.81%, #F1F6F7 100%)',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

export const SectionFive = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingLeft: '70px',
  paddingRight: '70px',
  backgroundImage:
    'linear-gradient(0deg, transparent 0%, #F1F6F7FF 80%), url(/bg-registry-at-a-glance.png), linear-gradient(0deg, #518D9BFF 20%, transparent 100%)',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

export const SectionSix = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingLeft: '70px',
  paddingRight: '70px',
  paddingBottom: '100px',
  backgroundImage:
    'radial-gradient(ellipse at 50% 30%, transparent, #F1F6F7FF 50%), radial-gradient(circle at 50% 20%, #518D9B99 0%, transparent 60%), url(/bg-registry-at-a-glance.png)',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

// @to do: keyframes not working
const textShine = keyframes`
    0% {
      background-position: 10% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
`;

export const HeadlineGradient = styled(Typography)(({ theme }) => ({
  fontSize: '60px',
  lineHeight: '72.61px',
  fontWeight: 700,
  background:
    'linear-gradient(90deg, #124652 28.82%, #61B1C3 68%, #000000 122.97%)',
  '-webkit-background-clip': 'text',
  'background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
  'text-fill-color': 'transparent',
  'background-size': '500% auto',
  animation: `${textShine} 8s ease-in-out infinite alternate`,
  [theme.breakpoints.down('sm')]: {
    fontSize: '44px',
    lineHeight: 'normal',
  },
}));

export const GovernanceHeaderTop = styled(Typography)(() => ({
  fontSize: '60px',
  lineHeight: '72.61px',
  fontWeight: 700,
  marginBottom: '80px',
  background:
    '-webkit-linear-gradient(89.87deg, #124652 2.29%, #124652 47.78%, #000000 122.97%)',
  '-webkit-background-clip': 'text',
  'background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
  'text-fill-color': 'transparent',
}));

export const Subheader = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  lineHeight: '42px',
  fontWeight: 400,
  color: '#1E1E1EBF',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    fontWight: 'bold',
    width: '60%',
    lineHeight: 'normal',
  },
}));

export const Subtext = styled(Typography)({
  fontSize: '18px',
  lineHeight: '28px',
  fontWeight: 400,
  color: '#1E1E1EBF',
  textAlign: 'center',
  marginTop: '32px',
});

export const SectionHeader = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  lineHeight: '38.73px',
  fontWeight: 700,
  color: '#134752',
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    lineHeight: 'normal',
  },
}));

export const GradientWrapper = styled(Box)(() => ({
  backgroundImage:
    'linear-gradient(rgba(243, 248, 248, 1) rgba(217, 217, 217, 0))',
}));

export const HeroContainer = styled(Box)(({ theme }) => ({
  padding: '0 64px',
  marginBottom: '40px',
  marginTop: '120px',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    marginTop: '140px',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    marginBottom: '140px',
  },
}));

export const LogosContainer = styled(Box)(({ theme }) => ({
  padding: '0 64px',
  marginTop: '50px',
  paddingBottom: '8px',
  [theme.breakpoints.up('lg')]: {
    marginTop: '120px',
    paddingBottom: '120px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

export const RegistryExplainerStyled = styled('picture')(({ theme }) => ({
  '& > *': {
    height: 'unset',
    width: 'calc(100% - 80px)',
    marginTop: 0,
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
      width: '100%',
    },
  },
}));

export const LogoLink = styled('a')(() => ({
  textTransform: 'unset',
  textDecoration: 'unset',
}));

export const CollaborationPaper = styled(Box)(({ theme }) => ({
  borderRadius: '12px',
  background: '#FFF',
  boxShadow: '0px 12px 40px 0px rgba(0, 0, 0, 0.12)',
  padding: '32px'
}));

export const FindOutMoreLink = styled('a')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  }
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  }
}));
