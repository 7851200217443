import type { FC } from 'react';
import { memo } from 'react';
import { List } from './MainNavigation.styles';
import type { TMenuItem } from 'components/navigation/NavMenuItem';
import { NavMenuItem } from 'components/navigation/NavMenuItem';

interface MainNavigationProps {
  menuButtons: TMenuItem[];
}

export const MainNavigation: FC<MainNavigationProps> = memo(
  ({ menuButtons }) => {
    return (
      <List>
        {menuButtons.map((item) => (
          <NavMenuItem key={item.label} {...item} />
        ))}
      </List>
    );
  },
);

MainNavigation.displayName = 'MainNavigation';
