import { Button } from '@safc/ui-components/components/buttons/Button';
import { useCookieConsentBarEffects } from './CookieConsentBar.effects';
import { CookieContainer, CookieText } from './CookieConsentBar.styles';

export const CookieConsentBar = () => {
  const [showConsent, handleAccept] = useCookieConsentBarEffects();

  return showConsent ? (
    <CookieContainer>
      <CookieText>This website uses cookies to improve your experience. We&apos;ll assume you&apos;re ok with this, but you can opt-out if you wish.</CookieText>
      <Button variant='outlined' color='info' onClick={handleAccept}>Accept</Button>
    </CookieContainer>
  ) : null;
};
