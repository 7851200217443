import { MainLayout } from 'containers/MainLayout';
import type { TMenuItem } from 'components/navigation/NavMenuItem';

export const useRoutesRootElement = () => {
  const renderRootElement = (navigationItems: TMenuItem[]) => {
    return <MainLayout navigationItems={navigationItems} />;
  };

  return renderRootElement;
};
