import { useErrorStore } from 'components/layout/GlobalErrorHandler/GlobalErrorHandler.context';

export const useGlobalErrorHandlerEffects = () => {
  const { error, parsedError, clearError } = useErrorStore();

  if (error === null) {
    return {
      parsedError: null,
      open: false,
      onClose: clearError,
    };
  }

  return {
    open: error.error !== null,
    parsedError,
    onClose: clearError,
  };
};
