import type {
  Control,
  DeepMap,
  DeepPartial,
  FieldError,
  FieldValues,
  UseFormClearErrors,
  UseFormResetField,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { FormAutocomplete } from '../FormAutocomplete';
import { FormCheckbox } from '../FormCheckbox';
import { FormCheckboxes } from '../FormCheckboxes';
import { FormDatePicker } from '../FormDatePicker';
import { FormSelect } from '../FormSelect';
import { FileUpload } from '../FileUpload';
import { RadioGroupForm } from '../RadioGroupForm';
import Grid, { type GridProps } from '@mui/material/Grid';
import type { FieldRenderer, FormItemConfiguration } from 'containers/GenericForm/GenericForm.types';
import { FormTextArea } from '@safc/ui-components/components/form/FormTextArea';
import { FormInput } from '@safc/ui-components/components/form/FormInput';

export interface SingleColumnFormProps<
  FormValues extends FieldValues = Record<string, any>,
> {
  fields: FormItemConfiguration;
  control: Control<FormValues>;
  errors: DeepMap<DeepPartial<FormValues>, FieldError>;
  clearErrors: UseFormClearErrors<FormValues>;
  setError: UseFormSetError<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  resetField: UseFormResetField<FormValues>;
  wrapperProps?: GridProps;
}

const defaultRender: FieldRenderer = ({
  config,
  control,
  clearErrors,
  errorExists,
  errorText,
  setError,
  resetField,
}) =>
  (config.select && (
    <FormSelect
      field={config}
      control={control}
      errorExists={errorExists}
      errorText={errorText}
    />
  )) ||
  (config.textarea && (
    <FormTextArea
      field={config}
      control={control}
    />
  )) ||
  (config.autocomplete && <FormAutocomplete field={config} control={control} />) ||
  (config.radio && <RadioGroupForm field={config} control={control} />) ||
  (config.datePicker && <FormDatePicker field={config} control={control} />) ||
  (config.checkbox && <FormCheckbox field={config} control={control} />) ||
  (config.checkboxes && <FormCheckboxes field={config} control={control} />) ||
  (config.fileUpload && (
    <FileUpload
      field={config}
      control={control}
      errorExists={errorExists}
      errorText={errorText}
      setError={setError}
      resetField={resetField}
      clearErrors={clearErrors}
    />
  )) || (
    <FormInput
      control={control}
      field={config}
    />
  );

export const SingleColumnForm = ({
  fields,
  control,
  setError,
  setValue,
  clearErrors,
  errors,
  resetField,
  wrapperProps,
}: SingleColumnFormProps) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={1} {...wrapperProps}>
      {fields.map((field) => {
        const { render, config } = field;

        const errorExists = Boolean(errors[config.name]);
        const errorText = errors[config.name]?.message;

        return (
          <Grid item xs={12} key={config.name} {...field?.wrapperProps}>
            {(render ?? defaultRender)({
              control,
              config,
              clearErrors,
              errorExists,
              errorText,
              resetField,
              setError,
              setValue,
            })}
          </Grid>
        );
      })}
    </Grid>
  );
};
