import styled from '@mui/material/styles/styled';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import buttonBaseClasses from '@mui/material/ButtonBase/buttonBaseClasses';
import svgIconClasses from '@mui/material/SvgIcon/svgIconClasses';
import outlinedInputClasses from '@mui/material/OutlinedInput/outlinedInputClasses';
import selectClasses from '@mui/material/Select/selectClasses';
import paperClasses from '@mui/material/Paper/paperClasses';
import listClasses from '@mui/material/List/listClasses';
import type { Theme } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';

export const StyledCircularProgress = styled(CircularProgress)({
  position: 'absolute',
  right: 45,
});

export const Placeholder = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '24px',
  fontWeight: 400,
  fontFamily: theme.typography.body2.fontFamily,
  color: theme.palette.grey[800],
  opacity: 0.4,
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '24px',
  fontWeight: 400,
  color: theme.palette.text.primary,
  fontFamily: theme.typography.body2.fontFamily,
  padding: '12px 26px',
  [`&.${buttonBaseClasses.root}`]: {
    backgroundColor: 'transparent',
  },
  [`&.${menuItemClasses.selected}`]: {
    color: theme.palette.primary.main,
    [`&.${buttonBaseClasses.root}`]: {
      backgroundColor: alpha(theme.palette.primary.dark, 0.05),
    },
    [`& .${svgIconClasses.root}`]: {
      fill: theme.palette.primary.main,
    },
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.dark, 0.08),
    color: theme.palette.primary.main,
    [`& .${svgIconClasses.root}`]: {
      fill: theme.palette.primary.main,
    },
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& .${outlinedInputClasses.root}`]: {
    margin: 0,
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    [`& .${selectClasses.select}`]: {
      padding: '11px 40px 11px 16px',
      fontSize: 14,
      lineHeight: '24px',
      minHeight: 24,
      fontWeight: 400,
      color: theme.palette.grey[800],
      fontFamily: theme.typography.body2.fontFamily,
      textTransform: 'capitalize',
      borderRadius: 10,
      [`&.${selectClasses.disabled}`]: {
        WebkitTextFillColor: theme.palette.text.disabled,
        '& ~ input': {
          color: theme.palette.grey[800],
        },
        [`& ~ .${outlinedInputClasses.notchedOutline}`]: {
          border: `1px solid ${theme.palette.grey[600]} !important`,
        },
      },
    },
    [`& .${selectClasses.icon}`]: {
      color: theme.palette.common.black,
    },
    [`&.${outlinedInputClasses.disabled}`]: {
      backgroundColor: theme.palette.grey[600],
    },
    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderWidth: 1,
      },
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: `1px solid ${theme.palette.grey[600]} !important`,
    borderRadius: 10,
    borderWidth: '1px !important',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.5),
    },
  },
}));

export const NoOptions = styled('div')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '24px',
  fontWeight: 400,
  color: theme.palette.grey[800],
  fontFamily: theme.typography.body1.fontFamily,
  padding: '12px 26px',
  cursor: 'default',
}));

export const menuStyles = (theme: Theme) => ({
  [`& .${paperClasses.root}`]: {
    boxShadow: `1px 1px 8px ${alpha(theme.palette.common.black, 0.05)}`,
    borderRadius: '6px',
    marginTop: '4px',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      boxSizing: 'border-box',
      paddingRight: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[500],
      borderRadius: '50px',
    },
  },
  [`& .${listClasses.root}`]: {
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    maxHeight: 200,
  },
});
