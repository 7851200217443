import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container, PolicyLinks, Text, TextBold } from './Footer.style';
import { forwardRef } from 'react';
import { Icon } from '@safc/assets/Icon';

export const Footer = forwardRef((props, ref) => {
  return (
    <>
      <Container>
        <Box mb={8} ref={ref}>
          <TextBold id="contact-us" mb={1} sx={{ fontWeight: '600' }}>
            Contact Us
          </TextBold>
          <Text>
            Questions about using the registry:{' '}
            <a href="mailto: helpdesk@safcregistry.org">
              helpdesk@safcregistry.org
            </a>
          </Text>
          <Text>
            To learn more about SABA and how to buy or sell SAFc:{' '}
            <a href="mailto: info@flysaba.org">info@flysaba.org</a>
          </Text>
        </Box>
        <Box>
          <Icon icon="safIconDarkSvg" sx={{ width: 78, height: 36 }} />
          <TextBold>
            Bringing consistency and transparency to the SAFc market​.
          </TextBold>
          <Box mt={2} maxWidth="550px">
            <Icon icon="poweredByEnergyWebSvg" />
            <Text>
              Designed and operated in collaboration with RMI and EDF.
            </Text>
          </Box>
        </Box>
      </Container>
      <Container pl={8} pr={8} pb={2} mt={8} flexDirection={'row'}>
        <Box
          display="flex"
          justifyContent="space-around"
          gap="35px"
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              marginTop: '16px',
            },
          })}
        >
          <Text>
            © {new Date().getFullYear()} SAFc registry, All Rights Reserved.
          </Text>
        </Box>
        <Box
          display="flex"
          justifyContent="space-around"
          gap="35px"
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              marginTop: '16px',
            },
          })}
        >
          <a href="/terms" target="_blank" rel="noopener noreferrer">
            <PolicyLinks>Terms And Conditions</PolicyLinks>
          </a>
          <a
            href="https://www.energyweb.org/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PolicyLinks>Privacy policy</PolicyLinks>
          </a>
          <a
            href="https://www.energyweb.org/cookies-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PolicyLinks>Cookie policy</PolicyLinks>
          </a>
          <Typography color="gray" fontSize={12}>
            version: {process.env.VERSION}
          </Typography>
        </Box>
      </Container>
    </>
  );
});

Footer.displayName = 'Footer';
