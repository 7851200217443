import type { FC, ReactNode } from 'react';
import {
  ButtonsWrapper,
  ContentWrapper,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
} from './Modal.styles';
import { Button } from '../../buttons/Button';
import type { DialogProps } from '@mui/material/Dialog';
import type { ButtonProps } from '@mui/material/Button';

export type ModalAction = {
  onClick: () => unknown | Promise<unknown>;
  title: string;
  buttonProps?: ButtonProps;
  disabled?: boolean;
};

export type ModalProps = DialogProps & {
  children?: ReactNode;
  actions?: ModalAction[];
};

export const Modal: FC<ModalProps> = (props) => {
  const { children, actions = [], ...dialogProps } = props;
  return (
    <StyledDialog transitionDuration={0} {...dialogProps}>
      <StyledDialogContent>
        <ContentWrapper>{children}</ContentWrapper>
      </StyledDialogContent>
      {actions.length > 0 && (
        <StyledDialogActions>
          <ButtonsWrapper data-testid="modal-actions">
            {actions?.map((action) => (
              <Button
                onClick={action.onClick}
                disabled={action.disabled}
                key={action.title}
                {...action.buttonProps}
              >
                {action.title}
              </Button>
            ))}
          </ButtonsWrapper>
        </StyledDialogActions>
      )}
    </StyledDialog>
  );
};
