import { Action, Entity, PermissionClient } from './entities';

export class RaPermissions extends PermissionClient {
  grantPermissions() {
    this.can('manage', 'all');

    this.cannot(Action.Issue, Entity.Unit);
    this.cannot(Action.Create, Entity.Account);
    this.cannot(Action.Trust, Entity.Account);
  }
}
