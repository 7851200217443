import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';
import type { TSizes, TVariants } from './InitialsIcon.types';

export const stringToInitials = (str: string) =>
  (str ? `${str.split(' ')[0][0]}${str.split(' ')?.[1]?.[0] ?? ''}` : '');

export const sizes: TSizes = {
  small: {
    size: 16,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '18px',
  },
  medium: {
    size: 32,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '16px',
  },
  large: {
    size: 64,
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '48px',
  },
};

export const getVariants = (theme: Theme, color?: string): TVariants => ({
  standard: {
    border: 'none',
    boxShadow: 'none',
  },
  filled: {
    border: 'none',
    boxShadow: 'none',
    backgroundColor: color,
    color: theme.palette.common.white,
  },
  outlined: {
    border: `1px solid ${theme.palette.common.white}`,
    boxShadow: `1px 1px 12px ${alpha(theme.palette.common.black, 0.03)}`,
  },
});
