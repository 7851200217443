import type { AxiosError } from 'axios';
import type { ErrorStatus } from '@safc/ui-utils/enums';

const isAxiosError = (error: any): error is AxiosError =>
  error?.isAxiosError;

export const isResponseError = (error: any, errorStatus: ErrorStatus) => {
  if (!isAxiosError(error)) {
    return false;
  }

  return error.response?.status === errorStatus;
};
