import type {
  CertificationSchemeType,
  ConversionProcessTypes,
  JetFuelBaselineCarbonIntensityType,
  RegulatoryScheme,
  SchemeStandard,
} from '../lib/types';
import type { GreenHouseGas } from '../types';
import { manifest } from '../manifest/manifest';

export const tier1Schemes: RegulatoryScheme[] = [
  'France Blending Mandate *',
  'Norway Blending Mandate *',
  'Sweden Blending Mandate *',
  'Refuel EU *',
  'Netherlands HBE *',
];

export const corsiaStandard: SchemeStandard[] = ['rsbCorsia', 'isccCorsia'];

export const euStandard: SchemeStandard[] = [
  'isccEu',
  'isccPlus',
  'rsbEuRed',
  'rsbGlobal',
];

export const tierASchemes: SchemeStandard[] = ['rsbGlobal', 'isccPlus'];

export const getValue = (value: number | undefined, defaultValue: number = 0) =>
  value ?? defaultValue;

export const getActualGHGIntensityAirportDelivery = (
  greenhouseGas: GreenHouseGas,
) => {
  if (greenhouseGas.totalLCAValueincludeEmissionsFromDeliveryToAirport) {
    return 0;
  }

  if (greenhouseGas.useDefaultValueForDeliveryToAirport) {
    return 0.9;
  }

  return getValue(
    'actualGHGIntensityAirportDelivery' in greenhouseGas
      ? greenhouseGas.actualGHGIntensityAirportDelivery
      : 0,
  );
};

export enum SustainabilityTierTypes {
  C = 'SCS-eligible',
  B = 'SABA-eligible',
  A = 'SABA-preferred',
}

export const UsabilityTierInfoForSAFC = {
  1: 'valid for compliance reporting',
  2: 'voluntary units, no SAFcE issued',
  3: 'voluntary units, SAFcE has been issued',
};

export const CertificationSchemeToLabel: Record<
  CertificationSchemeType,
  string
> = {
  rsbCorsia: 'RSB CORSIA',
  isccCorsia: 'ISCC CORSIA',
  rsbEuRed: 'RSB EU RED',
  isccEu: 'ISCC EU',
  rsbGlobal: 'RSB Global',
  isccPlus: 'ISCC PLUS',
};

export const JetFuelBaselineCarbonIntensityToLabel: Record<
  JetFuelBaselineCarbonIntensityType,
  string
> = {
  '89g': '89g (CORSIA jet fuel)',
  '94g': '94g (EU RED)',
  '90g': '90g (RSB Global)',
  '95g': '95g (CORSIA aviation gasoline)',
};

export const conversionProcessToLabel: Record<ConversionProcessTypes, string> =
  {
    hefa: 'HEFA (Hydroprocessed Esters and Fatty Esters)',
    atjIntegrated:
      'ATJ (Alcohol/Ethanol to jet) - integrated conversion design',
    atjStandalone:
      'ATJ (Alcohol/Ethanol to jet) - standalone conversion design',
    ft: 'FT (Fischer-Tropsch)',
    hefaCoProcessed: 'HEFA co-processed at petroleum refinery',
    sip: 'SIP (synthesized iso-paraffins)',
    ptl: 'PtL (power to liquids, including e-fuels, RFNBOs)',
  };

export const tierCFeedStock = [
  'palmOil',
  'palmFattyAcidDistillate',
  'palmFattyAcidDistillate',
  'palmOilMillEffluent',
  'palmKernelMeal',
];

export const FeedstockNameToLabel = Object.fromEntries(
  manifest.Entities.Issue.schema().definitions.product.properties.feedStock.anyOf
    .filter((feedstockDef) => feedstockDef?.properties)
    .map((feedstockDef) => [
      feedstockDef.properties!.name.default,
      feedstockDef.title,
    ]),
);
