import { useCompanyContext } from '@safc/ui-utils/useCompanyContext';
import { useMyUser } from '@safc/ui-utils/getMyUser';

export const useCompanyContextSelectEffects = () => {
  const { companyId, setSelectedCompany } = useCompanyContext();
  const { user } = useMyUser();

  return {
    selectedCompanyId: companyId,
    setCurrentCompany: setSelectedCompany,

    field: {
      name: 'companyId',
      options: user?.companies?.map((c) => ({
        value: c.id,
        label: c.name,
      })) ?? [],
    },
  };
};
