import { alpha } from '@mui/material/styles';
import type { ThemeVariables } from './types';

export const variables: ThemeVariables = {
  colors: {
    primary: {
      main: '#427783',
      dark: '#215561',
      light: '#5193A1',
      disabled: '#d3dcde',
    },
    action: {
      active: '#F28524',
    },
    success: {
      main: '#169A5E',
    },
    warning: {
      main: '#FF8D00',
      dark: '#D09F00',
    },
    error: {
      main: '#C55C3B',
      dark: '#AD1603',
    },
    info: {
      main: '#ECF0F3',
    },
    grey: {
      200: '#F5F5F5',
      300: '#EBEBEB',
      400: '#E4E4E4',
      500: '#D1D1D1',
      600: '#D8D8D8',
      700: '#8B8B8B',
      800: '#484848',
    },
    paper: '#FFFFFF',
    text: {
      primary: '#000000',
      secondary: alpha('#000000', 0.75),
      disabled: alpha('#000000', 0.3),
    },
    background: '#F5F7F9',
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    body1: {
      fontFamily: 'Inter, sans-serif',
    },
    body2: {
      fontFamily: 'Open Sans, sans-serif',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1366,
      xl: 1536,
    },
  },
};
