import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const CookieContainer = styled(Box)(({ theme }) => ({
  width: '100vw',
  position: 'fixed',
  bottom: 0,
  left: 0,
  backgroundColor: theme.palette.primary.main,
  height: '60px',
  zIndex: 10000,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down(1120)]: {
    height: '180px',
    flexDirection: 'column',
  },
}));

export const CookieText = styled(Typography)(({ theme }) => ({
  color: 'white',
  marginRight: theme.spacing(2),
  [theme.breakpoints.down(1120)]: {
    textAlign: 'center',
    marginRight: 0,
    marginBottom: theme.spacing(2),
    padding: '0 16px',
  },
}));
