import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import type { FileRejection } from 'react-dropzone';
import type { FileUploadProps } from './FileUpload.types';

export const useFileUploadEffects = ({
  setError,
  control,
  clearErrors,
  errorExists,
  field,
}: FileUploadProps) => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const {
    field: { value },
  } = useController({ control, name: field.name });

  const serErrorMessage = (message: string) =>
    setError(field.name, { message });

  const onDropRejected = (fileRejection: FileRejection[]) => {
    setRejectedFiles(fileRejection);
    serErrorMessage('an error occurred');
  };

  const removeRejectedFile = (index: number) => {
    const newFiles = [...rejectedFiles];
    newFiles.splice(index, 1);
    setRejectedFiles(newFiles);
  };

  useEffect(() => {
    if (rejectedFiles.length === 0 && errorExists && value.length > 0) {
      clearErrors(field.name);
    }
  }, [errorExists, rejectedFiles, value]);

  return {
    onDropRejected,
    rejectedFiles,
    removeRejectedFile,
  };
};
