import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@safc/ui-components/components/buttons/Button';
import { Paper, Wrapper } from './ErrorFallback.styled';

interface ErrorFallbackProps {
  error: Error;
}

export const ErrorFallback = ({ error }: ErrorFallbackProps) => {
  const reloadHandler = () => window.location.reload();
  const returnHomeHandler = () => window.location.replace('/');

  return (
    <Wrapper>
      <Paper>
        <Typography color="error" variant="h5" align="center" gutterBottom>
          Something Went Wrong:
        </Typography>
        <Box mt={2}>
          <Typography color="textSecondary" align="center">
            {error?.message}
          </Typography>
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          <Button disableElevation onClick={reloadHandler}>
            Reload page
          </Button>
          <Box ml={2}>
            <Button disableElevation onClick={returnHomeHandler}>
              Return to Home page
            </Button>
          </Box>
        </Box>
      </Paper>
    </Wrapper>
  );
};
