import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import type { Control, FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { StyledFormLabel } from './RadioGroupForm.styles';
import {
  Radio,
  RadioFormControlLabel,
} from '@safc/ui-components/components/form/Radio';
import type { GenericFormField } from '@safc/ui-components/components/form/GenericForm';

interface RadioGroupFormProps<FormValues extends FieldValues = any> {
  field: GenericFormField;
  control: Control<FormValues>;
}

export const RadioGroupForm = ({ control, field }: RadioGroupFormProps) => {
  return (
    <Box {...field?.wrapperProps}>
      <FormControl component="fieldset">
        <Controller
          control={control}
          key={`${field.name}`}
          name={field.name}
          render={({ field: props }) => (
            <>
              {field?.label && (
                <StyledFormLabel required={field?.required}>{field?.label}</StyledFormLabel>
              )}
              <RadioGroup row {...props} style={field.inputProps}>
                {field.options?.map((option) => {
                  return (
                    <RadioFormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  );
                })}
              </RadioGroup>
            </>
          )}
        />
      </FormControl>
    </Box>
  );
};
