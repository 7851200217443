import { StrictMode, Suspense, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { ThemeProvider } from './theme/ThemeProvider';
import { initializeDateLibrary } from './dates/initializeDateLibrary';
import * as Sentry from '@sentry/react';
import { App } from './app/app';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AuthProvider } from '@safc/ui-utils/auth/AuthProvider';
import { DateAdapter } from '@safc/ui-components/components/form/DatePicker';
import { ErrorFallback } from 'components/common/ErrorFallback';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const sentryConfig = {
  dsn: process.env.FRONTEND_SENTRY_DSN,
  env: process.env.SENTRY_ENV,
};

Sentry.init({
  dsn: sentryConfig.dsn,
  tunnel: '/api/bugs',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [window.location.origin],
      maskAllInputs: false,
      maskAllText: false,
    }),
  ],
  environment: sentryConfig.env,
  enabled: Boolean(sentryConfig.dsn),
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0,
});

initializeDateLibrary();

root.render(
  <StrictMode>
    <ThemeProvider>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <AuthProvider>
                <Suspense>
                  <App />
                </Suspense>
              </AuthProvider>
            </LocalizationProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  </StrictMode>,
);
