import Box from '@mui/material/Box';
import type { InputLabelProps } from '@mui/material/InputLabel';
import { StyledInputLabel } from './FormLabel.styled';

export interface FormLabelProps extends InputLabelProps {
  label?: string;
  required?: boolean;
}

export const FormLabel = ({ htmlFor, label, required }: FormLabelProps) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <StyledInputLabel htmlFor={htmlFor}>
        {label ?? ''}
        {required ? '*' : ''}
      </StyledInputLabel>
    </Box>
  );
};
