/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * SAFc Backend
 * The 24/7 API description
 * OpenAPI spec version: 1.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ActionsControllerGetPendingActionsCountParams,
  ActionsControllerGetPendingActionsParams,
  GetPendingActionsCountResponse,
  GetPendingActionsResponse
} from './sAFcBackend.schemas'
import actionsControllerGetPendingActionsCountMutator from '../response-type';
import actionsControllerGetPendingActionsMutator from '../response-type';
import { customFormData } from '../../mutators/custom-file-data';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


export const actionsControllerGetPendingActionsCount = (
    params?: ActionsControllerGetPendingActionsCountParams,
 options?: SecondParameter<typeof actionsControllerGetPendingActionsCountMutator>,signal?: AbortSignal
) => {
      
      
      return actionsControllerGetPendingActionsCountMutator<GetPendingActionsCountResponse>(
      {url: `/api/actions/pending-count`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getActionsControllerGetPendingActionsCountQueryKey = (params?: ActionsControllerGetPendingActionsCountParams,) => {
    
    return [`/api/actions/pending-count`, ...(params ? [params]: [])] as const;
    }

    
export const getActionsControllerGetPendingActionsCountQueryOptions = <TData = Awaited<ReturnType<typeof actionsControllerGetPendingActionsCount>>, TError = unknown>(params?: ActionsControllerGetPendingActionsCountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof actionsControllerGetPendingActionsCount>>, TError, TData>, request?: SecondParameter<typeof actionsControllerGetPendingActionsCountMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getActionsControllerGetPendingActionsCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof actionsControllerGetPendingActionsCount>>> = ({ signal }) => actionsControllerGetPendingActionsCount(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof actionsControllerGetPendingActionsCount>>, TError, TData> & { queryKey: QueryKey }
}

export type ActionsControllerGetPendingActionsCountQueryResult = NonNullable<Awaited<ReturnType<typeof actionsControllerGetPendingActionsCount>>>
export type ActionsControllerGetPendingActionsCountQueryError = unknown

export const useActionsControllerGetPendingActionsCount = <TData = Awaited<ReturnType<typeof actionsControllerGetPendingActionsCount>>, TError = unknown>(
 params?: ActionsControllerGetPendingActionsCountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof actionsControllerGetPendingActionsCount>>, TError, TData>, request?: SecondParameter<typeof actionsControllerGetPendingActionsCountMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getActionsControllerGetPendingActionsCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const actionsControllerGetPendingActions = (
    params?: ActionsControllerGetPendingActionsParams,
 options?: SecondParameter<typeof actionsControllerGetPendingActionsMutator>,signal?: AbortSignal
) => {
      
      
      return actionsControllerGetPendingActionsMutator<GetPendingActionsResponse>(
      {url: `/api/actions/pending`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getActionsControllerGetPendingActionsQueryKey = (params?: ActionsControllerGetPendingActionsParams,) => {
    
    return [`/api/actions/pending`, ...(params ? [params]: [])] as const;
    }

    
export const getActionsControllerGetPendingActionsQueryOptions = <TData = Awaited<ReturnType<typeof actionsControllerGetPendingActions>>, TError = unknown>(params?: ActionsControllerGetPendingActionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof actionsControllerGetPendingActions>>, TError, TData>, request?: SecondParameter<typeof actionsControllerGetPendingActionsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getActionsControllerGetPendingActionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof actionsControllerGetPendingActions>>> = ({ signal }) => actionsControllerGetPendingActions(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof actionsControllerGetPendingActions>>, TError, TData> & { queryKey: QueryKey }
}

export type ActionsControllerGetPendingActionsQueryResult = NonNullable<Awaited<ReturnType<typeof actionsControllerGetPendingActions>>>
export type ActionsControllerGetPendingActionsQueryError = unknown

export const useActionsControllerGetPendingActions = <TData = Awaited<ReturnType<typeof actionsControllerGetPendingActions>>, TError = unknown>(
 params?: ActionsControllerGetPendingActionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof actionsControllerGetPendingActions>>, TError, TData>, request?: SecondParameter<typeof actionsControllerGetPendingActionsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getActionsControllerGetPendingActionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



