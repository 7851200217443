import GlobalStyles from '@mui/material/GlobalStyles/GlobalStyles';
import { LoginPage } from './LoginPage';
import { useTheme } from '@mui/material/styles';

const LoginPageWrapper = () => {
  const theme = useTheme();

  return (
    <>
      <GlobalStyles
        styles={{
          '& html body': {
            backgroundColor: theme.palette.background.paper,
          },
        }}
      />
      <LoginPage />
    </>
  );
};

export default LoginPageWrapper;
