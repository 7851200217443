import { useTheme } from '@mui/material/styles';
import type { DrawerProps } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import type { SideBarProps } from './SideBar.types';

export const useSideBarEffects = ({ isNavOpen }: Pick<SideBarProps, 'isNavOpen'>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const drawerOpen = isMobile ? isNavOpen : true;
  const drawerVariant: DrawerProps['variant'] = isMobile
    ? 'persistent'
    : 'permanent';

  return {
    drawerOpen,
    drawerVariant,
  };
};
