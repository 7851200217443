import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const StyledIconButton = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: '25px',
  height: '25px',
});
