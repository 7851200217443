import type { AbilityBuilder } from '@casl/ability';
import type { PermissionsContext } from './permission.types';

export enum Action {
  Create = 'Create',
  Block = 'Block',
  Unblock = 'Unblock',
  Edit = 'Edit',
  Delete = 'Delete',
  Close = 'Close',
  Remove = 'Remove',
  View = 'View',
  Invite = 'Invite',
  Manage = 'Manage',
  Issue = 'Issue',
  Sign = 'Sign',
  Transfer = 'Transfer',
  Split = 'Split',
  Retire = 'Retire',
  Trust = 'Trust',
  ViewUnits = 'ViewUnits',
}

export enum Entity {
  User = 'User',
  RegistryAdminUser = 'RegistryAdminUser',
  Account = 'Account',
  AccountRepresentative = 'AccountRepresentative',
  AccountRole = 'AccountRole',
  Company = 'Company',
  CompanyRepresentative = 'CompanyRepresentative',
  CompanyUsers = 'CompanyUsers',
  Unit = 'Unit',
  Billing = 'Billing',
}

export abstract class PermissionClient {
  protected can: AbilityBuilder<any>['can'];
  protected cannot: AbilityBuilder<any>['cannot'];

  constructor(
    protected readonly builder: Pick<AbilityBuilder<any>, 'can' | 'cannot'>,
    protected readonly permissionsContext: PermissionsContext,
  ) {
    this.can = builder.can;
    this.cannot = builder.cannot;
  }

  abstract grantPermissions(): void;
}

export abstract class PermissionEntity {
  constructor(public id: string) {}
}

export class UserEntity extends PermissionEntity {
  static get modelName(): string {
    return Entity.User;
  }
}

export class RegistryAdminUserEntity extends PermissionEntity {
  static get modelName() {
    return Entity.RegistryAdminUser;
  }
}

export class AccountEntity extends PermissionEntity {
  static get modelName() {
    return Entity.Account;
  }
}

export class AccountRepresentativeEntity extends PermissionEntity {
  static get modelName() {
    return Entity.AccountRepresentative;
  }
}

export class AccountRoleEntity extends PermissionEntity {
  static get modelName() {
    return Entity.AccountRole;
  }
}

export class CompanyEntity extends PermissionEntity {
  static get modelName() {
    return Entity.Company;
  }
}

export class CompanyRepresentativeEntity extends PermissionEntity {
  static get modelName() {
    return Entity.CompanyRepresentative;
  }
}

export class CompanyUsersEntity extends PermissionEntity {
  static get modelName() {
    return Entity.CompanyUsers;
  }
}

export class UnitEntity extends PermissionEntity {
  static get modelName() {
    return Entity.Unit;
  }
}

export class BillingEntity extends PermissionEntity {
  static get modelName() {
    return Entity.Billing;
  }
}
