import LinearProgress from '@mui/material/LinearProgress';
import SvgIcon from '@mui/material/SvgIcon';
import type { FileRejection, FileWithPath } from 'react-dropzone';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import type {
  FileInfoType,
  FileUploadInputProps,
} from './FileUploadInput.types';
import { formatErrorMessage } from './FileUploadInput.utils';
import {
  FileContent,
  FilesWrapper,
  FileText,
  StyledIconButton,
  Text,
  UploadBox,
  Wrapper,
} from './FileUploadInput.styled';
import { Icon } from '@safc/assets/Icon';

const FILE_ITEM_HEIGHT = 37;

const FileInfo = ({
  name,
  errors = [],
  removeFile,
  openFile,
  showDownload,
}: FileInfoType) => {
  return (
    <FileContent>
      <Grid container>
        <Grid item xs={showDownload ? 5 : 6} container>
          <FileText
            noWrap
            maxWidth={156}
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {name ?? ''}
          </FileText>
        </Grid>
        <Grid item xs={5}>
          {errors?.length > 0 && (
            <FileText color="error">
              {formatErrorMessage(errors?.[0]?.code)}
            </FileText>
          )}
        </Grid>
        {showDownload ? (
          <Grid item xs={1} container>
            <StyledIconButton onClick={openFile}>
              <SvgIcon
                component={ArrowCircleDownIcon}
                inheritViewBox
                sx={{
                  fontSize: '21px',
                  fill: ({ palette }) => palette.primary.dark,
                }}
              />
            </StyledIconButton>
          </Grid>
        ) : null}
        <Grid item xs={1} container>
          <StyledIconButton onClick={removeFile}>
            <Icon icon="outlineDeleteSvg" sx={{ height: 21 }} />
          </StyledIconButton>
        </Grid>
      </Grid>
    </FileContent>
  );
};

export const FileUploadInput = ({
  files,
  getRootProps,
  getInputProps,
  rejectedFiles,
  removeFile,
  removeRejectedFile,
  wrapperProps,
  openFile,
  showDownload = false,
  filesToDisplay = 3,
  uploadInProgress,
}: FileUploadInputProps) => {
  return (
    <Wrapper {...wrapperProps}>
      <UploadBox {...getRootProps()}>
        <input {...getInputProps()} />
        <Icon sx={{ height: 21 }} icon="uploadSvg" />
        <Text>
          <span>
            <strong>Drag and drop</strong> or browse to choose a file
          </span>
        </Text>
      </UploadBox>
      {uploadInProgress ? <LinearProgress sx={{ width: '100%' }} /> : null}
      <Collapse
        style={{ width: '100%' }}
        in={files.length > 0 || rejectedFiles.length > 0}
      >
        <FilesWrapper style={{ maxHeight: filesToDisplay * FILE_ITEM_HEIGHT }}>
          {files.map((file: string, index: number) => {
            return (
              <FileInfo
                key={`file-${file}-${index}`}
                name={file}
                removeFile={() => removeFile(index)}
                openFile={() => openFile(index)}
                showDownload={showDownload}
              />
            );
          })}
          {rejectedFiles.map((rejected: FileRejection, index: number) => {
            const file = rejected?.file as FileWithPath;
            return (
              <FileInfo
                key={`rejected-file-${file?.path}-${index}`}
                name={file?.path ?? ''}
                errors={rejected.errors}
                removeFile={() => removeRejectedFile(index)}
                openFile={() => openFile(index)}
                showDownload={showDownload}
              />
            );
          })}
        </FilesWrapper>
      </Collapse>
    </Wrapper>
  );
};
