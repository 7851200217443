import type { FC, ReactElement } from 'react';
import Box from '@mui/material/Box';
import MuiCircularProgress from '@mui/material/CircularProgress';
import {
  CloseIconButton,
  CloseIconWrapper,
  ConfirmModalDescription,
  ConfirmModalTitle,
  StyledModal,
} from './ConfirmModal.styles';
import type { ModalProps } from '../Modal';
import { WarningIcon } from '../../icons/WarningIcon';
import type { IconKey } from '@safc/assets/Icon';
import { Icon as IconElement } from '@safc/assets/Icon';

export interface ConfirmModalProps extends ModalProps {
  title?: string;
  text?: string;
  Icon?: ReactElement | FC | IconKey | null;
}

export const CircularProgress = () => {
  return <MuiCircularProgress size={55} />;
};

const isElement = (Icon: ReactElement | FC): Icon is ReactElement =>
  (Icon as any).type;

const renderElementIcon = (Icon: ReactElement | FC): ReactElement =>
  (isElement(Icon) ? Icon : <Icon />);

export const ConfirmModal: FC<ConfirmModalProps> = ({
  children,
  title,
  text,
  Icon = WarningIcon,
  ...props
}) => {
  return (
    <StyledModal {...props}>
      {props.fullScreen && (
        <CloseIconWrapper>
          <CloseIconButton
            onClick={(event) => props.onClose?.(event, 'backdropClick')}
          />
        </CloseIconWrapper>
      )}
      {Icon === null ? null : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb={2}
          width={67}
          height={68}
        >
          {typeof Icon === 'string' ? (
            <IconElement icon={Icon} />
          ) : (
            renderElementIcon(Icon)
          )}
        </Box>
      )}
      {children}
      {title && <ConfirmModalTitle>{title}</ConfirmModalTitle>}
      {text && <ConfirmModalDescription>{text}</ConfirmModalDescription>}
    </StyledModal>
  );
};
