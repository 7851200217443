import styled from '@mui/material/styles/styled';
import MuiListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { listItemClasses, svgIconClasses, typographyClasses, alpha } from '@mui/material';

export const ListItemButton = styled(MuiListItemButton)(({ theme }) => {
  const inactiveColor = alpha(theme.palette.common.black, 0.55);
  const activeColor = theme.palette.primary.main;

  return {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: 'auto',
    minHeight: 52,
    padding: '5px 7px 5px 7px',
    marginBottom: 10,
    [`& .${svgIconClasses.root}`]: {
      fontSize: 22,
      '& path': {
        fill: inactiveColor,
        stroke: inactiveColor,
        strokeWidth: '0.3px',
        transition: theme.transitions.create('fill', {
          duration: theme.transitions.duration.shortest,
        }),
      },
    },
    [`&:not(.${listItemClasses.selected}):hover`]: {
      backgroundColor: alpha(activeColor, 0.25),
      [`& > .${typographyClasses.root}`]: {
        color: activeColor,
        fontWeight: 600,
      },
      [`& .${svgIconClasses.root}`]: {
        '& path': {
          fill: activeColor,
          stroke: activeColor,
          strokeWidth: '0.3px',
        },
      },
    },
    [`&.${listItemClasses.selected}`]: {
      [`& > .${typographyClasses.root}`]: {
        color: activeColor,
        fontWeight: 600,
      },
      boxShadow: `inset 4px 0 0 0 ${activeColor}`,
      backgroundColor: theme.palette.background.default,
      zIndex: 1,
      [`& .${svgIconClasses.root} path`]: {
        fill: activeColor,
        stroke: activeColor,
        strokeWidth: '0.4px',
      },
      '&:hover': {
        backgroundColor: alpha(activeColor, 0.25),
      },
      [`&:hover .${svgIconClasses.root} path`]: {
        fill: activeColor,
      },
    },
  };
}) as typeof MuiListItemButton;

export const Label = styled(Typography)(({ theme }) => ({
  fontSize: 11,
  lineHeight: '13px',
  fontWeight: 500,
  color: alpha(theme.palette.common.black, 0.55),
  transition: theme.transitions.create('color', {
    duration: theme.transitions.duration.shortest,
  }),
}));
