import { RoutePaths } from '@safc/ui-utils/routes';
import { useAccountUnitContext } from '@safc/ui-utils/useAccountUnitsContext';
import { useContext } from 'react';
import type { TMenuItem } from 'components/navigation/NavMenuItem';
import { Action, Entity } from '@safc/ui-utils/permissions/entities';
import { AbilityContext } from '@safc/ui-utils/permissions/usePermissions';
import { useCompanyContext } from '@safc/ui-utils/useCompanyContext';

type PermissionedMenuItem = TMenuItem & { hasPermission: boolean };

export const useNavigationMenu = (): TMenuItem[] => {
  const ability = useContext(AbilityContext);
  const { companyId } = useCompanyContext();
  const { accounts } = useAccountUnitContext();

  const accountsAvailable = Boolean(accounts) && Boolean(accounts?.length);

  const navigationItems: PermissionedMenuItem[] = [
    {
      url: RoutePaths.COMPANY,
      label: 'Company information',
      icon: 'navigationCompanySvg',
      hasPermission: true,
      testId: 'menu-company',
    },
    {
      url: RoutePaths.ACCOUNTS,
      label: 'Accounts',
      icon: 'navigationAccountsSvg',
      hasPermission: Boolean(companyId),
      testId: 'menu-accounts',
    },
    {
      url: RoutePaths.COLLEAGUES,
      label: 'Colleagues',
      icon: 'navigationColleaguesSvg',
      hasPermission:
        ability.can(Action.View, Entity.CompanyUsers) && Boolean(companyId),
      testId: 'menu-colleagues',
    },
    {
      url: RoutePaths.HOLDINGS,
      label: 'Holdings',
      icon: 'navigationHoldingsSvg',
      hasPermission: Boolean(companyId) && accountsAvailable,
      testId: 'menu-holdings',
    },
    {
      url: RoutePaths.LOGS,
      label: 'Logs',
      icon: 'navigationLogsSvg',
      hasPermission: Boolean(companyId),
      testId: 'menu-logs',
    },
  ];
  return navigationItems.filter((menu) => menu.hasPermission);
};

export const useCreateRegistryAdminNavigationMenu = (): TMenuItem[] => {
  const { accounts } = useAccountUnitContext();
  const items: PermissionedMenuItem[] = [
    {
      url: RoutePaths.COMPANY,
      label: 'Company information',
      icon: 'navigationCompanySvg',
      testId: 'menu-company',
      hasPermission: true,
    },
    {
      url: RoutePaths.COMPANIES,
      label: 'Companies',
      icon: 'navigationCompanySvg',
      testId: 'menu-companies',
      hasPermission: true,
    },
    {
      url: RoutePaths.ACCOUNTS,
      label: 'Accounts',
      icon: 'navigationAccountsSvg',
      testId: 'menu-accounts',
      hasPermission: true,
    },
    {
      url: RoutePaths.USERS,
      label: 'Users',
      icon: 'navigationColleaguesSvg',
      testId: 'menu-users',
      hasPermission: true,
    },
    {
      url: RoutePaths.ACTIONS,
      label: 'Actions',
      icon: 'navigationActionsSvg',
      testId: 'menu-actions',
      hasPermission: true,
    },
    {
      url: RoutePaths.HOLDINGS,
      label: 'Holdings',
      icon: 'navigationHoldingsSvg',
      testId: 'menu-holdings',
      hasPermission: Boolean(accounts?.length),
    },
    {
      url: RoutePaths.ANALYTICS,
      label: 'Analytics',
      icon: 'navigationAnalyticsSvg',
      testId: 'menu-analytics',
      hasPermission: Boolean(accounts?.length),
    },
    {
      url: RoutePaths.LOGS,
      label: 'Logs',
      icon: 'navigationLogsSvg',
      testId: 'menu-logs',
      hasPermission: true,
    },
    {
      url: RoutePaths.BILLING,
      label: 'Billing',
      icon: 'navigationBillingSvg',
      hasPermission: true,
    },
  ];

  return items.filter((menu) => menu.hasPermission);
};
