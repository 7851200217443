import { getVariants } from './InitialsIcon.utils';
import { sizes } from './InitialsIcon.utils';
import type { StyleProps } from './InitialsIcon.types';
import useTheme from '@mui/material/styles/useTheme';

export const useRootStyles = ({ variant, size, color, type, text = '' }: StyleProps) => {
  const theme = useTheme();
  const currentVariant = getVariants(theme, color)[variant];
  const currentSize = sizes[size];
  const getRandomColorFromPalette = () => {
    const colors = [
      theme.palette.secondary.main,
      theme.palette.secondary.light,
      theme.palette.error.light,
      theme.palette.warning.main,
      theme.palette.action.active,
      theme.palette.success.main,
    ];
    let index = 0;
    for (let i = 0; i < text.length; i++) {
      index += text.charCodeAt(i);
    }
    return colors[index % colors.length];
  };
  return {
    'border': currentVariant?.border,
    'boxShadow': currentVariant?.boxShadow,
    'borderRadius': type === 'circle' ? '50%' : '8px',
    'width': currentSize.size,
    'height': currentSize.size,
    'backgroundColor': getRandomColorFromPalette(),
    '& .MuiTypography-root': {
      fontSize: currentSize.fontSize,
      fontWeight: currentSize.fontWeight,
      lineHeight: currentSize.lineHeight,
      color: theme.palette.common.white,
    },
  };
};
