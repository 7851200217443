import { alpha, darken } from '@mui/material/styles';
import { paperClasses } from '@mui/material';
import type { Theme } from '@mui/material';
import {
  dayPickerClasses,
  pickersDayClasses,
  pickersYearClasses,
  pickersCalendarHeaderClasses,
  dateCalendarClasses,
} from '@mui/x-date-pickers';

export const popperStyles = (theme: Theme) => ({
  [`& .${paperClasses.root}`]: {
    boxShadow: `0px 10px 10px ${alpha(theme.palette.common.black, 0.08)}`,
    borderRadius: '0px 0px 4px 4px',
  },
  [`& .${dateCalendarClasses.root}`]: {
    width: 320,
    [`& .${dayPickerClasses.weekDayLabel}`]: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
      color: darken(theme.palette.primary.dark, 0.37),
    },
    [`& .${pickersDayClasses.root}, & .${pickersYearClasses.root}`]: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '21px',
      color: darken(theme.palette.primary.dark, 0.37),
      [`&.${pickersDayClasses.selected}`]: {
        color: theme.palette.common.white,
      },
    },
    [`& .${pickersDayClasses.dayOutsideMonth}, & .${pickersYearClasses.disabled}`]:
      {
        color: alpha(darken(theme.palette.primary.dark, 0.37), 0.2),
      },
    [`& .${pickersCalendarHeaderClasses.root}`]: {
      marginBottom: 0,
      paddingLeft: '12px',
      [`& .${pickersCalendarHeaderClasses.label}`]: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '23px',
        color: darken(theme.palette.primary.dark, 0.37),
        marginRight: 0,
      },
    },
    '& .MuiPickersArrowSwitcher-root': {
      position: 'absolute',
      left: 0,
      width: '100%',
      '& .MuiPickersArrowSwitcher-button': {
        top: -17,
        position: 'absolute',
        '&.Mui-disabled': {
          opacity: 0.2,
        },
      },
      '& .MuiIconButton-edgeStart': {
        right: 10,
      },
      '& .MuiIconButton-edgeEnd': {
        left: 10,
      },
    },
    [`& .${pickersCalendarHeaderClasses.labelContainer}`]: {
      marginLeft: 'auto',

      '& .MuiIconButton-root': {
        display: 'none',
      },
    },
  },
});
