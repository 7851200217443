import { type SyntheticEvent, useState } from 'react';

export const useFormSectionEffects = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  const onChangeTabHandler = (_event: SyntheticEvent, newValue: number) =>
    setCurrentTab(newValue);

  return {
    currentTab,
    onChangeTabHandler,
  };
};
