import { AccountApiStatus, AccountMembershipRole } from '@safc/api-client';
import { Action, Entity } from './entities';
import type { AbilityBuilder } from '@casl/ability';
import type { PermissionsContext } from './permission.types';

type CanCannotBuilders = {
  can: AbilityBuilder<any>['can'];
  cannot: AbilityBuilder<any>['cannot'];
};

export const buildUnitPermissions = (
  { can }: CanCannotBuilders,
  permissionsContext: PermissionsContext,
) => {
  const { user } = permissionsContext;

  if (user.status !== 'active') {
    return;
  }

  /**
   * Accounts that have status active and user is a member
   */
  const activeAccountIds = user.accounts.filter(a => a.status === AccountApiStatus.active).map((a) => a.id);

  const viewableAccounts = user.accountMemberships.map((a) => a.accountId);

  /**
   * Accounts where user is full access or initiator
   * and the account is active
   */
  const initiateAccounts = user.accountMemberships
    .filter(
      (a) =>
        (a.role === AccountMembershipRole.accountRepFullAccess ||
          a.role === AccountMembershipRole.accountRepInitiator) &&
        activeAccountIds.includes(a.accountId),
    )
    .map((a) => a.accountId);

  /**
   * Accounts where user full access or approver
   * and the account is active
   */
  const signAccounts = user.accountMemberships
    .filter(
      (a) =>
      (a.role === AccountMembershipRole.accountRepFullAccess ||
       a.role === AccountMembershipRole.accountRepApprover) &&
        activeAccountIds.includes(a.accountId),
    )
    .map((a) => a.accountId);

  can(Action.View, Entity.Unit, {
    id: { $in: viewableAccounts },
  });

  can(Action.Issue, Entity.Unit, {
    id: { $in: initiateAccounts },
  });
  can(Action.Transfer, Entity.Unit, {
    id: { $in: initiateAccounts },
  });
  can(Action.Split, Entity.Unit, {
    id: { $in: initiateAccounts },
  });
  can(Action.Retire, Entity.Unit, {
    id: { $in: initiateAccounts },
  });
  can(Action.Sign, Entity.Unit, { id: { $in: signAccounts } });
};
