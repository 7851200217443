import { Navigate, Route } from 'react-router-dom';
import { useRoutesRootElement } from './useRoutesRootElement';

import { useNavigationMenu } from 'utils/navigationMenu';
import { RoutePaths } from '@safc/ui-utils/routes';
import { Suspense } from 'components/common/Suspense';
import { asLazy } from 'utils/lazy.utils';

const LogsPage = asLazy(import('../../pages/LogsPage/LogsPage'), 'LogsPage');

const BillingPage = asLazy(
  import('../../pages/Billing/BillingPage/BillingPage'),
  'BillingPage',
);
const Account = asLazy(import('../../pages/Account'), 'Account');
const Accounts = asLazy(import('../../pages/Accounts'), 'Accounts');
const ColleaguesPage = asLazy(
  import('../../pages/ColleaguesPage'),
  'ColleaguesPage',
);
const ColleagueViewPage = asLazy(
  import('../../pages/ColleagueViewPage'),
  'ColleagueViewPage',
);
const InvoiceView = asLazy(
  import('../../pages/Billing/InvoiceView/InvoiceView'),
  'InvoiceView',
);
const Company = asLazy(import('../../pages/Company/Company'), 'Company');
const Holdings = asLazy(import('../../pages/Holdings'), 'Holdings');
const UnitPage = asLazy(import('../../pages/UnitPage/UnitPage'), 'UnitPage');
const ProfileSettings = asLazy(
  import('../../pages/ProfileSettings'),
  'ProfileSettings',
);

export const useRegularRoutes = () => {
  const navigationItems = useNavigationMenu();
  const renderRootElement = useRoutesRootElement();

  return (
    <Route path="/" element={renderRootElement(navigationItems)}>
      <Route
        path={RoutePaths.COMPANY}
        element={
          <Suspense>
            <Company />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.ACCOUNTS}
        element={
          <Suspense>
            <Accounts />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.COLLEAGUES}
        element={
          <Suspense>
            <ColleaguesPage />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.COLLEAGUE()}
        element={
          <Suspense>
            <ColleagueViewPage />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.INVOICE_VIEW()}
        element={
          <Suspense>
            <InvoiceView />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.PROFILE_SETTINGS}
        element={
          <Suspense>
            <ProfileSettings />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.ACCOUNT()}
        element={
          <Suspense>
            <Account />
          </Suspense>
        }
      />
      <Route path={RoutePaths.USERS} element={null} />
      <Route path={RoutePaths.COLLEAGUES} element={null} />
      <Route
        path={RoutePaths.HOLDINGS}
        element={
          <Suspense>
            <Holdings />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.LOGS}
        element={
          <Suspense>
            <LogsPage />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.BILLING}
        element={
          <Suspense>
            <BillingPage />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.UNIT_VIEW()}
        element={
          <Suspense>
            <UnitPage />
          </Suspense>
        }
      />
      <Route path="/" element={<Navigate to={RoutePaths.COMPANY} />} />
    </Route>
  );
};
