import type { JSONSchema } from 'json-schema-to-typescript';

const splitSchema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  properties: {
    blockId: {
      type: 'string',
    },
    volume: {
      type: 'number',
      minimum: 0.001,
    },
    accountId: {
      type: 'string',
    },
  },
  required: ['blockId', 'volume', 'accountId'],
  additionalProperties: false,
} satisfies JSONSchema;

export const Split = {
  schema: splitSchema,
  mapping: {
    blockId: 'blockId',
    volume: 'volume',
    accountId: 'accountId',
  },
  nonUpdatable: ['blockId', 'volume', 'accountId'],
};
