import type { FC } from 'react';
import type { BackdropProps } from '@mui/material';
import MuiBackdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const Backdrop: FC<BackdropProps> = (props) => {
  return (
    <MuiBackdrop
      transitionDuration={0}
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      {...props}
    >
      <CircularProgress size={55} />
    </MuiBackdrop>
  );
};
