import type { ErrorResponse } from '@safc/api-client';
import { AxiosError } from 'axios';

const isHandledErrorResponse = (
  error?: any,
): error is ErrorResponse => {
  return Boolean(error?.message && error?.statusCode);
};

const formatHandledErrorMessage = (error: ErrorResponse) => {
  if (error.details?.reason) {
    return `${error.details.reason}`;
  }

  if (error.errorId) {
    return error.message;
  }

  return `[${error.statusCode}] ${error.message}`;
};

export type ParsedError = { message: string; errorId?: string };

export const getErrorMessage = (error: unknown): ParsedError => {
  if (!error) {
    return { message: '' };
  }

  if (error instanceof AxiosError) {
    const errorBody = error.response?.data;
    if (isHandledErrorResponse(errorBody)) {
      return {
        errorId: errorBody.errorId,
        message: formatHandledErrorMessage(errorBody),
      };
    }

    return errorBody?.message ?? error.cause?.message ?? error.message;
  }

  const anyError = error as any;
  if (anyError?.message) {
    return {
      message: `${anyError.message}`,
    };
  }

  return {
    message: 'No detailed description of the error',
  };
};
