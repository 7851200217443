import type {
  DatePickerProps } from '@safc/ui-components/components/form/DatePicker';
import {
  DatePicker,
} from '@safc/ui-components/components/form/DatePicker';
import type { GenericFormField } from '@safc/ui-components/components/form/GenericForm';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

export interface FormDatePickerProps {
  field: GenericFormField<DatePickerProps['componentProps']>;
  control: Control;
}

export const FormDatePicker = ({ control, field }: FormDatePickerProps) => {
  return (
    <Controller
      key={`${field.name}`}
      name={field.name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <DatePicker
            disabled={field?.disabled}
            placeholder={field?.placeholder}
            onChange={onChange}
            value={value}
            componentProps={field?.componentProps}
          />
        );
      }}
    />
  );
};
