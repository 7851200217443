import edfLogoPng from './icons/edf/logo.png?url';
import rmiLogoPng from './icons/rmi/logo.png?url';
import accountsLogisticsProviderHpPng from './icons/accounts/logistics-provider-hp.png?url';
import edfLogoSvg from './icons/edf/logo.svg?url';
import invitationExistingUserSvg from './icons/invitation/existing-user.svg?url';
import invitationNewUserSvg from './icons/invitation/new-user.svg?url';
import navigationAccountsSvg from './icons/navigation/accounts.svg?url';
import navigationActionsSvg from './icons/navigation/actions.svg?url';
import navigationBillingSvg from './icons/navigation/billing.svg?url';
import navigationColleaguesSvg from './icons/navigation/colleagues.svg?url';
import navigationCompanySvg from './icons/navigation/company.svg?url';
import navigationHoldingsSvg from './icons/navigation/holdings.svg?url';
import navigationLogoutSvg from './icons/navigation/logout.svg?url';
import navigationProfileSettingsSvg from './icons/navigation/profile-settings.svg?url';
import navigationTcLegalPrivacySvg from './icons/navigation/tc-legal-privacy.svg?url';
import navigationBellSvg from './icons/navigation/bell.svg?url';
import navigationLogsSvg from './icons/navigation/logs.svg?url';
import navigationAnalyticsSvg from './icons/navigation/analytics.svg?url';
import rmiLogoSvg from './icons/rmi/logo.svg?url';
import arrowDownCircleSvg from './icons/arrow-down-circle.svg?url';
import arrowLeftCircleSvg from './icons/arrow-left-circle.svg?url';
import blockSvg from './icons/block.svg?url';
import checkSecondarySvg from './icons/check-secondary.svg?url';
import closeSvg from './icons/close.svg?url';
import customerSvg from './icons/customer.svg?url';
import documentSvg from './icons/document.svg?url';
import documentEvaluateSvg from './icons/document-evaluate.svg?url';
import dropdownArrowDownSvg from './icons/dropdown-arrow-down.svg?url';
import errorSvg from './icons/error.svg?url';
import ewLogoSvg from './icons/ew-logo.svg?url';
import ewLogoFullSvg from './icons/ew-logo-full.svg?url';
import helpOutlineRoundedSvg from './icons/help-outline-rounded.svg?url';
import issuanceTableIconSvg from './icons/issuance-table-icon.svg?url';
import modalCloseSvg from './icons/modal-close.svg?url';
import othersSvg from './icons/others.svg?url';
import poweredByEnergyWebSvg from './icons/powered-by-energy-web.svg?url';
import registryIconSvg from './icons/registry-icon.svg?url';
import safIconSvg from './icons/saf-icon.svg?url';
import safIconDarkSvg from './icons/saf-icon-dark.svg?url';
import sumsubLogoSvg from './icons/sumsub-logo.svg?url';
import supportSvg from './icons/support.svg?url';
import supportFireflySvg from './icons/support-firefly.svg?url';
import userSvg from './icons/user.svg?url';
import binSvg from './icons/bin.svg?url';
import calendarSvg from './icons/calendar.svg?url';
import calendarLeftArrowSvg from './icons/calendar-left-arrow.svg?url';
import calendarRightArrowSvg from './icons/calendar-right-arrow.svg?url';
import checkSvg from './icons/check.svg?url';
import checkDarkSvg from './icons/check-dark.svg?url';
import checkSuccessSvg from './icons/check-success.svg?url';
import editOutlineSvg from './icons/edit-outline.svg?url';
import filterIconSvg from './icons/filter-icon.svg?url';
import infoSvg from './icons/info.svg?url';
import inputClearSvg from './icons/input-clear.svg?url';
import minusSvg from './icons/minus.svg?url';
import minusCircleSvg from './icons/minus-circle.svg?url';
import minusDarkSvg from './icons/minus-dark.svg?url';
import optionSvg from './icons/option.svg?url';
import outlineDeleteSvg from './icons/outline-delete.svg?url';
import plusCircleSvg from './icons/plus-circle.svg?url';
import stepActiveSvg from './icons/step-active.svg?url';
import uploadSvg from './icons/upload.svg?url';
import alertErrorSvg from './icons/alert-error.svg?url';
import alertSuccessSvg from './icons/alert-success.svg?url';
import alertWarningSvg from './icons/alert-warning.svg?url';
import issueIconSvg from './icons/issue-icon.svg?url';
import issueIconWSvg from './icons/issue-icon-w.svg?url';
import splitIconSvg from './icons/split-icon.svg?url';
import transferIconSvg from './icons/transfer-icon.svg?url';
import accountsAccountPhotoSvg from './icons/accounts/account-photo.svg?url';
import accountsAirTransportProviderHAccountSvg from './icons/accounts/air-transport-provider-h-account.svg?url';
import accountsFuelProviderHAccountSvg from './icons/accounts/fuel-provider-h-account.svg?url';
import accountsFuelProviderHpSvg from './icons/accounts/fuel-provider-hp.svg?url';
import accountsGeneralHoldingSvg from './icons/accounts/general-holding.svg?url';
import accountsLogisticsProviderHAccountSvg from './icons/accounts/logistics-provider-h-account.svg?url';
import accountsAtProviderSvg from './icons/accounts/at-provider.svg?url';
import unitsBlockSvg from './icons/units/block.svg?url';
import warningCircleSvg from './icons/warning-circle.svg?url';
import solarShieldSvg from './icons/solar-shield.svg?url';
import unbundleIconSvg from './icons/unbundle-icon.svg?url';

export const icons = {
  edfLogoPng,
  rmiLogoPng,
  accountsLogisticsProviderHpPng,
  edfLogoSvg,
  invitationExistingUserSvg,
  invitationNewUserSvg,
  navigationAccountsSvg,
  navigationActionsSvg,
  navigationBillingSvg,
  navigationColleaguesSvg,
  navigationCompanySvg,
  navigationHoldingsSvg,
  navigationLogoutSvg,
  navigationProfileSettingsSvg,
  navigationTcLegalPrivacySvg,
  navigationBellSvg,
  navigationLogsSvg,
  navigationAnalyticsSvg,
  rmiLogoSvg,
  arrowDownCircleSvg,
  arrowLeftCircleSvg,
  blockSvg,
  checkSecondarySvg,
  closeSvg,
  customerSvg,
  documentSvg,
  documentEvaluateSvg,
  dropdownArrowDownSvg,
  errorSvg,
  ewLogoSvg,
  ewLogoFullSvg,
  helpOutlineRoundedSvg,
  issuanceTableIconSvg,
  modalCloseSvg,
  othersSvg,
  poweredByEnergyWebSvg,
  registryIconSvg,
  safIconSvg,
  safIconDarkSvg,
  sumsubLogoSvg,
  supportSvg,
  supportFireflySvg,
  userSvg,
  binSvg,
  calendarSvg,
  calendarLeftArrowSvg,
  calendarRightArrowSvg,
  checkSvg,
  checkDarkSvg,
  checkSuccessSvg,
  editOutlineSvg,
  filterIconSvg,
  infoSvg,
  inputClearSvg,
  minusSvg,
  minusCircleSvg,
  minusDarkSvg,
  optionSvg,
  outlineDeleteSvg,
  plusCircleSvg,
  stepActiveSvg,
  uploadSvg,
  alertErrorSvg,
  alertSuccessSvg,
  alertWarningSvg,
  issueIconSvg,
  issueIconWSvg,
  splitIconSvg,
  transferIconSvg,
  accountsAccountPhotoSvg,
  accountsAirTransportProviderHAccountSvg,
  accountsFuelProviderHAccountSvg,
  accountsFuelProviderHpSvg,
  accountsGeneralHoldingSvg,
  accountsLogisticsProviderHAccountSvg,
  accountsAtProviderSvg,
  unitsBlockSvg,
  warningCircleSvg,
  solarShieldSvg,
  unbundleIconSvg,
} as const;
