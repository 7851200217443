import type { JSONSchema } from 'json-schema-to-typescript';

const transferSchema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  properties: {
    blockId: {
      type: 'string',
    },
    volume: {
      type: 'number',
      minimum: 0.001,
    },
    recipientId: {
      type: 'string',
      format: 'uuid',
    },
    accountId: {
      type: 'string',
      format: 'uuid',
    },
  },
  required: ['blockId', 'volume', 'recipientId', 'accountId'],
  additionalProperties: false,
} satisfies JSONSchema;

const transferUiSchema = {
  'ui:submitButtonOptions': {
    submitText: 'Save',
    style: {
      marginTop: 20,
    },
  },
  'blockId': {
    'ui:title': 'Block ID',
    'ui:placeholder': 'Block ID',
  },
  'volume': {
    'ui:title': 'Volume',
    'ui:placeholder': 'Volume',
  },
  'recipientId': {
    'ui:title': 'Recipient ID',
    'ui:placeholder': 'Recipient ID',
    'ui:widget': 'hidden',
  },
  'accountId': {
    'ui:title': 'Account ID',
    'ui:placeholder': 'Account ID',
  },
};

export const Transfer = {
  schema: transferSchema,
  uiSchema: transferUiSchema,
  mapping: {
    blockId: 'blockId',
    volume: 'volume',
    recipientId: 'recipientId',
    accountId: 'accountId',
  },
  nonUpdatable: ['blockId', 'volume', 'recipientId', 'accountId'],
};
