import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '@safc/ui-utils/routes';
import {
  getActionsControllerGetPendingActionsCountQueryKey,
  getActionsControllerGetPendingActionsQueryKey,
  useActionsControllerGetPendingActions,
  useActionsControllerGetPendingActionsCount,
} from '@safc/api-client';
import uniq from 'lodash/uniq';
import first from 'lodash/first';
import { useMyUser } from '@safc/ui-utils/getMyUser';
import { useCurrentCompany } from '@safc/ui-utils/useCurrentCompany';

export const useNotificationBellEffects = () => {
  const { companyId } = useCurrentCompany();
  const { data: pendingActionsCount } =
    useActionsControllerGetPendingActionsCount(
      {
        companyId,
      },
      {
        query: {
          refetchInterval: 15000,
          throwOnError: false,
          queryKey: getActionsControllerGetPendingActionsCountQueryKey({
            companyId,
          }),
        },
      },
    );
  const navigate = useNavigate();
  const { data: pendingActions } = useActionsControllerGetPendingActions(
    {
      companyId,
    },
    {
      query: {
        refetchInterval: 15000,
        enabled: Object.values(pendingActionsCount ?? {}).some(
          (count) => count > 0,
        ),
        queryKey: getActionsControllerGetPendingActionsQueryKey({ companyId }),
        throwOnError: false,
      },
    },
  );

  const { user } = useMyUser();

  const navigateToActions = () => {
    if (user?.isRegistryAdmin) {
      navigate(RoutePaths.ACTIONS);
    } else {
      const pendingAccountActions = uniq([
        ...(pendingActions?.pendingAcceptance.processes?.map(
          (p) => p.receivingAccountId,
        ) ?? []),
        ...(pendingActions?.pendingSignature.processes.map(
          (p) => p.sendingAccountId,
        ) ?? []),
      ]);
      const accountId = first(pendingAccountActions);

      navigate({
        pathname: RoutePaths.HOLDINGS,
        search: `?${new URLSearchParams({
          ...(accountId ? { accountId } : {}),
          selectedTab: 'pendingActions',
        })}`,
      });
    }
  };
  const actionsCount = pendingActionsCount
    ? pendingActionsCount.pendingApprovalCount +
      pendingActionsCount.pendingAcceptanceCount +
      pendingActionsCount.pendingSignatureCount
    : 0;
  const notificationButtonDisabled = actionsCount === 0;
  const previousActionsCount = useRef<number>(0);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (actionsCount > previousActionsCount.current) {
      setShowAnimation(true);
      const timeoutId = setTimeout(() => setShowAnimation(false), 600);

      return () => clearTimeout(timeoutId);
    }

    previousActionsCount.current = actionsCount;
  }, [actionsCount]);

  return {
    actionsCount,
    notificationButtonDisabled,
    navigateToActions,
    showAnimation,
  };
};
