import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { svgIconClasses, typographyClasses } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const StyledRadio = styled(Radio)(({ theme }) => ({
  [`& .${svgIconClasses.root}`]: {
    fontSize: 20,
    color: theme.palette.action.active,
  },
}));

export const RadioFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginRight: 21,
  [`& .${typographyClasses.root}`]: {
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 400,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.body1.fontFamily,
    marginLeft: 4,
  },
}));

export const RadioFormLabel = styled(FormLabel)(({ theme }) => ({
  color: alpha(theme.palette.common.black, 0.75),
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 600,
  marginBottom: '10px',
  '&:focus': {
    color: alpha(theme.palette.common.black, 0.75),
  },
}));
