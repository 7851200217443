import { alpha, styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  '& > .MuiDrawer-paper': {
    top: '67px',
    margin: '5px 7px 7px 10px',
    borderRadius: 6,
    width: 74,
    borderRight: 'none',
    boxShadow: `1px 1px 12px ${alpha(theme.palette.common.white, 0.03)}`,
  },
}));
