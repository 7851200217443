import { Controller } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import type { FileRejection, FileWithPath } from 'react-dropzone';
import type { FileUploadProps, TFile } from './FileUpload.types';
import { useFileUploadEffects } from './FileUpload.effects';
import { ONE_MB } from 'utils/constants';
import { FileUploadInput } from '@safc/ui-components/components/form/FileUploadInput';

export const FileUpload = (props: FileUploadProps) => {
  const { control, field } = props;
  const { onDropRejected, rejectedFiles, removeRejectedFile } =
    useFileUploadEffects(props);

  return (
    <Controller
      name={field.name}
      control={control}
      render={({ field: { onChange, value: files = [] } }) => {
        const removeFile = (i: number) => {
          const newFiles = files.filter(
            (_: TFile, index: number) => index !== i,
          );
          onChange(newFiles);
        };

        const fileNames = files.map((file: FileWithPath) => file?.path);
        return (
          <Dropzone
            accept={field?.fileProps?.accept ?? {}}
            multiple={true}
            onDropRejected={onDropRejected}
            maxFiles={field?.fileProps?.maxFiles ?? 10}
            maxSize={field?.fileProps?.maxSize ?? 3 * ONE_MB}
            onDrop={(acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
              if (rejectedFiles.length > 0) return;
              onChange([...files, ...acceptedFiles]);
            }}
          >
            {({ getRootProps, getInputProps }) => {
              return (
                <FileUploadInput
                  files={fileNames}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  rejectedFiles={rejectedFiles}
                  removeRejectedFile={removeRejectedFile}
                  removeFile={removeFile}
                  wrapperProps={props?.field?.wrapperProps}
                  openFile={() => null}
                />
              );
            }}
          </Dropzone>
        );
      }}
    />
  );
};
