import { alpha, darken, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { buttonClasses, typographyClasses } from '@mui/material';

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: '13px 28px 13px 28px',
  borderRadius: 8,
  height: 45,
  '&&&:disabled': {
    color: theme.palette.text.disabled,
    backgroundColor: alpha(theme.palette.text.disabled, 0.1),
  },
  containedPrimary: {
    color: theme.palette.common.white,
    '&:active, &:focus': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  [`&.${buttonClasses.outlinedSecondary}`]: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
    },
  },
  [`&.${buttonClasses.sizeSmall}`]: {
    height: 31,
    padding: '8px 16px',
    boxShadow: `0px 4px 6px ${alpha(theme.palette.common.black, 0.1)}`,
    [`& .${typographyClasses.root}`]: {
      fontSize: 12,
      lineHeight: '15px',
      fontWeight: 600,
    },
  },
  [`&.${buttonClasses.containedSizeSmall}`]: {
    [`&.${buttonClasses.containedWarning}`]: {
      backgroundColor: theme.palette.action.active,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: darken(theme.palette.action.active, 0.2),
      },
    },
    [`&.${buttonClasses.containedPrimary}`]: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: darken(theme.palette.primary.main, 0.2),
      },
    },
  },
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  lineHeight: '19px',
  fontWeight: 600,
  fontFamily: theme.typography.body1.fontFamily,
}));
