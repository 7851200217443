import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import type {
  Auth0ContextInterface,
  LogoutOptions,
  RedirectLoginOptions,
} from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import throttle from 'lodash/throttle';
import { authControllerLogout, AuthError } from '@safc/api-client';

type TokenContextProps = {
  getToken: () => Promise<AuthToken | null>;
  isLoading: boolean;
  isAuthenticated: boolean;
};
export const TokenContext = createContext<TokenContextProps>({
  getToken: () => Promise.resolve(null),
  isLoading: false,
  isAuthenticated: false,
});

const INTERNAL_TOKEN_KEY = 'internalToken';

export const authError = {
  ...AuthError,
  inactivity: 'inactivity' as 'inactivity',
};

interface AuthToken {
  token: string;
  expiresAt: number;
}

const getInternalToken = (): AuthToken | null => {
  const item = window.localStorage.getItem(INTERNAL_TOKEN_KEY);

  if (item) {
    return JSON.parse(item);
  } else {
    return null;
  }
};

const getAuth0Token = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
): Promise<AuthToken | null> => {
  const token = await getAccessTokenSilently({
    authorizationParams: {
      audience: process.env.AUTH_AUDIENCE,
    },
    detailedResponse: true,
  });

  return {
    expiresAt: Date.now() + token.expires_in * 1000,
    token: token.access_token,
  };
};

// We use session storage for that, because we want it to be reset on page reload
const lastActivityStore = {
  get(): Date | null {
    const stored = sessionStorage.getItem('lastActivity');
    return stored ? new Date(Number(stored)) : null;
  },
  set(value: Date) {
    sessionStorage.setItem('lastActivity', value.getTime().toString());
  },
};

/** CALL THAT ONLY ONCE IN APPLICATION */
export const useTokenContextProvider = (): TokenContextProps => {
  const { logout, ...auth0 } = useAuth0();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(auth0.isLoading);

  const successLogin = () => {
    setIsAuthenticated(true);
    setIsLoading(false);
  };

  const failedLogin = () => {
    setIsAuthenticated(false);
    setIsLoading(false);
  };

  const getToken = useCallback(async () => {
    const internalToken = getInternalToken();

    if (internalToken) {
      return internalToken;
    }

    try {
      const auth0Token = await getAuth0Token(auth0.getAccessTokenSilently);

      if (!auth0Token) {
        return null;
      }

      return auth0Token;
    } catch (e) {
      // Auth0 might throw for any reason actually, like Login-required or something. In that case we'll just "logout" a user
      return null;
    }
  }, [auth0.getAccessTokenSilently]);

  // ===== Initial token check =====
  // We perform initial token-getting to kick-off the auth0 background process
  useEffect(() => {
    const initialTokenCheck = async () => {
      if (auth0.isLoading) {
        return null;
      }

      const token = await getToken();

      if (token) {
        successLogin();
      }

      if (!token) {
        failedLogin();
      }
    };

    void initialTokenCheck();
  }, [auth0.isAuthenticated, auth0.isLoading, getToken]);

  // ===== Auto logout =====
  // Here we perform logout after some inactivity.
  // Inactivity can be checked below - we rely on clicks in the application
  useEffect(() => {
    const twoHoursInMs = 2 * 3600 * 1000;
    const intervalId = setInterval(() => {
      const lastActivity = lastActivityStore.get();

      if (!lastActivity) {
        return;
      }

      if (lastActivity.getTime() + twoHoursInMs < Date.now()) {
        if (isAuthenticated) {
          localStorage.removeItem(INTERNAL_TOKEN_KEY);

          const returnUrl = new URL(window.origin);
          returnUrl.searchParams.set('authError', authError.inactivity);

          void logout({
            logoutParams: {
              returnTo: returnUrl.href,
            },
          });
        }
      }
    }, 3000);

    return () => { clearInterval(intervalId); };
  }, [isAuthenticated, logout]);

  // ===== Track activity =====
  // Track activity to prevent logout
  // This is simplest and most effective method
  // Using API calls for that doesn't make sense, because
  // things are periodically refetched in background
  useEffect(() => {
    const handler = throttle(() => {
      lastActivityStore.set(new Date());
    }, 5000);

    document.body.addEventListener('click', handler);
    lastActivityStore.set(new Date());

    return () => document.body.removeEventListener('click', handler);
  }, []);

  return {
    isLoading,
    isAuthenticated,
    getToken,
  };
};

export const useAuth = () => {
  const tokenData = useContext(TokenContext);
  const { loginWithRedirect, logout } = useAuth0();

  return {
    ...tokenData,
    login: (options?: RedirectLoginOptions) => {
      return loginWithRedirect(options);
    },
    logout: async (options?: LogoutOptions) => {
      await authControllerLogout().catch(() => {}); /** if that action failed, still logout */

      localStorage.removeItem(INTERNAL_TOKEN_KEY);
      await logout(options); // logout performs redirect, so we don't have to clean the state
    },
    setInternalToken: (token: string) => {
      localStorage.setItem(
        INTERNAL_TOKEN_KEY,
        JSON.stringify({ token, expiresAt: Date.now() + 3600 * 1000 } satisfies AuthToken
      ),
      );
    },
  };
};
