import { useNavigate } from 'react-router-dom';
import { RoutePaths } from './routes';
import type { UserDataWithOnboardingStatus } from '@safc/api-client';

export const useNavigateToDefaultPage = ({
  user,
}: {
  user?: UserDataWithOnboardingStatus;
}) => {
  const navigate = useNavigate();

  const navigateToDefaultPage = () => {
    if (!user) {
      return;
    }

    const isCROfSomeCompany = user.companies.some(
      (company) => company.role === 'companyRepresentative',
    );
    const hasSomeAccountMemberships = user.accountMemberships.length > 0;

    if (user.isPendingOnboardingApproval) {
      return navigate(RoutePaths.COMPANY);
    }

    if (
      user.isRegistryAdmin ||
      (isCROfSomeCompany && !hasSomeAccountMemberships)
    ) {
      navigate(RoutePaths.ACCOUNTS);
    } else {
      navigate(RoutePaths.HOLDINGS);
    }
  };

  return { navigateToDefaultPage };
};
