import { alpha, styled } from '@mui/material/styles';
import type { TabProps } from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { tabsClasses, tabClasses } from '@mui/material';
import type { ReactNode, SyntheticEvent } from 'react';

interface StyledTabsProps<ValueType = any> {
  children?: ReactNode;
  value: ValueType;
  onChange: (event: SyntheticEvent, newValue: ValueType) => void;
}

export const Indicator = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '6px 6px 0px 0px',
}));

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <Indicator /> }} />
))({
  minHeight: 41,
  [`& .${tabsClasses.indicator}`]: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 4,
  },
});

interface StyledTabProps extends TabProps {
  label: string;
}

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '17px',
  color: alpha(theme.palette.common.black, 0.55),
  fontFamily: theme.typography.body1.fontFamily,
  padding: '10px 0',
  minHeight: 37,
  marginRight: 40,
  [`&.${tabClasses.selected}`]: {
    color: theme.palette.primary.main,
  },
}));
