import { Navigate, Route } from 'react-router-dom';

import { useRoutesRootElement } from './useRoutesRootElement';
import { useCreateRegistryAdminNavigationMenu } from 'utils/navigationMenu';
import { RoutePaths } from '@safc/ui-utils/routes';
import { asLazy } from 'utils/lazy.utils';
import { Suspense } from 'components/common/Suspense';

const UnitPage = asLazy(import('../../pages/UnitPage/UnitPage'), 'UnitPage');
const LogsPage = asLazy(import('../../pages/LogsPage/LogsPage'), 'LogsPage');
const Account = asLazy(import('../../pages/Account'), 'Account');
const Accounts = asLazy(import('../../pages/Accounts'), 'Accounts');
const Analytics = asLazy(import('../../pages/Analytics'), 'Analytics');
const Companies = asLazy(
  import('../../pages/RegistryAdmin/Companies'),
  'Companies',
);
const CompanyView = asLazy(
  import('../../pages/RegistryAdmin/CompanyView/CompanyView'),
  'CompanyView',
);
const InvoiceView = asLazy(
  import('../../pages/Billing/InvoiceView/InvoiceView'),
  'InvoiceView',
);
const Holdings = asLazy(import('../../pages/Holdings'), 'Holdings');
const ProfileSettings = asLazy(
  import('../../pages/ProfileSettings'),
  'ProfileSettings',
);
const UsersPage = asLazy(
  import('../../pages/RegistryAdmin/UsersPage'),
  'UsersPage',
);
const UserViewPage = asLazy(
  import('../../pages/RegistryAdmin/UserViewPage'),
  'UserViewPage',
);
const RegistryAdminList = asLazy(
  import('../../pages/RegistryAdmin/RegistryAdminList'),
  'RegistryAdminList',
);
const ActionsPage = asLazy(
  import('../../pages/Actions/ActionsPage/ActionsPage'),
  'ActionsPage',
);

const BillingPage = asLazy(
  import('../../pages/Billing/BillingPage/BillingPage'),
  'BillingPage',
);

export const useRegistryAdminRoutes = () => {
  const navigationItems = useCreateRegistryAdminNavigationMenu();
  const renderRootElement = useRoutesRootElement();

  return (
    <Route path="/" element={renderRootElement(navigationItems)}>
      <Route
        path={RoutePaths.COMPANY}
        element={
          <Suspense>
            <RegistryAdminList />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.COMPANIES}
        element={
          <Suspense>
            <Companies />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.COMPANY_VIEW()}
        element={
          <Suspense>
            <CompanyView />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.INVOICE_VIEW()}
        element={
          <Suspense>
            <InvoiceView />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.ACCOUNTS}
        element={
          <Suspense>
            <Accounts />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.ACCOUNT()}
        element={
          <Suspense>
            <Account />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.USERS}
        element={
          <Suspense>
            <UsersPage />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.USER_VIEW()}
        element={
          <Suspense>
            <UserViewPage />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.ACTIONS}
        element={
          <Suspense>
            <ActionsPage />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.LOGS}
        element={
          <Suspense>
            <LogsPage />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.BILLING}
        element={
          <Suspense>
            <BillingPage />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.PROFILE_SETTINGS}
        element={
          <Suspense>
            <ProfileSettings />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.HOLDINGS}
        element={
          <Suspense>
            <Holdings />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.ANALYTICS}
        element={
          <Suspense>
            <Analytics />
          </Suspense>
        }
      />
      <Route
        path={RoutePaths.UNIT_VIEW()}
        element={
          <Suspense>
            <UnitPage />
          </Suspense>
        }
      />

      <Route path="/" element={<Navigate to={RoutePaths.COMPANY} />} />
    </Route>
  );
};
