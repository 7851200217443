import {
  DEFAULT_LOGGED_PAGE,
  DEFAULT_NOT_LOGGED_PAGE,
} from '@safc/ui-utils/routes';
import { useAuth } from '@safc/ui-utils/auth/useAuth';

export const useAppAuth = () => {
  const auth = useAuth();

  return {
    ...auth,
    login: () =>
      auth.login({
        appState: { returnTo: DEFAULT_LOGGED_PAGE },
      }),
    logout: () =>
      auth.logout({
        logoutParams: {
          returnTo: `${window.origin}${DEFAULT_NOT_LOGGED_PAGE}`,
        },
      }),
  };
};
