import type { FC, SVGProps } from 'react';
import { icons } from './icons';
import type { StyleFunction, SxProps, Theme } from '@mui/system';
import { styled, unstable_styleFunctionSx } from '@mui/system';

export type IconKey = keyof typeof icons;

const StyledUse = styled('use')<SVGProps<any>>(
  unstable_styleFunctionSx as StyleFunction<SVGProps<any>>,
);
const StyledSvg = styled('svg')<SVGProps<any>>(
  unstable_styleFunctionSx as StyleFunction<SVGProps<any>>,
);
const StyledImg = styled('img')(unstable_styleFunctionSx as StyleFunction<any>);

export type UseIconProps = {
  icon?: IconKey;
  id?: string;
  className?: string;
  sx?: SxProps<Theme>;
  svgProps?: SVGProps<any>;
};

export const UseIcon: FC<UseIconProps> = ({
  icon,
  id = 'icon',
  className,
  sx,
  svgProps,
}) => {
  if (!icon)
    return (
      <StyledSvg className={className} sx={sx} {...svgProps}>
        {null}
      </StyledSvg>
    );

  return (
    <StyledSvg className={className} sx={sx} {...svgProps}>
      {icon ? (
        <StyledUse
          className={className}
          sx={sx}
          href={`${icons[icon]}#${id}`}
        />
      ) : null}
    </StyledSvg>
  );
};

export const Icon: FC<{
  icon?: IconKey;
  className?: string;
  sx?: SxProps<Theme>;
  style?: React.CSSProperties;
  alt?: string;
}> = ({ icon, className, style, sx, alt }) => {
  if (!icon)
    return <StyledImg className={className} style={style} sx={sx} alt={alt} />;

  return (
    <StyledImg
      style={style}
      sx={sx}
      className={className}
      src={icons[icon]}
      alt={alt}
    />
  );
};
