import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useMainLayoutEffects = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const toggleNav = () => setNavOpen((open) => !open);
  const location = useLocation();

  useEffect(() => {
    setNavOpen(false);
  }, [location]);

  return { isNavOpen, toggleNav };
};
