import type { FormEventHandler } from 'react';
import React, { memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useGenericFormEffects } from './GenericForm.effects';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Button } from '@safc/ui-components/components/buttons/Button';
import { FormSection } from '../FormSection';
import type {
  FormItemConfiguration,
  FormSectionItem,
  GenericFormProps,
} from './GenericForm.types';
import { SingleColumnForm } from 'components/form/SingleColumnForm';

export const GenericForm = memo(
  ({
    submitHandler,
    secondaryButtons,
    items = [],
    mode,
    children,
    initialValues,
    withSections,
    shouldUpdateInitialValues,
    inputsToWatch,
    onWatchHandler,
    buttonProps,
    buttonsWrapperProps,
    columnProps,
    submitButtonText,
    stepperProps,
    loading = false,
    withButtons = true,
  }: GenericFormProps) => {
    const {
      onSubmit,
      clearErrors,
      control,
      formState,
      setError,
      setValue,
      resetField,
    } = useGenericFormEffects({
      submitHandler,
      items,
      mode,
      inputsToWatch,
      initialValues,
      onWatchHandler,
      withSections,
      shouldUpdateInitialValues,
    });

    const { errors, isValid } = formState;

    return (
      <form
        onSubmit={onSubmit as FormEventHandler<HTMLFormElement>}
        style={{ width: '100% ' }}
      >
        {withSections ? (
          items.map((item, index) => {
            const formSection = item as FormSectionItem;
            const showSubtitle =
              formSection?.fields?.some((field) => field?.required) &&
              index === 0;
            return (
              <FormSection
                key={`section-${formSection.title}, ${index}`}
                title={formSection.title}
                fields={formSection.fields}
                control={control}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                resetField={resetField}
                wrapperProps={columnProps}
                showSubtitle={showSubtitle}
                showDivider={index !== items.length - 1}
                stepperProps={stepperProps}
              />
            );
          })
        ) : (
          <SingleColumnForm
            fields={items as FormItemConfiguration}
            control={control}
            errors={errors}
            setError={setError}
            setValue={setValue}
            resetField={resetField}
            wrapperProps={columnProps}
            clearErrors={clearErrors}
          />
        )}
        <Box>{children}</Box>
        {withButtons && (
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            {...buttonsWrapperProps}
          >
            {secondaryButtons?.map(({ wrapperProps = {}, ...button }) => (
              <Grid
                item
                key={`secondary-button-${button.label}`}
                mr="auto"
                {...wrapperProps}
              >
                <Button {...button}>{button.label}</Button>
              </Grid>
            ))}
            <Grid item>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={!isValid || loading || !isEmpty(errors)}
                fullWidth
                startIcon={loading && <CircularProgress size={24} />}
                {...buttonProps}
              >
                {submitButtonText ?? 'Submit'}
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    );
  },
);

GenericForm.displayName = 'GenericForm';
