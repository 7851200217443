import { ConfirmModal } from '@safc/ui-components/components/modals/ConfirmModal';
import { useGlobalErrorHandlerEffects } from './GlobalErrorHandler.effects';
import {
  ErrorDescription,
  ErrorID,
  ErrorTitle,
} from './GlobalErrorHandler.styles';

export const GlobalErrorHandler = () => {
  const { open, parsedError, onClose } = useGlobalErrorHandlerEffects();

  return (
    <ConfirmModal
      open={open}
      actions={[
        {
          title: 'Close',
          onClick: onClose,
          buttonProps: { variant: 'contained' },
        },
      ]}
    >
      <ErrorTitle>Error</ErrorTitle>
      <ErrorID gutterBottom>Error ID: {parsedError?.errorId}</ErrorID>
      <ErrorDescription variant="body1">
        {parsedError?.message}
      </ErrorDescription>
    </ConfirmModal>
  );
};
