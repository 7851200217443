import { alpha, styled } from '@mui/material/styles';
import MuiPaper from '@mui/material/Paper';
import Box, { type BoxProps } from '@mui/material/Box';

export const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Paper = styled(MuiPaper)(({ theme }) => ({
  width: '50%',
  padding: 20,
  minHeight: 280,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: `0px 5px 25px ${alpha(theme.palette.common.black, 0.08)}`,
}));
