import type { FC, ReactElement } from 'react';
import type { AppState } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export type AppAuthState = {} & AppState;

export const AuthProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN!}
      clientId={process.env.AUTH0_CLIENT_ID!}
      authorizationParams={{
        audience: process.env.AUTH_AUDIENCE,
        redirect_uri: `${window.origin}/accounts`,
      }}
      useRefreshTokens
      cacheLocation="localstorage"
      onRedirectCallback={(appState?: AppAuthState) => {
        navigate(appState?.returnTo ?? window.location.pathname);
      }}
    >
      {children}
    </Auth0Provider>
  );
};
