import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { keyframes, styled } from '@mui/material';

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
`;

export const NotificationDot = styled(Box)(({ theme }) => ({
  width: '19px',
  height: '18px',
  backgroundColor: theme.palette.error.dark,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 2,
  right: -7,
  marginLeft: '-9px',
  marginTop: '-9px',
}));

export const AnimatedNotificationDot = styled(NotificationDot)({
  animation: `${pulse} 0.6s ease-in-out`,
});

export const NotificationLabel = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  fontWeight: 700,
  color: theme.palette.common.white,
}));

export const NotificationButton = styled('button')({
  all: 'unset',
  position: 'relative',
  display: 'flex',
  cursor: 'pointer',
  '&:disabled': {
    pointerEvents: 'none',
  },
});
