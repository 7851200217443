import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import type { ChangeEvent } from 'react';
import { FormControlLabel } from '@safc/ui-components/components/form/FormControlLabel';
import { Checkbox } from '@safc/ui-components/components/form/Checkbox';
import type { FormInputProps } from '@safc/ui-components/components/form/FormInput';

export const FormCheckboxes = ({ control, field }: FormInputProps) => {
  return (
    <Controller
      key={`${field.name}`}
      name={field.name}
      control={control}
      render={({ field: { onChange, value = [] } }) => {
        const handleCheckboxes = (
          event: ChangeEvent<HTMLInputElement>,
          checked: boolean,
        ) => {
          if (checked) {
            onChange([...value, event.target.value]);
          } else {
            onChange(
              value.filter((item: string) => item !== event.target.value),
            );
          }
        };
        return (
          <Grid container flexDirection="column" rowSpacing={2}>
            {field?.options?.map((option) => {
              return (
                <Grid item key={option.value}>
                  <FormControlLabel
                    label={option.label}
                    labelPlacement="end"
                    labelProps={{ fontWeight: 400 }}
                    control={
                      <Checkbox
                        value={option.value}
                        color="warning"
                        onChange={handleCheckboxes}
                        checked={value.includes(option.value)}
                      />
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        );
      }}
    />
  );
};
