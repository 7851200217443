import type { FC } from 'react';
import { forwardRef } from 'react';
import MuiAvatar from '@mui/material/Avatar';
import type { AvatarProps } from './Avatar.types';
import { useStyles } from './Avatar.styles';
import { InitialsIcon } from './InitialsIcon';
import { isBase64 } from '../../utils/isBase64';

export const Avatar: FC<AvatarProps> = forwardRef(
  ({ src = '', variant, color, ...props }, ref) => {
    const styles = useStyles({ size: props.size, onClick: props.onClick });
    const isImage = isBase64(src) || src.includes('.svg');

    return isImage ? (
      <MuiAvatar
        ref={ref}
        style={styles}
        src={src}
        sx={{ '& img': { objectFit: 'contain' } }}
        {...props}
      />
    ) : (
      <InitialsIcon
        text={src}
        {...props}
        variant={variant ?? 'filled'}
        style={styles}
      />
    );
  },
);

Avatar.displayName = 'Avatar';
