import type { SyncResult } from '@safc/ui-utils/type.utils';
import type { ParsedError } from '@safc/ui-utils/errorMessage';
import { getErrorMessage } from '@safc/ui-utils/errorMessage';
import defaults from 'lodash/defaults';
import { create } from 'zustand';

type ErrorPayload = { error: any; updatedAt?: number };
type ErroredContext = {
  error: { error: Error; updatedAt: number };
  setError: (errorPayload: ErrorPayload) => void;
  clearError: () => SyncResult;
  parsedError: ParsedError;
};

type NonErrorContext = {
  error: { error: null; updatedAt: number };
  setError: (errorPayload: ErrorPayload) => void;
  clearError: () => SyncResult;
  parsedError: null;
};

type ErrorContextType = ErroredContext | NonErrorContext;

const noError = {
  error: { updatedAt: 0, error: null },
  parsedError: null,
} as const;

export const useErrorStore = create<ErrorContextType>((set) => ({
  error: noError.error,
  setError: (error) =>
    set(() => {
      if (error === null) {
        return noError;
      }
      const errorWithDefaults = defaults(error, {
        updatedAt: Date.now(),
        parsedError: getErrorMessage(error),
      });

      if (!error) {
        return errorWithDefaults;
      }

      if ((error.updatedAt ?? Date.now()) < (error.updatedAt ?? 0)) {
        return {};
      }

      return errorWithDefaults;
    }),
  clearError: () =>
    set(() => ({
      error: noError.error,
    })),
  parsedError: null,
}));
