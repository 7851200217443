import type { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  CollaborationPaper,
  Container,
  FindOutMoreLink,
  HeadlineGradient,
  HomeDrawer,
  LogoLink,
  NavButtonsContainer,
  NavContainer,
  NavLink,
  TopBarSpacer,
} from './Homepage.style';
import RMILogo from '@safc/assets/icons/rmi/logo.png';
import EDFLogo from '@safc/assets/icons/edf/logo.png';
import { Footer } from './Footer/Footer';
import { authRoutes, DEFAULT_LOGGED_PAGE } from '@safc/ui-utils/routes';
import { useHomepageEffects } from './Homepage.effects';
import Close from '@mui/icons-material/Close';
import Menu from '@mui/icons-material/Menu';
import { Auth0Error } from './Auth0Error/Auth0Error';
import { useAppAuth } from '../../hooks/auth/useAppAuth';
import { Button } from '@safc/ui-components/components/buttons/Button';
import SabaLogo from '@safc/assets/images/saba-logo.png?url';
import { Icon } from '@safc/assets/Icon';
import GBTLogo from '@safc/assets/images/gbt.jpg?url';
import AtlassianLogo from '@safc/assets/images/atlasian.jpg?url';
import AutoDeskLogo from '@safc/assets/images/autodesk.jpg?url';
import BainLogo from '@safc/assets/images/bain.jpg?url';
import BankOfAmericaLogo from '@safc/assets/images/bankofamerica.jpg?url';
import BoomLogo from '@safc/assets/images/boom.jpg?url';
import BCGLogo from '@safc/assets/images/bcg.jpg?url';
import WatershedLogo from '@safc/assets/images/watershed.jpg?url';
import DeloitteLogo from '@safc/assets/images/deloite.jpg?url';
import DisneyLogo from '@safc/assets/images/disney.jpg?url';
import GoogleLogo from '@safc/assets/images/google.jpg?url';
import MicrosoftLogo from '@safc/assets/images/microsoft.jpg?url';
import JPLogo from '@safc/assets/images/jp.jpg?url';
import LulemonLogo from '@safc/assets/images/lulemon.jpg?url';
import McKinseyLogo from '@safc/assets/images/mckinsey.jpg?url';
import WorkdayLogo from '@safc/assets/images/workday.jpg?url';
import MetaLogo from '@safc/assets/images/meta.jpg?url';
import MorganStanleyLogo from '@safc/assets/images/morganstanley.jpg?url';
import NetflixLogo from '@safc/assets/images/netflix.jpg?url';
import NovonordiskLogo from '@safc/assets/images/novonordisk.jpg';
import OktaLogo from '@safc/assets/images/okta.jpg?url';
import SalesforceLogo from '@safc/assets/images/salesforce.jpg?url';
import SamsungBioLogo from '@safc/assets/images/samsungbio.jpg?url';
import ZurichLogo from '@safc/assets/images/zurich.jpg?url';

const logos = [
  { src: GBTLogo, alt: 'GBT' },
  { src: AtlassianLogo, alt: 'Atlassian' },
  { src: AutoDeskLogo, alt: 'AutoDesk' },
  { src: BainLogo, alt: 'Bain' },
  { src: BankOfAmericaLogo, alt: 'Bank Of America' },
  { src: BoomLogo, alt: 'Boom' },
  { src: BCGLogo, alt: 'BCG' },
  { src: WatershedLogo, alt: 'Watershed' },
  { src: DeloitteLogo, alt: 'Deloitte' },
  { src: DisneyLogo, alt: 'Disney' },
  { src: GoogleLogo, alt: 'Google' },
  { src: MicrosoftLogo, alt: 'Microsoft' },
  { src: JPLogo, alt: 'JP' },
  { src: LulemonLogo, alt: 'Lulemon' },
  { src: McKinseyLogo, alt: 'McKinsey' },
  { src: WorkdayLogo, alt: 'Workday' },
  { src: MetaLogo, alt: 'Meta' },
  { src: MorganStanleyLogo, alt: 'Morgan Stanley' },
  { src: NetflixLogo, alt: 'Netflix' },
  { src: NovonordiskLogo, alt: 'Novonordisk' },
  { src: OktaLogo, alt: 'Okta' },
  { src: SalesforceLogo, alt: 'Salesforce' },
  { src: SamsungBioLogo, alt: 'Samsung Bio' },
  { src: ZurichLogo, alt: 'Zurich' },
];

export const Collaboration = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const {
    handleAboutClick,
    handleDocumentationClick,
    scrolled,
    isDrawerOpened,
    toggleDrawer,
    login,
    contactUsRef,
    handleContactUsClick,
  } = useHomepageEffects(isMobile);
  const navigate = useNavigate();

  const { isAuthenticated, logout } = useAppAuth();

  return (
    <Container
      sx={(theme) => ({
        padding: '0 102px',
        [theme.breakpoints.down('md')]: {
          padding: '0 16px',
        },
      })}
    >
      <NavContainer
        sx={{
          zIndex: scrolled || isDrawerOpened ? 99999 : 'unset',
        }}
      >
        <LogoLink href="/">
          <Box display="flex" alignContent="center" pt={0.5}>
            <Icon icon="safIconDarkSvg" sx={{ width: 78, height: 36 }} />
          </Box>
        </LogoLink>
        <NavButtonsContainer>
          <Button onClick={() => navigate('/')}>
            <NavLink sx={(theme) => ({ color: theme.palette.primary.dark })}>
              About the SAFc Registry
            </NavLink>
          </Button>
          <Button onClick={() => navigate('/')}>
            <NavLink sx={(theme) => ({ color: theme.palette.primary.dark })}>
              Key documents
            </NavLink>
          </Button>
          <Button href="https://docs.safcregistry.org/faqs">
            <NavLink sx={(theme) => ({ color: theme.palette.primary.dark })}>
              FAQs
            </NavLink>
          </Button>
        </NavButtonsContainer>
        <Box display="flex" alignItems="center" gap="15px">
          <Button
            variant="contained"
            onClick={handleContactUsClick}
            sx={(theme) => ({
              [theme.breakpoints.down(720)]: {
                display: 'none',
              },
            })}
          >
            Contact Us
          </Button>
          {isAuthenticated ? (
            <Button
              variant="contained"
              onClick={() => navigate(DEFAULT_LOGGED_PAGE)}
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  display: 'none',
                },
              })}
            >
              Explore Registry
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => navigate(authRoutes.REGISTER_CR)}
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  display: 'none',
                },
              })}
            >
              Register
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={isAuthenticated ? logout : login}
          >
            {isAuthenticated ? 'Logout' : 'Login'}
          </Button>
          <IconButton
            sx={(theme) => ({
              display: 'none',
              [theme.breakpoints.down('lg')]: {
                display: 'flex',
              },
            })}
            onClick={toggleDrawer}
          >
            {isDrawerOpened ? (
              <Close
                fontSize="large"
                color="primary"
                sx={{
                  zIndex: 9999,
                }}
              />
            ) : (
              <Menu fontSize="large" color={'primary'} />
            )}
          </IconButton>
        </Box>
      </NavContainer>
      <TopBarSpacer />
      <HomeDrawer open={isDrawerOpened}>
        <Box
          sx={{
            display: 'flex',
            width: '100vw',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '8px 16px',
            gap: 'calc(100vh - 148px - 175px - 45px)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100vw',
              marginTop: '74px',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <Button
              onClick={(e) => {
                toggleDrawer();
                handleAboutClick(e);
              }}
            >
              <NavLink sx={{ color: '#000' }}>About the SAFc Registry</NavLink>
            </Button>
            <Button
              onClick={(e) => {
                toggleDrawer();
                handleDocumentationClick(e);
              }}
            >
              <NavLink sx={{ color: '#000' }}>Registry documentation</NavLink>
            </Button>
            <Button href="https://docs.safcregistry.org/faqs">
              <NavLink sx={{ color: '#000' }}>FAQs</NavLink>
            </Button>
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Button
              variant="contained"
              onClick={(e) => {
                toggleDrawer();
                handleContactUsClick(e);
              }}
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                },
              })}
            >
              Contact Us
            </Button>
            {isAuthenticated ? null : (
              <Button
                variant="contained"
                onClick={() => navigate(authRoutes.REGISTER_CR)}
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
              >
                Register
              </Button>
            )}
          </Box>
        </Box>
      </HomeDrawer>
      <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
        <HeadlineGradient
          sx={(theme) => ({
            width: '50%',
            textAlign: 'center',
            marginTop: '30px',
            [theme.breakpoints.down('md')]: {
              width: '70%'
            },
            [theme.breakpoints.down('sm')]: {
              width: '100%',
            },
          })}
        >
          The SAFc Registry is a collaboration between
        </HeadlineGradient>
        <CollaborationPaper mt={'60px'}>
          <Box
            sx={{
              width: '120px',
              display: 'flex',
              flexDirection: 'column',
              pt: 1,
            }}
          >
            <img
              style={{ width: '100%', height: 'auto' }}
              src={SabaLogo}
              alt="saba logo"
            />
          </Box>
          <HeadlineGradient sx={{ fontSize: '24px', mt: 2 }}>
            Sustainable Aviation Buyers Alliance
          </HeadlineGradient>
          <Typography>
            The Sustainable Aviation Buyers Alliance (SABA) is accelerating the
            path to net-zero aviation by driving investment in, and adoption of,
            high-integrity sustainable aviation fuel (SAF) and supporting
            companies, airlines and freight customers in achieving their climate
            goals.Their members work in collaboration with EDF and RMI to
            develop a rigorous, transparent system that expands opportunities to
            invest in high-integrity SAF to all businesses and organizations
            interested in reducing the climate impacts of flying. SABA’s
            founding companies included Bank of America, Boeing, Boston
            Consulting Group, Deloitte, JPMorgan Chase, McKinsey & Company,
            Meta, Microsoft, Netflix and Salesforce.
          </Typography>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              color: 'rgba(30, 30, 30, 0.75)',
              marginTop: 4,
            }}
          >
            Members
          </Typography>
          <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            mt={4}
            mb={4}
            spacing={2}
          >
            {logos.map((logo) => (
              <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                item
                key={logo.alt}
                md={2}
                lg={1.5}
                sm={3}
                xs={6}
              >
                <img src={logo.src} alt={logo.alt} />
              </Grid>
            ))}
          </Grid>
          <FindOutMoreLink href="https://flysaba.org/" target="_blank" rel="noopener noreferrer">
            Find out more {' ->'}
          </FindOutMoreLink>
        </CollaborationPaper>
      </Box>
      <Grid container mt={2} spacing={3}>
        <Grid item md={4}>
          <CollaborationPaper height="454px" position={'relative'} sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              padding: '0 8px'
            },
            [theme.breakpoints.down(1523)]: {
              height: '500px'
            },
            [theme.breakpoints.down(1320)]: {
              height: '600px'
            },
            [theme.breakpoints.down(1089)]: {
              height: '700px'
            },
            [theme.breakpoints.down(990)]: {
              height: '800px'
            },
            [theme.breakpoints.down(980)]: {
              height: '484px'
            }
          })}>
            <Box
              sx={theme => ({
                width: '164px',
                display: 'flex',
                flexDirection: 'column',
                height: '115px',
                [theme.breakpoints.down('sm')]: {
                  height: '80px'
                }
              })}
            >
              <img
                style={{ width: '100%', height: 'auto' }}
                src={RMILogo}
                alt="saba logo"
              />
            </Box>
            <HeadlineGradient
              sx={(theme) => ({
                fontSize: '24px',
              })}
            >
              RMI
            </HeadlineGradient>
            <Typography>
              The Rocky Mountain Institute, commonly abbreviated to RMI, is an
              organization lecturing in the field of sustainability, with a
              focus on profitable innovations for energy and resource
              efficiency.
            </Typography>
            <FindOutMoreLink
              href="https://rmi.org/"
              sx={{ position: 'absolute', bottom: '20px' }}
              target="_blank" rel="noopener noreferrer"
            >
              Find out more {' ->'}
            </FindOutMoreLink>
          </CollaborationPaper>
        </Grid>
        <Grid item md={4}>
          <CollaborationPaper height="454px" position={'relative'} sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              padding: '0 8px'
            },
            [theme.breakpoints.down(1523)]: {
              height: '500px'
            },
            [theme.breakpoints.down(1320)]: {
              height: '600px'
            },
            [theme.breakpoints.down(1089)]: {
              height: '700px'
            },
            [theme.breakpoints.down(990)]: {
              height: '800px'
            },
            [theme.breakpoints.down(980)]: {
              height: '484px'
            }
          })}>
            <Box
             sx={theme => ({
              width: '164px',
              display: 'flex',
              flexDirection: 'column',
              height: '115px',
              [theme.breakpoints.down('sm')]: {
                height: '80px'
              }
            })}
            >
              <img
                style={{ width: '100%', height: 'auto' }}
                src={EDFLogo}
                alt="saba logo"
              />
            </Box>
            <HeadlineGradient sx={{ fontSize: '24px' }}>
              Enviromental Defense Fund
            </HeadlineGradient>
            <Typography>
              The group is known for its work on issues including global
              warming, ecosystem restoration, oceans, and human health, and
              advocates using sound science, economics and law to find
              environmental solutions that work.
            </Typography>
            <FindOutMoreLink
              href="https://www.edf.org/"
              sx={{ position: 'absolute', bottom: '20px' }}
              target="_blank" rel="noopener noreferrer"
            >
              Find out more {' ->'}
            </FindOutMoreLink>
          </CollaborationPaper>
        </Grid>
        <Grid item md={4}>
          <CollaborationPaper height="454px" position={'relative'} sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              padding: '0 8px'
            },
            [theme.breakpoints.down(1523)]: {
              height: '500px'
            },
            [theme.breakpoints.down(1320)]: {
              height: '600px'
            },
            [theme.breakpoints.down(1089)]: {
              height: '700px'
            },
            [theme.breakpoints.down(990)]: {
              height: '800px'
            },
            [theme.breakpoints.down(980)]: {
              height: '484px'
            }
          })}>
            <Box
              sx={theme => ({
                width: '164px',
                display: 'flex',
                flexDirection: 'column',
                height: '115px',
                [theme.breakpoints.down('sm')]: {
                  height: '80px'
                }
              })}
            >
              <Icon icon="ewLogoFullSvg" />
            </Box>
            <HeadlineGradient sx={{ fontSize: '24px' }}>
              Energy Web
            </HeadlineGradient>
            <Typography>
              Energy Web is a global non-profit accelerating the clean energy
              transition by developing open-source technology solutions for
              energy systems. Our enterprise-grade solutions improve
              coordination across complex energy markets, unlocking the full
              potential of clean, distributed energy resources for businesses,
              grid operators, and customers.
            </Typography>
            <FindOutMoreLink
              href="https://energyweb.org/"
              sx={{ position: 'absolute', bottom: '20px' }}
              target="_blank" rel="noopener noreferrer"
            >
              Find out more {' ->'}
            </FindOutMoreLink>
          </CollaborationPaper>
        </Grid>
      </Grid>
      <Footer ref={contactUsRef} />
      <Auth0Error />
    </Container>
  );
};
