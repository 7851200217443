/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * SAFc Backend
 * The 24/7 API description
 * OpenAPI spec version: 1.0
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions
} from '@tanstack/react-query'
import type {
  CreateTicketRequest
} from './sAFcBackend.schemas'
import helpdeskControllerCreateTicketMutator from '../response-type';
import { customFormData } from '../../mutators/custom-file-data';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Create helpdesk ticket
 */
export const helpdeskControllerCreateTicket = (
    createTicketRequest: CreateTicketRequest,
 options?: SecondParameter<typeof helpdeskControllerCreateTicketMutator>,) => {
      
      const formData = customFormData(createTicketRequest)
      return helpdeskControllerCreateTicketMutator<void>(
      {url: `/api/helpdesk/ticket`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getHelpdeskControllerCreateTicketMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof helpdeskControllerCreateTicket>>, TError,{data: CreateTicketRequest}, TContext>, request?: SecondParameter<typeof helpdeskControllerCreateTicketMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof helpdeskControllerCreateTicket>>, TError,{data: CreateTicketRequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof helpdeskControllerCreateTicket>>, {data: CreateTicketRequest}> = (props) => {
          const {data} = props ?? {};

          return  helpdeskControllerCreateTicket(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type HelpdeskControllerCreateTicketMutationResult = NonNullable<Awaited<ReturnType<typeof helpdeskControllerCreateTicket>>>
    export type HelpdeskControllerCreateTicketMutationBody = CreateTicketRequest
    export type HelpdeskControllerCreateTicketMutationError = unknown

    export const useHelpdeskControllerCreateTicket = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof helpdeskControllerCreateTicket>>, TError,{data: CreateTicketRequest}, TContext>, request?: SecondParameter<typeof helpdeskControllerCreateTicketMutator>}
) => {

      const mutationOptions = getHelpdeskControllerCreateTicketMutationOptions(options);

      return useMutation(mutationOptions);
    }
    