import type { ReactNode } from 'react';
import { Suspense as ReactSuspense } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface SuspenseProps {
  children?: ReactNode;
}

export const Suspense = ({ children }: SuspenseProps) => {
  return (
    <ReactSuspense
      fallback={
        <Box
          width="100%"
          height="100%"
          minHeight={800}
          display="grid"
          sx={{ placeItems: 'center' }}
        >
          <CircularProgress />
        </Box>
      }
    >
      {children}
    </ReactSuspense>
  );
};
