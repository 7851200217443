import type { FormControlLabelProps } from '@mui/material/FormControlLabel';
import {
  StyledFormControlLabel,
  StyledFormLabel,
} from './FormControlLabel.styled';
import type { TypographyProps } from '@mui/material/Typography';

export const FormControlLabel = ({
  labelProps,
  ...props
}: FormControlLabelProps & { labelProps?: TypographyProps }) => {
  const label =
    typeof props?.label === 'string' ? (
      <StyledFormLabel {...labelProps}>{props?.label ?? ''}</StyledFormLabel>
    ) : (
      props?.label
    );
  return <StyledFormControlLabel {...props} label={label} />;
};
