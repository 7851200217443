import { ErrorCode } from 'react-dropzone';

export const formatErrorMessage = (errorCode?: string) => {
  if (errorCode === ErrorCode.FileTooLarge) {
    return 'Exceeded maximum file size';
  }
  if (errorCode === ErrorCode.TooManyFiles) {
    return 'Exceeded maximum files count';
  }
  return 'Not supported';
};
