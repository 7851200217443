import styled from '@mui/material/styles/styled';
import MuiAutocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { alpha, autocompleteClasses, buttonBaseClasses, iconButtonClasses, outlinedInputClasses, svgIconClasses, touchRippleClasses } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';

export const StyledAutocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  [`& .${outlinedInputClasses.root}`]: {
    margin: 0,
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    [`& .${autocompleteClasses.input}`]: {
      padding: 0,
    },
    [`& .${iconButtonClasses.root}`]: {
      '&:hover': {
        background: 'transparent',
      },
    },
    [`& .${svgIconClasses.root}`]: {
      color: theme.palette.common.black,
    },
    [`&.${autocompleteClasses.inputRoot}`]: {
      padding: '11px 40px 11px 16px',
      fontSize: 14,
      lineHeight: '24px',
      minHeight: 24,
      fontWeight: 400,
      color: theme.palette.grey[800],
      fontFamily: theme.typography.body2.fontFamily,
      textTransform: 'capitalize',
      borderRadius: 10,
    },
    [`&.${touchRippleClasses.root}`]: {
      display: 'none',
    },
  },
}));

export const AutocompletePaper = styled(Paper)(({ theme }) => ({
  boxShadow: `1px 1px 8px ${alpha(theme.palette.common.black, 0.05)}`,
  borderRadius: '6px',
  marginTop: '4px',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    paddingRight: '3px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
    borderRadius: '50px',
  },
}));

export const autocompleteListBoxStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.background.paper,
  padding: 0,
  maxHeight: 200,
  [`& .${autocompleteClasses.option}`]: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    fontFamily: theme.typography.body2.fontFamily,
    padding: '11px 16px',
    [`&.${buttonBaseClasses.root}`]: {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.08),
      color: theme.palette.primary.main,
      [`& .${svgIconClasses.root}`]: {
        fill: theme.palette.primary.main,
      },
    },
  },
  [`& .${autocompleteClasses.groupLabel}`]: {
    color: theme.palette.primary.main,
  },
});
