import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import { RegisterRouter } from './routers/RegisterRouter';
import {
  DEFAULT_LOGGED_PAGE,
  DEFAULT_NOT_LOGGED_PAGE,
  RoutePaths,
} from '@safc/ui-utils/routes';
import LoginPage from '../pages/LoginPage';
import { isResponseError } from 'utils/response';
import { useInitializeApiConfig } from 'api/api.config';
import { Backdrop } from 'components/common/Backdrop';
import { GlobalErrorHandler } from 'components/layout/GlobalErrorHandler';
import { useRegistryAdminRoutes } from './hooks/useRegistryAdminRoutes';
import { usePublicRoutes } from './hooks/usePublicRoutes';
import { useRegularRoutes } from './hooks/useRegularRoutes';
import { QueryClientProvider } from 'utils/useQueryClient';
import { CookieConsentBar } from 'containers/CookieConsentBar/CookieConsentBar';
import {
  ClearCacheContext,
  useAutoClearCache,
  useClearCacheContextProvider,
} from 'hooks/useClearCache';
import {
  TokenContext,
  useTokenContextProvider,
} from '@safc/ui-utils/auth/useAuth';
import { useMyUser } from '@safc/ui-utils/getMyUser';
import { ErrorStatus } from '@safc/ui-utils/enums';
import { useCompanyContext } from '@safc/ui-utils/useCompanyContext';
import {
  AbilityContext,
  createUserAbilities,
} from '@safc/ui-utils/permissions/usePermissions';
import type { CompanyContextProps } from '@safc/ui-utils/useCurrentCompany';
import { CompanyContext } from '@safc/ui-utils/useCurrentCompany';
import { useAppAuth } from '../hooks/auth/useAppAuth';
import { LoginSuccessPage } from '../pages/LoginSuccessPage/LoginSuccessPage';
import { ConfirmModal } from '@safc/ui-components/components/modals/ConfirmModal';

const ContextfulApp = () => {
  const { isAuthenticated, logout } = useAppAuth();
  const { user, isLoading: isLoadingUser, error } = useMyUser();
  const navigate = useNavigate();
  const registryAdminRoutes = useRegistryAdminRoutes();
  const regularRoutes = useRegularRoutes();

  if (isLoadingUser) {
    return <Backdrop open={true} />;
  }

  if (isAuthenticated && isResponseError(error, ErrorStatus.Unauthorized)) {
    return (
      <ConfirmModal
        open
        actions={[
          {
            title: 'Go back to home page',
            onClick: () => {
              void logout();
              navigate(RoutePaths.HOME);
            },
            buttonProps: { variant: 'contained' },
          },
        ]}
      >
        Sorry, we couldn&apos;t find your profile.
      </ConfirmModal>
    );
  }

  return (
    <Routes>
      {user?.isRegistryAdmin ? registryAdminRoutes : regularRoutes}
      <Route
        path={'*'}
        element={
          <Navigate
            to={
              Boolean(isAuthenticated)
                ? DEFAULT_LOGGED_PAGE
                : DEFAULT_NOT_LOGGED_PAGE
            }
          />
        }
      />
    </Routes>
  );
};

const PrivateApp: FC<PropsWithChildren> = () => {
  const { companyId, companyName } = useCompanyContext();
  const { user } = useMyUser();
  const userAbilities = useMemo(
    () => createUserAbilities({ user, companyId }),
    [user, companyId],
  );

  return (
    <CompanyContext.Provider
      key={companyId}
      value={{ companyId, companyName } as CompanyContextProps}
    >
      <AbilityContext.Provider value={userAbilities}>
        <CookieConsentBar />
        <ContextfulApp />
      </AbilityContext.Provider>
    </CompanyContext.Provider>
  );
};

const AuthorizedApp: FC<PropsWithChildren> = () => {
  const { isLoading, isAuthenticated } = useAppAuth();

  if (isLoading) {
    return <Backdrop open={true} />;
  }

  if (!isAuthenticated) {
    return <Navigate to={DEFAULT_NOT_LOGGED_PAGE} />;
  }

  return (
    <Routes>
      <Route path={RoutePaths.LOGIN_SUCCESS} element={<LoginSuccessPage />} />
      <Route path="*" element={<PrivateApp />} />
    </Routes>
  );
};

const PublicApp = () => {
  const publicRoutes = usePublicRoutes();
  useInitializeApiConfig();
  useAutoClearCache();

  return (
    <Routes>
      {publicRoutes}
      {RegisterRouter}
      <Route path={RoutePaths.LOGIN} element={<LoginPage />} />
      <Route path="*" element={<AuthorizedApp />} />
    </Routes>
  );
};

export const App = () => {
  const clearCacheValue = useClearCacheContextProvider();
  const tokenValue = useTokenContextProvider();

  return (
    <TokenContext.Provider value={tokenValue}>
      <ClearCacheContext.Provider value={clearCacheValue}>
        <QueryClientProvider>
          <CookieConsentBar />
          <GlobalErrorHandler />
          <PublicApp />
        </QueryClientProvider>
      </ClearCacheContext.Provider>
    </TokenContext.Provider>
  );
};
