import type { JSONSchema } from 'json-schema-to-typescript';

const claimSchema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  properties: {
    blockId: {
      type: 'string',
      format: 'uuid',
    },
    volume: {
      type: 'number',
      minimum: 0.001,
    },
    accountId: {
      type: 'string',
      format: 'uuid',
    },
    retirementYear: {
      type: 'integer',
    },
    retirementCountry: {
      type: 'string',
    },
    retirementOnRegistryYear: {
      type: 'integer',
    },
    claimPurpose: {
      enum: ['domestic', 'international'],
    },
    complianceRegime: {
      enum: ['corsia', 'not-corsia'],
    },
    logisticsProviderBeneficiary: {
      type: 'string',
      format: 'uuid',
    },
    previousOwnerId: {
      type: 'string',
      format: 'uuid',
    },
    safcClaimBeneficiary: {
      oneOf: [
        {
          type: 'object',
          title: 'internal',
          properties: {
            ownerId: {
              type: 'string',
              format: 'uuid',
            },
          },
          required: ['ownerId'],
        },
        {
          type: 'object',
          title: 'external',
          properties: {
            name: {
              type: 'string',
            },
            email: {
              type: 'string',
            },
          },
          required: ['name', 'email'],
        },
      ],
    },
    sercClaimBeneficiary: {
      oneOf: [
        {
          type: 'object',
          title: 'internal',
          properties: {
            ownerId: {
              type: 'string',
              format: 'uuid',
            },
          },
          required: ['ownerId'],
        },
        {
          type: 'object',
          title: 'external',
          properties: {
            name: {
              type: 'string',
            },
            email: {
              type: 'string',
            },
          },
          required: ['name', 'email'],
        },
      ],
    },
  },
  required: ['blockId', 'volume', 'accountId', 'retirementYear', 'retirementCountry'],
  additionalProperties: false,
} satisfies JSONSchema;

const claimUiSchema = {
  'ui:submitButtonOptions': {
    submitText: 'Save',
    style: {
      marginTop: 20,
    },
  },
  'blockId': {
    'ui:title': 'Block ID',
    'ui:placeholder': 'Block ID',
  },
  'volume': {
    'ui:title': 'Volume',
    'ui:placeholder': 'Volume',
  },
  'accountId': {
    'ui:title': 'Account ID',
    'ui:placeholder': 'Account ID',
  },
  'retirementYear': {
    'ui:title': 'The Claim year',
    'ui:widget': 'date',
    'ui:placeholder': 'Year',
    'ui:options': {
      views: ['year'],
      disableFuture: true,
    },
  },
  'retirementCountry': {
    'ui:title': 'The Claim country',
    'ui:field': 'country',
    'ui:placeholder': 'Country',
  },
  'retirementOnRegistryYear': {
    'ui:title': 'Retirement year',
    'ui:widget': 'hidden',
  },
  'claimPurpose': {
    'ui:widget': 'radio',
    'ui:title': 'Claim Purpose',
  },
  'complianceRegime': {
    'ui:widget': 'radio',
    'ui:title': 'Compliance Regime',
  },
  'logisticsProviderBeneficiary': {
    'ui:title': 'Logistics Provider beneficiary',
  },
  'safcClaimBeneficiary': {
    'ui:title': 'Air Transport Provider beneficiary',
    'name': {
      'ui:title': 'Name',
      'ui:placeholder': 'Name',
    },
    'email': {
      'ui:title': 'Email',
      'ui:placeholder': 'Email',
    },
    'ownerId': {
      'ui:title': 'Owner ID',
      'ui:placeholder': 'Owner ID',
    },
  },
  'sercClaimBeneficiary': {
    'ui:title': 'SAFcE beneficiary',
    'name': {
      'ui:title': 'Name',
      'ui:placeholder': 'Name',
    },
    'ownerId': {
      'ui:title': 'Owner ID',
      'ui:placeholder': 'Owner ID',
    },
  },
};

export const Claim = {
  schema: claimSchema,
  uiSchema: claimUiSchema,
  mapping: {
    blockId: 'blockId',
    volume: 'volume',
    accountId: 'accountId',
  },
  nonUpdatable: [
    'blockId',
    'volume',
    'accountId',
    'retirementYear',
    'retirementCountry',
    'retirementOnRegistryYear',
    'claimPurpose',
    'complianceRegime',
    'logisticsProviderBeneficiary',
    'safcClaimBeneficiary.ownerId',
    'sercClaimBeneficiary.ownerId',
  ],
};
