import Box from '@mui/material/Box';
import { TopBarAvatar } from 'containers/TopBarAvatar';
import {
  AppBar,
  LogoWrapper,
  Menu,
  MenuButton,
  ToolBar,
} from './TopBar.styles';
import { Helpdesk } from 'components/helpdesk';
import { NotificationBell } from 'containers/NotificationBell';
import { useMyUser } from '@safc/ui-utils/getMyUser';
import { UseIcon } from '@safc/assets/Icon';
import { useNavigateToDefaultPage } from '@safc/ui-utils/useNavigateToDefaultPage';

interface TopBarProps {
  toggleNav: () => void;
}

export const TopBar = ({ toggleNav }: TopBarProps) => {
  const { isLoggedIn, user } = useMyUser();
  const { navigateToDefaultPage } = useNavigateToDefaultPage({ user });

  return (
    <AppBar position="fixed">
      <ToolBar variant="dense">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <MenuButton disableRipple onClick={toggleNav}>
              <Menu />
            </MenuButton>
            <LogoWrapper onClick={navigateToDefaultPage}>
              <UseIcon
                icon="safIconDarkSvg"
                sx={{ height: { xs: 30, md: 45 }, width: 80 }}
                svgProps={{ viewBox: '0 0 548 256' }}
              />
            </LogoWrapper>
          </Box>
          <Box display="flex" alignItems="center" gap={3}>
            {isLoggedIn && <NotificationBell />}
            <Helpdesk />
            {isLoggedIn && user ? (
              <TopBarAvatar username={user.name} email={user.email} />
            ) : null}
          </Box>
        </Box>
      </ToolBar>
    </AppBar>
  );
};
