/** @NOTE this may not work for all the cases, please fix if doesn't work */
const appendToFormData = (key: string, value: any, form: FormData) => {
  if (value instanceof Blob) {
    // File
    return form.append(key, value);
  }

  if (Array.isArray(value)) {
    if (value[0] instanceof Blob) {
      // Array with blobs
      return value.forEach((file: Blob) => {
        form.append(key, file);
      });
    }

    // Any other array: serialize whole array
    return form.append(key, JSON.stringify(value));
  }

  // Anything that should be serialized
  form.append(key, JSON.stringify(value));
}

export const customFormData = (body: any): FormData => {
  const form = new FormData();

  Object.entries(body).forEach(([key, value]) => {
    appendToFormData(key, value, form);
  });

  return form;
};
