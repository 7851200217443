import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { drawerClasses } from '@mui/material';

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& > .${drawerClasses.paper}`]: {
    [theme.breakpoints.down(1200)]: {
      display: 'none',
    },
    width: 365,
    minHeight: '100vh',
    borderRight: 'none',
    backgroundImage: 'url(/sidebar-image.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&:before': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      background:
        'linear-gradient(180deg, rgba(113, 153, 162, 0.9) 0%, rgba(255, 255, 255, 0.62) 51.56%)',
      transform: 'rotate(-180deg)',
    },
    [theme.breakpoints.down(1280)]: {
      width: 275,
    },
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '70px 47px 20px 48px',
  [theme.breakpoints.down(1280)]: {
    padding: '48px 24px 20px 25px',
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  fontSize: 21,
  fontWeight: 500,
  lineHeight: '25px',
  color: theme.palette.text.secondary,
  fontFamily: theme.typography.body1.fontFamily,
  marginTop: 28,
  zIndex: 1,
  [theme.breakpoints.down(1280)]: {
    fontSize: 18,
    lineHeight: '24px',
  },
}));
