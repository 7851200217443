import type { TextFieldProps } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Input } from '../Input';

export const DatePickerInput = (props: TextFieldProps) => (
  <FormControlLabel
    sx={{ margin: 0, width: '100%' }}
    label={props.label ?? ''}
    labelPlacement="top"
    control={
      <Input
        {...props}
        fullWidth
        margin="none"
        variant="outlined"
        InputProps={{
          ...props.InputProps,
          onClick: props.onClick,
        }}
        inputProps={{
          ...props.inputProps,
          placeholder: props.placeholder ?? '',
          disabled: true,
        }}
      />
    }
  />
);
