import { backdropClasses } from '@mui/material/Backdrop';
import { buttonClasses } from '@mui/material/Button';
import { dialogActionsClasses } from '@mui/material/DialogActions';
import { dialogContentClasses } from '@mui/material/DialogContent';
import { paperClasses } from '@mui/material/Paper';
import Typography, { typographyClasses } from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Modal } from '../Modal';

export const CloseIconWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(4),
  right: theme.spacing(4),
  display: 'grid',
  placeItems: 'end',
}));

export const StyledModal = styled(Modal)(({ theme }) => ({
  [theme.breakpoints.down(1200)]: {
    margin: 'auto',
  },
  [`& .${backdropClasses.root}`]: {
    backgroundColor: alpha(theme.palette.common.black, 0.5),
  },
  [`& .${paperClasses.root}`]: {
    [theme.breakpoints.down(1200)]: {
      minWidth: '300px',
    },
    boxShadow: 'none',
    borderRadius: 6,
    backgroundColor: theme.palette.background.default,
    minWidth: 570,
    minHeight: 250,
    padding: '30px 40px',
    gap: 20,
    alignItems: 'center',
    textAlign: 'center',
    transition: 'none !important',
    maxWidth: 720,
  },
  [`& .${dialogContentClasses.root}`]: {
    [theme.breakpoints.down(1200)]: {
      margin: 'auto',
    },
    padding: '0 24px 20px',
    overflow: 'initial',
    [`& .${typographyClasses.h3}`]: {
      fontSize: 21,
      lineHeight: '32px',
      fontWeight: 600,
      color: theme.palette.primary.dark,
    },
    [`& .${typographyClasses.subtitle1}`]: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '17px',
      color: theme.palette.primary.main,
    },
  },
  [`& .${dialogActionsClasses.root} .${buttonClasses.root}`]: {
    minWidth: 85,
    flexShrink: 0,
  },
}));

export const ConfirmModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '22px',
  lineHeight: '32px',
  color: theme.palette.primary.dark,
  marginBottom: 20,
  whiteSpace: 'pre-line',
}));

export const ConfirmModalDescription = styled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '17px',
  whiteSpace: 'pre-line',
});

export const CloseIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  padding: 0,
  right: 28,
  top: 26,
  '& path': {
    stroke: theme.palette.common.white,
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const ConfirmModalItalicDescription = styled(Typography)({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '15px',
  fontStyle: 'italic',
  whiteSpace: 'pre-line',
  marginTop: 10,
});
