import { Controller } from 'react-hook-form';
import { Select } from '../Select';
import type { FormSelectProps } from './FormSelect.types';
import type { FormSelectOption } from '@safc/ui-components/components/form/GenericForm';

export const FormSelect = ({
  field,
  control,
  errorExists = false,
  errorText = '',
}: FormSelectProps) => {
  return (
    <Controller
      key={`${field.name}`}
      name={field.name}
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <Select
            field={field}
            errorExists={errorExists}
            errorText={errorText}
            disabled={field?.disabled}
            onChange={onChange}
            value={
              value?.length === 0 ? '' : (value as FormSelectOption['value'])
            }
          />
        );
      }}
    />
  );
};
