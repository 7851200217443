import { createContext, useContext, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getUserControllerGetMyUserDataQueryKey } from '@safc/api-client';
import { DEFAULT_LOGGED_PAGE } from '@safc/ui-utils/routes';

export const useClearCache = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return {
    clearCache: async () => {
      await queryClient.invalidateQueries({
        queryKey: getUserControllerGetMyUserDataQueryKey(),
      });
      navigate(DEFAULT_LOGGED_PAGE);
      window.location.reload();
    },
    isLoading: false,
  };
};

type ClearCacheContextProps = {
  shouldClearCache: boolean;
  setShouldClearCache: (shouldClearCache: boolean) => void;
};
export const ClearCacheContext = createContext<ClearCacheContextProps>({
  shouldClearCache: false,
  setShouldClearCache: () => {},
});

export const useClearCacheContextProvider = (): ClearCacheContextProps => {
  const [shouldClearCache, setShouldClearCache] = useState(false);

  return {
    shouldClearCache,
    setShouldClearCache,
  };
};

// @TODO https://energyweb.atlassian.net/browse/SAF-1488
export const useAutoClearCache = () => {
  const { shouldClearCache } = useContext(ClearCacheContext);
  const { clearCache } = useClearCache();

  useEffect(() => {
    if (shouldClearCache) {
      void clearCache();
    }
  }, [shouldClearCache]);
};
