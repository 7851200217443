import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)(({ theme }) => ({
  padding: '55px 102px 100px 465px',
  width: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  [theme.breakpoints.down(1280)]: {
    padding: '40px 24px 100px 324px',
  },
  [theme.breakpoints.down(1200)]: {
    paddingLeft: '20px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px 7px 100px 7px',
    maxWidth: '500px',
  },
}));
