import styled from '@mui/material/styles/styled';
import FormControlLabel, {
  formControlLabelClasses,
} from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  width: '100%',
  [`& .${formControlLabelClasses.label}`]: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.body2.fontFamily,
    alignSelf: 'flex-start',
  },
}));

export const StyledFormLabel = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '16px',
  color: theme.palette.text.secondary,
  fontFamily: theme.typography.body1.fontFamily,
  paddingLeft: 15,
  paddingTop: 2,
  whiteSpace: 'pre-line',
}));
