/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * SAFc Backend
 * The 24/7 API description
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CompanyUserDataItem,
  GetAccountInvitedResponse,
  GetAccountUsersResponse,
  GetCompanyInvitedResponse,
  UpdateUserRequest,
  UserControllerGetAccountInvitedUsersParams,
  UserControllerGetCompanyInvitedUsersParams,
  UserControllerGetCompanyUsersParams,
  UserControllerGetUserDataParams,
  UserDataResponse,
  UserDataWithOnboardingStatus,
  UserDetailsResponse,
  UserListResponseItem
} from './sAFcBackend.schemas'
import userControllerGetMyUserDataMutator from '../response-type';
import userControllerGetUsersListMutator from '../response-type';
import userControllerUpdateUserMutator from '../response-type';
import userControllerRemoveUserMutator from '../response-type';
import userControllerGetUserDataMutator from '../response-type';
import userControllerGetCompanyUsersMutator from '../response-type';
import userControllerGetAccountUsersMutator from '../response-type';
import userControllerGetCompanyInvitedUsersMutator from '../response-type';
import userControllerGetAccountInvitedUsersMutator from '../response-type';
import userControllerBlockUserMutator from '../response-type';
import userControllerUnblockUserMutator from '../response-type';
import userControllerGetFullUserDataMutator from '../response-type';
import userControllerGetUserDetailsMutator from '../response-type';
import { customFormData } from '../../mutators/custom-file-data';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


export const userControllerGetMyUserData = (
    
 options?: SecondParameter<typeof userControllerGetMyUserDataMutator>,signal?: AbortSignal
) => {
      
      
      return userControllerGetMyUserDataMutator<UserDataWithOnboardingStatus>(
      {url: `/api/users/me`, method: 'get', signal
    },
      options);
    }
  

export const getUserControllerGetMyUserDataQueryKey = () => {
    
    return [`/api/users/me`] as const;
    }

    
export const getUserControllerGetMyUserDataQueryOptions = <TData = Awaited<ReturnType<typeof userControllerGetMyUserData>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetMyUserData>>, TError, TData>, request?: SecondParameter<typeof userControllerGetMyUserDataMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerGetMyUserDataQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetMyUserData>>> = ({ signal }) => userControllerGetMyUserData(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerGetMyUserData>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerGetMyUserDataQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerGetMyUserData>>>
export type UserControllerGetMyUserDataQueryError = unknown

export const useUserControllerGetMyUserData = <TData = Awaited<ReturnType<typeof userControllerGetMyUserData>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetMyUserData>>, TError, TData>, request?: SecondParameter<typeof userControllerGetMyUserDataMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerGetMyUserDataQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const userControllerGetUsersList = (
    
 options?: SecondParameter<typeof userControllerGetUsersListMutator>,signal?: AbortSignal
) => {
      
      
      return userControllerGetUsersListMutator<UserListResponseItem[]>(
      {url: `/api/users/list`, method: 'get', signal
    },
      options);
    }
  

export const getUserControllerGetUsersListQueryKey = () => {
    
    return [`/api/users/list`] as const;
    }

    
export const getUserControllerGetUsersListQueryOptions = <TData = Awaited<ReturnType<typeof userControllerGetUsersList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUsersList>>, TError, TData>, request?: SecondParameter<typeof userControllerGetUsersListMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerGetUsersListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetUsersList>>> = ({ signal }) => userControllerGetUsersList(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUsersList>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerGetUsersListQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerGetUsersList>>>
export type UserControllerGetUsersListQueryError = unknown

export const useUserControllerGetUsersList = <TData = Awaited<ReturnType<typeof userControllerGetUsersList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUsersList>>, TError, TData>, request?: SecondParameter<typeof userControllerGetUsersListMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerGetUsersListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update user
 */
export const userControllerUpdateUser = (
    userId: string,
    updateUserRequest: UpdateUserRequest,
 options?: SecondParameter<typeof userControllerUpdateUserMutator>,) => {
      
      
      return userControllerUpdateUserMutator<void>(
      {url: `/api/users/${userId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateUserRequest
    },
      options);
    }
  


export const getUserControllerUpdateUserMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdateUser>>, TError,{userId: string;data: UpdateUserRequest}, TContext>, request?: SecondParameter<typeof userControllerUpdateUserMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdateUser>>, TError,{userId: string;data: UpdateUserRequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerUpdateUser>>, {userId: string;data: UpdateUserRequest}> = (props) => {
          const {userId,data} = props ?? {};

          return  userControllerUpdateUser(userId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UserControllerUpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerUpdateUser>>>
    export type UserControllerUpdateUserMutationBody = UpdateUserRequest
    export type UserControllerUpdateUserMutationError = unknown

    export const useUserControllerUpdateUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdateUser>>, TError,{userId: string;data: UpdateUserRequest}, TContext>, request?: SecondParameter<typeof userControllerUpdateUserMutator>}
) => {

      const mutationOptions = getUserControllerUpdateUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Remove user from the registry
 */
export const userControllerRemoveUser = (
    userId: string,
 options?: SecondParameter<typeof userControllerRemoveUserMutator>,) => {
      
      
      return userControllerRemoveUserMutator<void>(
      {url: `/api/users/${userId}`, method: 'delete'
    },
      options);
    }
  


export const getUserControllerRemoveUserMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerRemoveUser>>, TError,{userId: string}, TContext>, request?: SecondParameter<typeof userControllerRemoveUserMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof userControllerRemoveUser>>, TError,{userId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerRemoveUser>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  userControllerRemoveUser(userId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UserControllerRemoveUserMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerRemoveUser>>>
    
    export type UserControllerRemoveUserMutationError = unknown

    export const useUserControllerRemoveUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerRemoveUser>>, TError,{userId: string}, TContext>, request?: SecondParameter<typeof userControllerRemoveUserMutator>}
) => {

      const mutationOptions = getUserControllerRemoveUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const userControllerGetUserData = (
    userId: string,
    params: UserControllerGetUserDataParams,
 options?: SecondParameter<typeof userControllerGetUserDataMutator>,signal?: AbortSignal
) => {
      
      
      return userControllerGetUserDataMutator<UserDataResponse>(
      {url: `/api/users/${userId}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getUserControllerGetUserDataQueryKey = (userId: string,
    params: UserControllerGetUserDataParams,) => {
    
    return [`/api/users/${userId}`, ...(params ? [params]: [])] as const;
    }

    
export const getUserControllerGetUserDataQueryOptions = <TData = Awaited<ReturnType<typeof userControllerGetUserData>>, TError = unknown>(userId: string,
    params: UserControllerGetUserDataParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUserData>>, TError, TData>, request?: SecondParameter<typeof userControllerGetUserDataMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerGetUserDataQueryKey(userId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetUserData>>> = ({ signal }) => userControllerGetUserData(userId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUserData>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerGetUserDataQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerGetUserData>>>
export type UserControllerGetUserDataQueryError = unknown

export const useUserControllerGetUserData = <TData = Awaited<ReturnType<typeof userControllerGetUserData>>, TError = unknown>(
 userId: string,
    params: UserControllerGetUserDataParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUserData>>, TError, TData>, request?: SecondParameter<typeof userControllerGetUserDataMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerGetUserDataQueryOptions(userId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const userControllerGetCompanyUsers = (
    params: UserControllerGetCompanyUsersParams,
 options?: SecondParameter<typeof userControllerGetCompanyUsersMutator>,signal?: AbortSignal
) => {
      
      
      return userControllerGetCompanyUsersMutator<CompanyUserDataItem[]>(
      {url: `/api/users/company`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getUserControllerGetCompanyUsersQueryKey = (params: UserControllerGetCompanyUsersParams,) => {
    
    return [`/api/users/company`, ...(params ? [params]: [])] as const;
    }

    
export const getUserControllerGetCompanyUsersQueryOptions = <TData = Awaited<ReturnType<typeof userControllerGetCompanyUsers>>, TError = unknown>(params: UserControllerGetCompanyUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetCompanyUsers>>, TError, TData>, request?: SecondParameter<typeof userControllerGetCompanyUsersMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerGetCompanyUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetCompanyUsers>>> = ({ signal }) => userControllerGetCompanyUsers(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerGetCompanyUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerGetCompanyUsersQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerGetCompanyUsers>>>
export type UserControllerGetCompanyUsersQueryError = unknown

export const useUserControllerGetCompanyUsers = <TData = Awaited<ReturnType<typeof userControllerGetCompanyUsers>>, TError = unknown>(
 params: UserControllerGetCompanyUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetCompanyUsers>>, TError, TData>, request?: SecondParameter<typeof userControllerGetCompanyUsersMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerGetCompanyUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const userControllerGetAccountUsers = (
    accountId: string,
 options?: SecondParameter<typeof userControllerGetAccountUsersMutator>,signal?: AbortSignal
) => {
      
      
      return userControllerGetAccountUsersMutator<GetAccountUsersResponse[]>(
      {url: `/api/users/account/${accountId}`, method: 'get', signal
    },
      options);
    }
  

export const getUserControllerGetAccountUsersQueryKey = (accountId: string,) => {
    
    return [`/api/users/account/${accountId}`] as const;
    }

    
export const getUserControllerGetAccountUsersQueryOptions = <TData = Awaited<ReturnType<typeof userControllerGetAccountUsers>>, TError = unknown>(accountId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetAccountUsers>>, TError, TData>, request?: SecondParameter<typeof userControllerGetAccountUsersMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerGetAccountUsersQueryKey(accountId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetAccountUsers>>> = ({ signal }) => userControllerGetAccountUsers(accountId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerGetAccountUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerGetAccountUsersQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerGetAccountUsers>>>
export type UserControllerGetAccountUsersQueryError = unknown

export const useUserControllerGetAccountUsers = <TData = Awaited<ReturnType<typeof userControllerGetAccountUsers>>, TError = unknown>(
 accountId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetAccountUsers>>, TError, TData>, request?: SecondParameter<typeof userControllerGetAccountUsersMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerGetAccountUsersQueryOptions(accountId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const userControllerGetCompanyInvitedUsers = (
    params: UserControllerGetCompanyInvitedUsersParams,
 options?: SecondParameter<typeof userControllerGetCompanyInvitedUsersMutator>,signal?: AbortSignal
) => {
      
      
      return userControllerGetCompanyInvitedUsersMutator<GetCompanyInvitedResponse[]>(
      {url: `/api/users/invited/company`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getUserControllerGetCompanyInvitedUsersQueryKey = (params: UserControllerGetCompanyInvitedUsersParams,) => {
    
    return [`/api/users/invited/company`, ...(params ? [params]: [])] as const;
    }

    
export const getUserControllerGetCompanyInvitedUsersQueryOptions = <TData = Awaited<ReturnType<typeof userControllerGetCompanyInvitedUsers>>, TError = unknown>(params: UserControllerGetCompanyInvitedUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetCompanyInvitedUsers>>, TError, TData>, request?: SecondParameter<typeof userControllerGetCompanyInvitedUsersMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerGetCompanyInvitedUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetCompanyInvitedUsers>>> = ({ signal }) => userControllerGetCompanyInvitedUsers(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerGetCompanyInvitedUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerGetCompanyInvitedUsersQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerGetCompanyInvitedUsers>>>
export type UserControllerGetCompanyInvitedUsersQueryError = unknown

export const useUserControllerGetCompanyInvitedUsers = <TData = Awaited<ReturnType<typeof userControllerGetCompanyInvitedUsers>>, TError = unknown>(
 params: UserControllerGetCompanyInvitedUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetCompanyInvitedUsers>>, TError, TData>, request?: SecondParameter<typeof userControllerGetCompanyInvitedUsersMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerGetCompanyInvitedUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const userControllerGetAccountInvitedUsers = (
    params: UserControllerGetAccountInvitedUsersParams,
 options?: SecondParameter<typeof userControllerGetAccountInvitedUsersMutator>,signal?: AbortSignal
) => {
      
      
      return userControllerGetAccountInvitedUsersMutator<GetAccountInvitedResponse[]>(
      {url: `/api/users/invited/account`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getUserControllerGetAccountInvitedUsersQueryKey = (params: UserControllerGetAccountInvitedUsersParams,) => {
    
    return [`/api/users/invited/account`, ...(params ? [params]: [])] as const;
    }

    
export const getUserControllerGetAccountInvitedUsersQueryOptions = <TData = Awaited<ReturnType<typeof userControllerGetAccountInvitedUsers>>, TError = unknown>(params: UserControllerGetAccountInvitedUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetAccountInvitedUsers>>, TError, TData>, request?: SecondParameter<typeof userControllerGetAccountInvitedUsersMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerGetAccountInvitedUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetAccountInvitedUsers>>> = ({ signal }) => userControllerGetAccountInvitedUsers(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerGetAccountInvitedUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerGetAccountInvitedUsersQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerGetAccountInvitedUsers>>>
export type UserControllerGetAccountInvitedUsersQueryError = unknown

export const useUserControllerGetAccountInvitedUsers = <TData = Awaited<ReturnType<typeof userControllerGetAccountInvitedUsers>>, TError = unknown>(
 params: UserControllerGetAccountInvitedUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetAccountInvitedUsers>>, TError, TData>, request?: SecondParameter<typeof userControllerGetAccountInvitedUsersMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerGetAccountInvitedUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Block user
 */
export const userControllerBlockUser = (
    userId: string,
 options?: SecondParameter<typeof userControllerBlockUserMutator>,) => {
      
      
      return userControllerBlockUserMutator<void>(
      {url: `/api/users/${userId}/block`, method: 'patch'
    },
      options);
    }
  


export const getUserControllerBlockUserMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerBlockUser>>, TError,{userId: string}, TContext>, request?: SecondParameter<typeof userControllerBlockUserMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof userControllerBlockUser>>, TError,{userId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerBlockUser>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  userControllerBlockUser(userId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UserControllerBlockUserMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerBlockUser>>>
    
    export type UserControllerBlockUserMutationError = unknown

    export const useUserControllerBlockUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerBlockUser>>, TError,{userId: string}, TContext>, request?: SecondParameter<typeof userControllerBlockUserMutator>}
) => {

      const mutationOptions = getUserControllerBlockUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Unblock user
 */
export const userControllerUnblockUser = (
    userId: string,
 options?: SecondParameter<typeof userControllerUnblockUserMutator>,) => {
      
      
      return userControllerUnblockUserMutator<void>(
      {url: `/api/users/${userId}/unblock`, method: 'patch'
    },
      options);
    }
  


export const getUserControllerUnblockUserMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUnblockUser>>, TError,{userId: string}, TContext>, request?: SecondParameter<typeof userControllerUnblockUserMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof userControllerUnblockUser>>, TError,{userId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerUnblockUser>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  userControllerUnblockUser(userId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UserControllerUnblockUserMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerUnblockUser>>>
    
    export type UserControllerUnblockUserMutationError = unknown

    export const useUserControllerUnblockUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUnblockUser>>, TError,{userId: string}, TContext>, request?: SecondParameter<typeof userControllerUnblockUserMutator>}
) => {

      const mutationOptions = getUserControllerUnblockUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const userControllerGetFullUserData = (
    userId: string,
 options?: SecondParameter<typeof userControllerGetFullUserDataMutator>,signal?: AbortSignal
) => {
      
      
      return userControllerGetFullUserDataMutator<UserDataResponse>(
      {url: `/api/users/${userId}/full`, method: 'get', signal
    },
      options);
    }
  

export const getUserControllerGetFullUserDataQueryKey = (userId: string,) => {
    
    return [`/api/users/${userId}/full`] as const;
    }

    
export const getUserControllerGetFullUserDataQueryOptions = <TData = Awaited<ReturnType<typeof userControllerGetFullUserData>>, TError = unknown>(userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetFullUserData>>, TError, TData>, request?: SecondParameter<typeof userControllerGetFullUserDataMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerGetFullUserDataQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetFullUserData>>> = ({ signal }) => userControllerGetFullUserData(userId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerGetFullUserData>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerGetFullUserDataQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerGetFullUserData>>>
export type UserControllerGetFullUserDataQueryError = unknown

export const useUserControllerGetFullUserData = <TData = Awaited<ReturnType<typeof userControllerGetFullUserData>>, TError = unknown>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetFullUserData>>, TError, TData>, request?: SecondParameter<typeof userControllerGetFullUserDataMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerGetFullUserDataQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const userControllerGetUserDetails = (
    userId: string,
 options?: SecondParameter<typeof userControllerGetUserDetailsMutator>,signal?: AbortSignal
) => {
      
      
      return userControllerGetUserDetailsMutator<UserDetailsResponse>(
      {url: `/api/users/${userId}/details`, method: 'get', signal
    },
      options);
    }
  

export const getUserControllerGetUserDetailsQueryKey = (userId: string,) => {
    
    return [`/api/users/${userId}/details`] as const;
    }

    
export const getUserControllerGetUserDetailsQueryOptions = <TData = Awaited<ReturnType<typeof userControllerGetUserDetails>>, TError = unknown>(userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUserDetails>>, TError, TData>, request?: SecondParameter<typeof userControllerGetUserDetailsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerGetUserDetailsQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetUserDetails>>> = ({ signal }) => userControllerGetUserDetails(userId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUserDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerGetUserDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerGetUserDetails>>>
export type UserControllerGetUserDetailsQueryError = unknown

export const useUserControllerGetUserDetails = <TData = Awaited<ReturnType<typeof userControllerGetUserDetails>>, TError = unknown>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUserDetails>>, TError, TData>, request?: SecondParameter<typeof userControllerGetUserDetailsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerGetUserDetailsQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



