import { Outlet } from 'react-router-dom';
import { useMainLayoutEffects } from './MainLayout.effects';
import type { TMenuItem } from 'components/navigation/NavMenuItem';
import { TopBar } from 'components/layout/TopBar';
import { NavigationBar } from 'components/navigation/NavigationBar';
import { PageWrapper } from 'components/layout/PageWrapper';

export interface MainLayoutProps {
  navigationItems: TMenuItem[];
}

export const MainLayout = ({ navigationItems }: MainLayoutProps) => {
  const { toggleNav, isNavOpen } = useMainLayoutEffects();

  return (
    <>
      <TopBar toggleNav={toggleNav} />
      <NavigationBar isNavOpen={isNavOpen} menuButtons={navigationItems} />
      <PageWrapper>
        <Outlet />
      </PageWrapper>
    </>
  );
};
