import type { GenericFormProps } from 'containers/GenericForm';
import { GenericForm } from 'containers/GenericForm';
import { z } from 'zod';
import { useHelpdeskControllerCreateTicket } from '@safc/api-client';
import { assign, createMachine, send } from 'xstate';
import { setErrorMessage } from '@safc/ui-components/machines/setErrorMessage';
import { useMachine } from '@xstate/react';
import Box from '@mui/material/Box';
import html2canvas from 'html2canvas';
import type { ModalAction } from '@safc/ui-components/components/modals/Modal';
import {
  CircularProgress,
  ConfirmModalDescription,
  ConfirmModalTitle,
} from '@safc/ui-components/components/modals/ConfirmModal';
import { Icon } from '@safc/assets/Icon';

const modalId = 'helpdesk_modal';

enum ReportingType {
  TechnicalIssue = 'technicalIssue',
  OtherIssues = 'otherIssues',
  Suggestion = 'suggestion',
}

type HelpdeskContextContext = {
  modalOpen: boolean;
  data: {
    type: ReportingType;
    description: string;
    screenshot: boolean;
  };
  errorMessage?: string;
};

const initialState = {
  modalOpen: false,
  errorMessage: undefined,
  data: {
    type: '',
    description: '',
    screenshot: true,
  },
};

type CreateHelpdeskTicketEvents =
  | { type: 'CLOSE_MODAL' }
  | { type: 'SHOW_SUCCESS' }
  | { type: 'SHOW_ERROR' }
  | { type: 'OPEN_MODAL' }
  | {
      type: 'CREATE_TICKET';
      data: { description: string; screenshot: boolean; type: ReportingType };
    };

const createHelpdeskTicketMachine = createMachine(
  {
    predictableActionArguments: true,
    schema: {
      context: {} as HelpdeskContextContext,
      events: {} as CreateHelpdeskTicketEvents,
    },
    id: 'update-account-flow',
    initial: 'Closed',
    context: initialState as HelpdeskContextContext,
    on: {
      CLOSE_MODAL: {
        target: 'Closed',
        actions: assign(initialState as HelpdeskContextContext),
      },
    },
    states: {
      Creating: {
        invoke: {
          src: 'createTicket',
          onDone: {
            target: 'Success',
          },
          onError: setErrorMessage(),
        },
      },
      Success: {
        after: {
          5000: { actions: 'closeModal' },
        },
      },
      Error: {
        after: {
          5000: { target: 'Opened' },
        },
      },
      Opened: {
        entry: assign({ modalOpen: () => true }),
        on: {
          CREATE_TICKET: {
            target: 'Creating',
            actions: assign({
              data: (_, event) => event.data,
            }),
          },
        },
      },
      Closed: {
        on: {
          OPEN_MODAL: {
            target: 'Opened',
            actions: assign(initialState as HelpdeskContextContext),
          },
        },
      },
    },
  },
  {
    actions: {
      closeModal: send({ type: 'CLOSE_MODAL' }),
    },
  },
);

const getBlobFromCanvas = (canvas: HTMLCanvasElement): Promise<Blob | null> =>
  new Promise((resolve, reject) => {
    try {
      canvas.toBlob((blob) => {
        resolve(blob);
      });
    } catch (error) {
      reject(error);
    }
  });

export const useHelpdeskEffects = () => {
  const { mutateAsync: createHelpdeskTicket } =
    useHelpdeskControllerCreateTicket();

  const [helpdeskState, send] = useMachine(createHelpdeskTicketMachine, {
    services: {
      async createTicket(ctx) {
        let screenshotToSend: Blob[] = [];

        if (ctx.data.screenshot) {
          const canvas = await html2canvas(document.documentElement, {
            ignoreElements: ({ id }) => id === modalId,
          });
          const blob = await getBlobFromCanvas(canvas);
          screenshotToSend = blob ? [blob] : [];
        }

        await createHelpdeskTicket({
          data: {
            description: ctx.data.description,
            screenshots: screenshotToSend,
            url: window.location.href,
            type: ctx.data.type as ReportingType,
          },
        });
      },
    },
  });

  const createTicket = (data: HelpdeskContextContext['data']) => {
    send({ type: 'CREATE_TICKET', data });
  };

  const openModal = () => {
    send({ type: 'OPEN_MODAL' });
  };

  const closeModal = () => {
    send({ type: 'CLOSE_MODAL' });
  };

  const { modalOpen, errorMessage } = helpdeskState.context;

  const items: GenericFormProps['items'] = [
    {
      config: {
        name: 'type',
        radio: true,
        options: [
          { label: 'Technical issue', value: ReportingType.TechnicalIssue },
          { label: 'Other Issues', value: ReportingType.OtherIssues },
          { label: 'Suggestion', value: ReportingType.Suggestion },
        ],
      },
      validate: z.nativeEnum(ReportingType),
    },
    {
      config: {
        placeholder:
          'Describe your problem and create a new ticket in our helpdesk.',
        name: 'description',
        textarea: true,
        wrapperProps: {
          sx: { marginBottom: 5 },
        },
      },
      validate: z
        .string()
        .min(
          10,
          'Please ensure your problem description contains at least 10 characters to provide sufficient details for us to assist you effectively.',
        ),
    },
    {
      config: {
        label: 'Include screenshot from present page',
        name: 'screenshot',
        checkbox: true,
      },
      validate: z.boolean(),
      initialValue: true,
    },
  ];

  const getModalContent = () => {
    switch (helpdeskState.value) {
      case 'Opened':
        return {
          Icon: <Icon icon="supportSvg" style={{ width: 50 }} />,
          content: (
            <>
              <ConfirmModalTitle>Helpdesk</ConfirmModalTitle>
              <ConfirmModalDescription>
                <GenericForm items={items} submitHandler={createTicket} />
              </ConfirmModalDescription>
            </>
          ),
        };
      case 'Creating':
        return {
          Icon: <Icon icon="uploadSvg" style={{ width: 50 }} />,
          content: (
            <>
              <CircularProgress />
              <Box mt={2}>
                <ConfirmModalTitle>Sending...</ConfirmModalTitle>
              </Box>
            </>
          ),
        };
      case 'Success':
        return {
          Icon: <Icon icon="alertSuccessSvg" />,
          content: (
            <>
              <ConfirmModalTitle>Success</ConfirmModalTitle>
              <ConfirmModalDescription>
                We have created a new ticket in our helpdesk system. <br />
                Our support team will reach out as soon as it&apos;s possible.
              </ConfirmModalDescription>
            </>
          ),
        };
      case 'Error':
        return {
          Icon: <Icon icon="alertErrorSvg" />,
          content: (
            <>
              <ConfirmModalTitle>Error</ConfirmModalTitle>
              <ConfirmModalDescription>
                We have some problems with reporting your problem
                {errorMessage && <>: &quot;{errorMessage}&quot;</>}
              </ConfirmModalDescription>
            </>
          ),
        };
      default:
        return {
          content: null,
          Icon: null,
        };
    }
  };

  const getDialogActions = (): ModalAction[] => {
    switch (helpdeskState.value) {
      case 'Success':
        return [
          {
            title: 'Close',
            buttonProps: {
              color: 'secondary',
              variant: 'outlined',
            },
            onClick: closeModal,
          },
        ];
      default:
        return [];
    }
  };

  return {
    modalId,
    isHelpdeskModalOpen: modalOpen,
    onCloseHelpdeskModal: closeModal,
    onHelpdeskButtonClick: openModal,
    getModalContent,
    dialogActions: getDialogActions(),
  };
};
